import { deserialize } from "jsonapi-fractal";

import {
  BaseAPI,
} from '@/internal';

export default class EmailLogAPI extends BaseAPI {

  index(params) {
    return this.get({
      url: "email-logs",
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        links: res.links,
        paginator: res.meta && res.meta.pagination ? res.meta.pagination : {},
        data: deserialize(res),
      }
    })
  }

  export(name, params) {
    return this.get({
      url: `email-logs/export`,
      headers: [],
      queryParams: params,
    }).then(res => res.blob())
      .then((response) => {
        const file = new Blob(
          [response],
          {type: 'text/csv'},
        );
        const link = document.createElement('a');
        link.href =  URL.createObjectURL(file);
        link.download = `${name}.csv`;
        document.body.append(link);
        link.click();
        link.remove();
      });
  }

};