<template>
  <div class="form-questions" ref="questionsRef">
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <div class="columns">
      <div class="column is-4 box has-margin-right-large" style="margin-bottom: unset;">
        <div class="title">Available Questions</div>
        <draggable v-model="availableQuestions"
                   item-key="id"
                   :group="{name: 'availableQuestions', pull: ['questions']}"
                   tag="div"
                   :component-data="{name:'fade'}"
                   @start="drag=true"
                   @end="drag=false"
                   :force-fallback="true"
                   :scroll-sensitivity="200"
        >
          <template #header>
            <div class="buttons">
              <o-button variant="primary" @click="handleCreateQuestion">Create New Question</o-button>
            </div>
          </template>

          <template #item="{element}">
            <div class="question has-margin-bottom-small">
              <div class="is-flex">
                <div class="question-drag">
                  <o-icon icon="arrows-alt" pack="fas" variant="secondary" size="small" />
                </div>

                <div class="question-system">
                  <o-tooltip label="Saved to Company Data" position="top" variant="secondary" v-if="element.db_retain">
                    <o-icon icon="thumbtack" variant="secondary" size="small" />
                  </o-tooltip>
                </div>

                <div class="question-text">{{element.nonHTMLQuestion}}</div>

                <div class="question-actions">
                  <o-tooltip label="View Question" position="top" variant="secondary">
                    <button class="action-icon" @click="handleToggleViewQuestion(element)">
                      <o-icon icon="eye" pack="fas" variant="secondary"/>
                    </button>
                  </o-tooltip>
                </div>
              </div>

              <div class="question-view is-flex" :class="{'is-block' : element.viewActive, 'is-hidden': !element.viewActive}">
                <div class="question-preview">
                  <QuestionPicker :question="element" :preview-mode="true" />
                </div>
              </div>
            </div>
          </template>
        </draggable>
      </div>

      <div class="column box">
        <div class="title">Form Questions
          <o-tooltip label="Required">
            <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
          </o-tooltip>
        </div>
        <div class="has-margin-bottom-large">To add questions to your form, drag a question from the Available Questions to the space below. You may also move the questions below to order them how you'd like.</div>
        <div v-if="formQuestions.length"><o-checkbox v-model="requireAll" variant="secondary">Require All</o-checkbox></div>

        <draggable v-model="formQuestions"
                   item-key="id"
                   :group="{name: 'questions', put: ['availableQuestions', 'questions']}"
                   tag="div"
                   :component-data="{name:'fade'}"
                   @start="drag=true"
                   @end="drag=false"
                   class="target-area"
                   :force-fallback="true"
                   :scroll-sensitivity="200"
        >
          <template #item="{element}">
            <div class="question has-margin-bottom-small" :class="{'attention-needed': !isQuestionComplete(element)}">
              <div class="is-flex">
                <div class="question-drag">
                  <o-icon icon="sort" pack="fas" variant="secondary" size="small" />
                </div>

                <div class="question-system">
                  <o-tooltip label="Saved to Company Data" position="top" variant="secondary" v-if="element.db_retain">
                    <o-icon icon="thumbtack" variant="secondary" size="small" />
                  </o-tooltip>
                </div>

                <div class="question-text">
                  <div class="has-margin-bottom-small">{{element.nonHTMLQuestion}}</div>
                  <div><o-checkbox v-model="element.is_required" variant="secondary" :disabled="element.is_instruction_type">Required</o-checkbox></div>
                </div>

                <div class="question-actions">
                  <template v-if="canEdit(element)">
                    <button class="action-icon" @click="handleEdit(element)" v-if="!isQuestionComplete(element)">
                      <o-tooltip label="Options Need to be Selected" position="top" variant="danger">
                        <o-icon icon="pen-to-square" pack="fas" variant="danger"/>
                      </o-tooltip>
                    </button>
                    <o-tooltip label="Edit Question" position="top" variant="secondary" v-else>
                      <button class="action-icon" @click="handleEdit(element)">
                        <o-icon icon="pen-to-square" pack="fas" variant="secondary"/>
                      </button>
                    </o-tooltip>
                  </template>

                  <o-tooltip label="View Question" position="top" variant="secondary">
                    <button class="action-icon" @click="handleToggleViewQuestion(element)">
                      <o-icon icon="eye" pack="fas" variant="secondary"/>
                    </button>
                  </o-tooltip>
                  <o-tooltip label="Remove Question from Form" position="left" variant="secondary">
                    <button class="action-icon" @click="handleRemove(element)">
                      <o-icon icon="trash-alt" pack="fas" variant="secondary"/>
                    </button>
                  </o-tooltip>
                </div>
              </div>

              <div class="question-view is-flex" :class="{'is-block' : element.viewActive, 'is-hidden': !element.viewActive}">
                <div class="question-drag">
                  <o-icon icon="sort" pack="fas" variant="secondary" size="small" />
                </div>
                <div class="question-preview">
                  <QuestionPicker :question="element" :preview-mode="true" />
                </div>
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>

    <o-modal v-model:active="isEditActive" :width="600" class="form-question-modal" v-if="selectedQuestion">
      <div class="title">Edit Question</div>
      <template v-if="selectedQuestion.is_upload_type">
        <div class="subtitle">{{ selectedQuestion.question }}</div>
        <div class="has-margin-bottom-large">
          <o-field label="Question">
            <o-input v-model="editName"></o-input>
          </o-field>
          <o-field label="Note">
            <o-input v-model="editNote" type="textarea"></o-input>
          </o-field>
        </div>

        <div class="has-text-right">
          <o-button class="is-primary has-margin-right" @click="handleCancelEdit">Cancel</o-button>
          <o-button class="is-primary" @click="handleStoreEdit">Save</o-button>
        </div>
      </template>
      <template v-else>
        <o-notification
            variant="warning"
            role="alert"
        >
          <div class="has-margin-bottom-small">WARNING: This question may be utilized on existing forms.</div>
          <div>Any modifications to this question will be reflected on the existing forms as well as any responses to those forms.
            Any changes to options will not change the answers provided in prior submissions, but will show a different option set to future respondents.</div>
        </o-notification>

        <Form v-model:question-value="selectedQuestion" @cancel="handleCancelEdit" @saved="handleQuestionSaved" />
      </template>
    </o-modal>
  </div>

</template>

<script setup>

  import draggable from 'vuedraggable';

  import Form from "@/pages/Questions/Form.vue";
  import QuestionPicker from "@/components/Pickers/QuestionPicker.vue";

  import {
    useFormQuestionStore,
    useGroupStore,
    useTradeStore,
  } from "@/internal";

  import {computed, onMounted, ref, toRefs, watch} from "vue";
  import _ from "lodash";
  import {v4 as uuidv4} from 'uuid';
  import stripHTML from "@/use/stripHTML.js";

  const formQuestionStore = useFormQuestionStore();
  const groupStore = useGroupStore();
  const tradeStore = useTradeStore();

  const availableQuestions = ref([]);
  const formQuestions = ref([]);
  const editName = ref('');
  const editNote = ref('');
  const isEditActive = ref(false);
  const isLoading = ref(false);
  const questionsRef = ref(null);
  const requireAll = ref(false);
  const selectedQuestion = ref(null);
  const uploadFileCount = ref(0);
  const uploadFileObj = {
    id: 'upload',
    question: 'Upload File',
    nonHTMLQuestion: 'Upload File',
    question_note: '',
    is_upload_type: true,
    db_retain: true,
  };

  defineExpose({
    loadAvailableQuestions,
  });

  const props = defineProps({
    questions: {
      required: true,
      type: Array,
    },
  });
  const {
    questions,
  } = toRefs(props);

  const emit = defineEmits([
    'create',
    'update',
  ]);

  onMounted(() => {
    formQuestions.value = questions.value.map(question => {
      return {
        ...question,
        nonHTMLQuestion: stripHTML(question.question),
      }
    });
    loadAvailableQuestions();
    loadGroups();
    loadTrades();
  });

  const groups = computed(() => groupStore.data || []);

  const trades = computed(() => tradeStore.data.sort((a, b) => a.short_label > b.short_label ? 1 : -1) || []);

  function canEdit(question) {
    return question.is_upload_type || !question.is_system || (question.is_system && (question.is_group_multi_select_type || question.is_group_single_select_type || question.is_trade_multi_select_type || question.is_trade_single_select_type));
  }

  function handleCancelEdit() {
    isEditActive.value = false;
    selectedQuestion.value = {};
  }

  function handleCreateQuestion() {
    emit('create');
  }

  async function handleEdit(question) {
    if (question.is_upload_type) {
      selectedQuestion.value = question;
      editName.value = question.question;
      editNote.value = question.question_note;
    }
    else {
      await loadQuestion(question.question_id);
      selectedQuestion.value = formQuestionStore.find(question.question_id);
    }

    isEditActive.value = true;
  }

  function handleRemove(questionToRemove) {
    questionToRemove.viewActive = false;
    formQuestions.value = formQuestions.value.filter(question => {
      if ((!questionToRemove.id || !questionToRemove.id.startsWith("upload_")) && (question.id === questionToRemove.id || (!question.id && question.question_id === questionToRemove.question_id))) {
        availableQuestions.value.splice(1, 0, questionToRemove);
      }
      return question.id !== questionToRemove.id;
    });
  }

  function handleQuestionSaved(question) {
    isEditActive.value = false;

    formQuestions.value = formQuestions.value.map(formQuestion => {
      return parseInt(question.id) === parseInt(formQuestion.question_id)
          ? {
              ...question,
              nonHTMLQuestion: stripHTML(question.question),
              is_required: formQuestion.is_required,
              join_id: formQuestion.join_id,
              question_id: formQuestion.question_id,
              id: formQuestion.id,
            }
          : formQuestion;
    });
  }

  function handleStoreEdit() {
    isEditActive.value = false;

    formQuestions.value = formQuestions.value.map(question => {
      return selectedQuestion.value.id === question.id
          ? {
              ...question,
              question: editName.value,
              question_note: editNote.value,
              nonHTMLQuestion: stripHTML(editName.value),
            }
          : question;
    });

    selectedQuestion.value = {};
    editName.value = '';
    editNote.value = '';
  }

  function handleToggleViewQuestion(element) {
    element.viewActive = element.hasOwnProperty('viewActive') ? !element.viewActive : true;
  }

  function isQuestionComplete(question) {
    const optionsType = question.is_group_multi_select_type || question.is_group_single_select_type || question.is_trade_multi_select_type || question.is_trade_single_select_type || question.is_checkbox_type || question.is_radio_type || question.is_dropdown_type;
    return (optionsType && question.options && question.options.length) || !optionsType;
  }

  function loadAvailableQuestions() {
    isLoading.value = true;

    const payload = {
      params: {
        include: [
            'customField',
            'options',
        ],
        status: 'active',
      },
    };

    formQuestionStore.index(payload)
      .then(() => {
        availableQuestions.value = formQuestionStore.data.filter(question => {
          return !formQuestions.value.find(formQuestion => parseInt(formQuestion.question_id) === parseInt(question.id));
        }).map(question => {
          return {
            ...question,
            question_id: question.id,
            id: `avail_${uuidv4()}`,
            nonHTMLQuestion: stripHTML(question.question),
          };
        }) || [];
        availableQuestions.value.unshift(uploadFileObj);
      }).finally(() => {
        isLoading.value = false;
      });
  }

  function loadQuestion(questionId) {

    isLoading.value = true;

    const payload = {
      params: {
        include: [
          'customField',
          'formQuestionType',
          'options',
        ],
      },
    };

    return formQuestionStore.show(questionId, payload)
        .finally(() => {
          isLoading.value = false;
        });

  }

  function loadGroups() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    groupStore.index(payload)
      .then(() => {
      }).finally(() => {
      });
  }

  function loadTrades() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    tradeStore.index(payload)
      .then(() => {
      }).finally(() => {
      });
  }

  watch(availableQuestions, () => {
    if (!availableQuestions.value.find(question => question.id === 'upload')) {
      formQuestions.value = formQuestions.value.map(question => {
        const id = `upload_${++uploadFileCount.value}`;
        return question.id === 'upload' ? { ...question, id: id, question_id: id } : question;
      });

      availableQuestions.value.unshift(uploadFileObj);
    }
  });

  watch(() => _.cloneDeep(formQuestions.value), () => {
    emit('update', formQuestions.value);
  });

  watch(requireAll, () => {
    formQuestions.value = formQuestions.value.map((question) => {
      return {
        ...question,
        is_required: question.is_instruction_type ? false : requireAll.value,
      };
    });
  });

</script>

<style lang="scss" scoped>

.form-questions {

  .question {
    cursor: move;
    border: #eeeeee 5px solid;
    padding: 3px;

    &.attention-needed {
      border-color: $danger;
    }

    .question-drag {
      min-width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .question-system {
      min-width: 30px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 3px;
    }

    .question-text {
      width: 100%;
      margin-right: 5px;
      display: inline-grid;
      overflow-x: auto;
    }

    .question-actions {
      min-width: 35px;
      margin-right: 8px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .action-icon {
        background-color: transparent;
        border: 0;
        cursor: pointer;
      }
    }

    .question-view {
      border-top: $grey-lighter 1px solid;
      margin-top: 1em;

      .question-drag {
        .icon {
          color: transparent !important;
        }
      }

      .question-preview {
      }
    }
  }

  .form-question-modal {
    margin-left: 13em;
    margin-bottom: 3em;
  }

  .target-area {
    height: 100%;
  }
}

</style>

<style lang="scss">

.form-questions {

  .modal {
    .animation-content {
      margin-bottom: 5em !important;
    }
  }
}

</style>