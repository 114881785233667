import {defineStore} from "pinia";
import {deserialize, serialize} from "jsonapi-fractal";
import FormQuestionAPI from "@/api/FormQuestionAPI";

export const useFormQuestionStore = defineStore('form-question', {
  state: () => ({
    data: [],
  }),
  actions: {
    clear() {
      this.data = [];
    },
    find(id) {
      return this.data.find(data => parseInt(data.id) === parseInt(id)) || null;
    },
    index(payload) {
      const { params } = payload;

      return new FormQuestionAPI().index(params)
      .then((response) => {
        this.data = response.data.map((data) => {
          return {
            ...data,
            archived: !data.active,
          };
        });
        return response;
      });
    },
    show(id, payload) {
      const { params } = payload;

      return new FormQuestionAPI().show(id, params)
        .then((response) => {
          const obj = {
            ...response.data,
            archived: !response.data.active,
          };

          let index = 0;
          for (index = 0; index < this.data.length; index++) {
            if (parseInt(this.data[index].id) === parseInt(id)) {
              this.data[index] = {
                ...obj,
              }

              break;
            }
          }
          if (index === this.data.length) {
            this.data[index] = obj;
          }

          return obj;
        });
    },
    store(payload) {
      return new FormQuestionAPI().store(payload)
        .then((response) => {
          return response;
        });
    },
    update(payload) {
      const { id, params, ...rest } = payload;

      return new FormQuestionAPI().update(id, rest)
        .then((response) => {
          return response;
        });
    },
    archive(id) {
      return new FormQuestionAPI().archive(id)
        .then((response) => {
          return response;
        });
    },
    restore(id) {
      return new FormQuestionAPI().restore(id)
        .then((response) => {
          return response;
        });
    },
    delete(id) {
      return new FormQuestionAPI().delete(id)
        .then((response) => {
        });
    },
  },
  persist: true,
});
