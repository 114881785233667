<template>
    <o-menu class="nav-menu" :class="{ 'is-dark': dark }">
      <div :label="menu.title || ' '" class="menu-list">
        <template v-for="(item, i) in menu.menuItems">
          <div class="ps-icon nav-menu-item-icon" v-if="item.usePsIcon">
            <img src="../assets/images/psIconLight.svg" :alt="item.title">
          </div>
          <figure class="item-avatar image is-32x32" v-else-if="item.imageUrl">
            <img :src="item.imageUrl" :alt="item.title" class="squircle">
          </figure>
          <NavMenuItem :item="item"
                       v-else
          />
        </template>
      </div>
    </o-menu>
</template>

<script setup>

  import { ref, computed, onMounted, toRefs, watch } from "vue";
  import { useRoute, useRouter } from "vue-router";

  import NavMenuItem from '@/components/NavMenuItem.vue';

  import {
    Menu,
  } from '@/internal';

  let numUnreadNotifications = ref(0);
  const currentRoute = useRoute();
  const router = useRouter();

  const props = defineProps({
    dark: {
      required: false,
      type: Boolean,
      default: false,
    },
    menu: {
      required: true,
      type: Menu,
      default: () => new Menu(),
    }
  });
  const {
    dark,
    menu,
  } = toRefs(props);

  function handleMenuItem(route) {
    router.push({
      name: route.name,
    });
  }

</script>

<style lang="scss">

    .nav-menu {
        background: $primary-light;
        padding: 25px 0;

        li {
          list-style-type: none;
        }

        .menu-item {
          margin-top: 3px;
          margin-bottom: 3px;
          display: flex;

          .icon {
            line-height: 1em;
          }

          span:nth-child(2) {
            vertical-align: top;
            margin-left: .3em;
          }

          &.item-active,
          &:hover:not(.has-ps-icon):not(.has-image),
          &.router-link-exact-active:not(.has-ps-icon):not(.has-image) {
            background: $primary-lighter;
            border-radius: 5px;

            &:hover:not(.has-ps-icon):not(.has-image) {
              background: $primary-light2;
            }
          }

          &.icon-only {
            width: 40px;
            height: 40px;
            justify-content: center;

            &.item-active,
            &:hover:not(.has-ps-icon):not(.has-image),
            &.router-link-exact-active:not(.has-ps-icon):not(.has-image) {
              //background: $primary-dark;
              border-radius: 50%;
            }
          }

        }

        .nav-menu-item {
          padding: 0;

          &.has-ps-icon {
            margin-bottom: 10px;
          }

          &.has-ps-icon:hover,
          &.has-image:hover {
            background-color: transparent;
          }

        }

        .item-active {
          //border-radius: 50%;
          //background: $primary-dark;
          //color: $white;
        }

        .sub-menu {
          border-color: $primary-lighter;
          margin-top: 0;
          display: block !important;
        }

      &.is-dark {
        background: $primary;

        .menu-item {
          color: $white;

          &.item-active {
            background: $primary-dark;
          }
        }

        &.router-link-exact-active:not(.has-ps-icon):not(.has-image) {
          //background: $secondary;//$primary-dark;
          //color: $white;
        }

        .nav-menu-item {
            background: $primary;

            &.router-link-exact-active:not(.has-ps-icon):not(.has-image) {
              background: $primary;
              color: $white;
            }
          }
      }

      .nav-menu-item-icon {
        align-items: center;
        display: flex;
        justify-content: center;

        &.ps-icon {

          margin-bottom: 1em;

          img {
            left: 2.5px;
            position: relative;
            width: 24px;
          }
        }

        .icon {
          line-height: 1em;

          i {
            position: relative;
            top: 1px;
          }
        }
      }
    }

</style>