import Menu from '@/use/Menu';

export default (payload = {}) => {

  const {
    handleFavoritesClick,
    handleSearchClick,
    handleAddClick,
    hideTitles,
  } = payload;

  const hideTitle = hideTitles !== undefined ? hideTitles : true;

  return new Menu({
    menuItems: [
      {
        hideTitle,
        route: {
          name: 'overview',
        },
        title: 'Overview',
        usePsIcon: true,
      },
      {
        hideTitle,
        icon: 'star',
        onClick: handleFavoritesClick,
        title: 'Recents',
        iconPack: 'fal',
      },
      {
        hideTitle,
        icon: 'search',
        onClick: handleSearchClick,
        title: 'Search',
        iconPack: 'fal',
      },
      {
        hideTitle,
        icon: 'plus',
        onClick: handleAddClick,
        title: 'Add',
        iconPack: 'fal',
      },
    ],
  });
}