import { createRouter, createWebHistory } from "vue-router";
import AppRoutes from "@/router/AppRoutes";
import AuthRoutes from "@/router/AuthRoutes";
import FormRoutes from "@/router/FormRoutes";
import QuestionRoutes from "@/router/QuestionRoutes";
import ResponseRoutes from "@/router/ResponseRoutes";
import SubRoutes from "@/router/SubRoutes";
import SystemRoutes from "@/router/SystemRoutes";
import Auth from "@/auth";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...AppRoutes,
    ...AuthRoutes,
    ...FormRoutes,
    ...QuestionRoutes,
    ...ResponseRoutes,
    ...SubRoutes,
    ...SystemRoutes,
  ]
});

router.beforeEach((to, from, next) => {

  if(to.meta.requiresAuth && !Auth.isAuthenticated()) {
    Auth.authenticate();
  } else {
    next()
  }
});

export default router;
