<template>
    <div id="menu-panel-wrapper">
        <div class="mask" :class="{ 'is-active': active }" />
        <div id="menu-panel" :class="{ 'is-active': active }">
            <div id="menu-panel-content" v-if="active" v-on-click-outside="close">
                <div id="close-button" @click="close">
                    <o-icon icon="arrow-left" />
                </div>
                <FavoritesMenuPanel v-if="activeMenuPanel === 'favorites'" :recents="recentRoutes" />
                <AccountMenuPanel v-if="activeMenuPanel === 'account'" />
                <AddMenuPanel v-else-if="activeMenuPanel === 'add'" />
                <HelpMenuPanel v-else-if="activeMenuPanel === 'help'" @close="close" />
                <SearchMenuPanel v-else-if="activeMenuPanel === 'search'" />
                <SuperMenuPanel v-else-if="activeMenuPanel === 'super'" @close="close" />
            </div>
        </div>
    </div>
</template>

<script setup>

import { vOnClickOutside } from '~/@vueuse/components';

import AccountMenuPanel from './MenuPanels/AccountMenuPanel.vue';
import AddMenuPanel from './MenuPanels/AddMenuPanel.vue';
import FavoritesMenuPanel from './MenuPanels/FavoritesMenuPanel.vue';
import HelpMenuPanel from './MenuPanels/HelpMenuPanel.vue';
import SearchMenuPanel from './MenuPanels/SearchMenuPanel.vue';
import SuperMenuPanel from './MenuPanels/SuperMenuPanel.vue';

import {
  useFormStore,
  useFormQuestionStore,
  useFormResponseStore,
  useSystemStore,
} from "@/internal";

import {ref, computed, watch} from "vue";
import { useRoute } from "vue-router";

const currentRoute = useRoute();
const formStore = useFormStore();
const formQuestionStore = useFormQuestionStore();
const formResponseStore = useFormResponseStore();
const systemStore = useSystemStore();
const recentRoutes = ref([]);

const active = computed(() => systemStore.navPanelOpen);

const activeMenuPanel = computed(() => systemStore.navPanel);

const currentRouteName = computed(() => currentRoute.name);

function close() {
  systemStore.toggleNavPanel('');
}

watch(currentRouteName, () => {

  const newRoute = {
    ...currentRoute,
  };

  if (currentRoute.meta?.useModelForTitle) {
    let title = null;

    if (currentRoute.meta.useModelForTitle === 'form') {
      const id = currentRoute.params.formId;
      title = formStore.find(id)?.name || null;
    }
    else if (currentRoute.meta.useModelForTitle === 'form-question') {
      const id = parseInt(currentRoute.params.questionId);
      title = formQuestionStore.find(id)?.question || null;
    }
    else if (currentRoute.meta.useModelForTitle === 'form-response') {
      const id = currentRoute.params.responseId;
      title = formResponseStore.find(id)?.title || null;
    }

    if (title) {
      newRoute.title = title;
    }
  }

  // Keep track of the last 10 page views
  const prevRoutes = recentRoutes.value.filter(route => route.path !== currentRoute.path);
  prevRoutes.unshift(newRoute);
  recentRoutes.value = prevRoutes.slice(0, 10);

  close();
});

</script>

<style scoped lang="scss">

    #menu-panel-wrapper {
        bottom: 0;
        left: 0;
        position: fixed;
        top: 0;
        z-index: 3000;
    }

    #menu-panel {
        background: $primary-light;
        bottom: 0;
        display: flex;
        left: 0;
        padding: 15px;
        position: fixed;
        top: 0;
        transition: all .2s;
        transform: translateX(-100%);
        width: 320px;

        @include mobile {
            width: calc(100% - 50px);
        }

        &.is-active {
            transform: translateX(0);
        }

        #menu-panel-content {
            flex: 1;
            overflow: auto;
        }

        #close-button {
            border-radius: 50%;
            display: inline-block;
            padding: 5px;

            &:hover {
                background: $grey-lighter;
            }

            .icon {
                align-items: center;
                display: flex;
                height: 24px;
                justify-content: center;
                position: relative;
                top: 1px;
                width: 24px;
            }
        }
    }
</style>