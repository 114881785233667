<template>
  <Page id="response-show" :title="pageTitle" v-if="response">
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        :can-cancel="true"
        icon="rotate"
        icon-size="large"
    />

    <template v-slot:subtitle>
      <div class="page-subtitle">
        <div class="is-inline-flex">
          <o-icon :icon="statusIcon" pack="fas" variant="secondary" class="has-margin-right" />
          <div class="text">{{ statusLabel }}</div>
        </div>
        <div class="is-flex is-flex-direction-column has-margin-top" v-if="response.has_workflow">
          <div class="is-flex is-align-items-center has-margin-bottom" @click="toggleWorkflowHistory">
            <span class="label workflow" v-if="workflowCompleted">Workflow Completed</span>
            <template v-else>
              <div class="label workflow has-margin-right">Workflow Step:</div>
              <div>{{workflowStepLabel}}</div>
            </template>
            <o-icon class="has-margin-left-small" :icon="showWorkflowHistory ? 'caret-down' : 'caret-up'" pack="fas" variant="secondary" v-if="formWorkflowSteps.length" />

          </div>
          <div v-if="showWorkflowHistory && formWorkflowSteps.length" class="box">
            <div v-for="step in formWorkflowSteps">
              <div><span class="label workflow">{{ step.workflowStep.label }}:</span> {{workflowStepDetails(step)}}</div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:addon>
      <o-tooltip label="Download PDF" position="right" variant="secondary">
        <div class="button is-secondary has-margin-top" @click="handlePDF">
          <o-icon icon="file-pdf" pack="fas" size="large" />
        </div>
      </o-tooltip>
    </template>

    <template v-slot:controls>
      <div class="buttons is-flex-wrap-nowrap">
        <div class="button is-primary" @click="handleInbox" v-if="canInbox">
          <o-icon icon="inbox-in" pack="fas" class="has-margin-right" />
          Move to Inbox
        </div>
        <div class="button is-primary" @click="handleReview" v-if="canReview">
          <o-icon icon="file-pen" pack="fas" class="has-margin-right" />
          Review
        </div>
        <div class="button is-primary" @click="handleApprove" v-if="canApprove">
          <o-icon icon="thumbs-up" pack="fas" class="has-margin-right" />
          Approve
        </div>
        <div class="button is-primary" @click="handleDecline" v-if="canDecline">
          <o-icon icon="thumbs-down" pack="fas" class="has-margin-right" />
          Decline
        </div>
        <div class="button is-primary" @click="handleSendBack" v-if="canSendBack">
          <o-icon icon="arrow-turn-down-left" pack="fas" class="has-margin-right" />
          Send Back
        </div>
        <div class="button is-primary" @click="handleDelete" v-if="canDelete">
          <o-icon icon="trash-alt" pack="fas" class="has-margin-right" />
          Delete
        </div>
      </div>
      <div class="buttons is-flex-wrap-nowrap">
        <ViewRFQCompany :company-id="response?.company?.id" class="has-margin-right" />
        <router-link class="button is-secondary" :to="`/responses/${statusPath}/${responseId}/log`" v-if="response?.company && response.emails_exist">
          <o-icon icon="list-check" class="has-margin-right" />
          View Log
        </router-link>
      </div>
    </template>

    <div class="columns">
      <div class="column is-two-thirds">
<!--    <div class="is-flex" v-if="isSentBack && response.response_url">-->
<!--      <div class="label has-margin-right">Sub's Edit Response URL:</div>-->
<!--      <div><a :href="response.response_url" target="_blank">{{response.response_url}}</a></div>-->
<!--    </div>-->
        <div class="is-flex">
          <div class="label has-margin-right">Submitted:</div>
          <div>{{response.submitted_at}}</div>
        </div>
        <div class="is-flex">
          <div class="label has-margin-right">Form Type:</div>
          <div>{{formTitle}}</div>
        </div>

        <div class="has-margin-top">
          <HTMLText v-if="formInstructions" :value="'<b>Instructions:</b> ' + formInstructions" class="has-margin-bottom-large" />
          <div class="columns alt-row">
            <div class="column is-9">
            </div>
            <div class="column is-3">
              <div class="is-flex">
                <div class="label has-margin-right">Total: </div>
                <div>{{totalScore}}</div>
              </div>
              <div class="is-link is-flex is-secondary" @click="showScores = !showScores">
                <div class="scores is-secondary">Scores</div>
                <o-icon class="has-margin-left-small" :icon="showScores ? 'caret-down' : 'caret-up'" pack="fas" variant="secondary" />
              </div>
            </div>
          </div>
          <template v-for="(question, index) in questions">
            <div class="columns" :class="{'alt-row': index % 2 !== 0}">
              <div class="column" :class="showScores ? 'is-9' : 'is-12'">
                <QuestionAnswer :question="question" :answers="questionAnswers(question.id)" :company="response?.company" />
              </div>
              <div class="column is-3 score-input" v-if="showScores">
                <o-loading v-model:active="isLoadingScores[questionAnswers(question.id).id]" :full-page="false"
                           icon="rotate"
                           icon-size="small"
                />
                <o-input v-model="questionScores[questionAnswers(question.id).id]"
                         @blur="handleScore(questionAnswers(question.id).id)"
                         type="number"
                         step=".01" />
              </div>
            </div>
          </template>
          <div class="columns" :class="{'alt-row': questions.length % 2 !== 0}">
            <div class="column is-8">
            </div>
            <div class="column is-4 is-flex">
              <div class="label has-margin-right">Total Score: </div>
              <div>{{totalScore}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-third">
        <CommentForm :responseId="responseId" :can-add-company-note="canAddCompanyNote" />
      </div>
    </div>
  </Page>

  <o-modal v-model:active="sendEmailActive">
    <div class="label has-margin-bottom">Would you like to Email Submitter?</div>

    <div class="box">
      <SendEmail :message="emailMessage" :include-button="false" @update="handleSendEmailUpdate" />
    </div>

<!--    <div class="label has-margin-top-large">Attachments</div>-->
<!--    <FileUploadTree :files="files"-->
<!--                    :api-url="formFileApiUrl"-->
<!--                    :upload-multipart-params="uploadMultipartParams"-->
<!--                    @uploaded="handleUploadedFile"-->
<!--                    :rename-file="handleFileUpdate"-->
<!--                    :delete-file="handleFileDelete"-->
<!--    />-->

    <div class="is-flex is-justify-content-flex-end has-margin-top-large">
      <o-button label="No Email" variant="primary" class="has-margin-right" @click="handleSendEmailClose" />
      <o-button label="Send Email" variant="primary" @click="handleSendEmail" />
    </div>
  </o-modal>
</template>

<script setup>

import {useRoute, useRouter} from "vue-router";

  import CommentForm from "@/components/CommentForm.vue";
  import HTMLText from "@/components/HTMLText.vue";
  import Page from "@/components/Page.vue";
  import QuestionAnswer from "@/components/QuestionAnswer.vue";
  import SendEmail from "@/components/SendEmail.vue";
  import ViewRFQCompany from "@/components/ViewRFQCompany.vue";

  import {
    Dialog,
    findRouteByName,
    NotificationError,
    NotificationSuccess,
    useFormStore,
    useFormResponseStore,
    useGroupStore,
    useTradeStore,
    userHasPermission,
  } from "@/internal";
  import {computed, onMounted, ref, watch} from "vue";
  import FileUploadTree from "@/components/FileUploadTree.vue";
  import {useFormAnswerStore} from "@/stores/FormAnswerStore.js";

  const formStore = useFormStore();
  const formAnswerStore = useFormAnswerStore();
  const formResponseStore = useFormResponseStore();
  const groupStore = useGroupStore();
  const tradeStore = useTradeStore();
  const route = useRoute();
  const router = useRouter();
  const emailMessage = ref('');
  const files = ref([]);
  const isLoading = ref(false);
  const isLoadingScores = ref({});
  const questionScores = ref({});
  const sendEmailActive = ref(false);
  const showWorkflowHistory = ref(false);
  const showScores = ref(false);

  const response = computed(() => {
    return formResponseStore.find(responseId.value);
  });

  const responseId = computed(() => {
    return route.params.responseId || null;
  });

  const pageTitle = computed(() => {
    return response.value ? response.value.title : '';
  });

  const answers = computed(() => {
    return (response.value && response.value.answers) || [];
  });

  const canAddCompanyNote = computed(() => !!response.value?.company);

  const canApprove = computed(() => {
    return userHasPermission('formResponse:update') && !isSentBack.value && (isPendingInReview.value || (response.value.has_workflow && !workflowCompleted.value));
  });

  const canDecline = computed(() => {
    return userHasPermission('formResponse:update') && isPendingInReview.value && !isSentBack.value
        && (!workflowStep.value || workflowStep.value.allow_decline);
  });

  const canDelete = computed(() => {
    return userHasPermission('formResponse:delete') && response.value.status_key === 'declined';
  });

  const canInbox = computed(() => {
    return userHasPermission('formResponse:update') && (response.value.status_key === 'declined' || isSentBack.value);
  });

  const canReview = computed(() => {
    return userHasPermission('formResponse:update') && !isSentBack.value && response.value.status_key !== 'in_review' && (response.value.status_key === 'pending' || (response.value.has_workflow && !workflowCompleted.value));
  });

  const canSendBack = computed(() => {
    return userHasPermission('formResponse:update') && !isSentBack.value && response.value.can_email && (!workflowStep.value || workflowStep.value.allow_decline_send_back);
  });

  const comments = computed(() => response.value.comments || []);

  const formId = computed(() => response.value?.form_id || null);

  const formFileApiUrl = computed(() => `${import.meta.env.VITE_APP_API_URL}/forms/${formId.value}/files`);

  const formInstructions = computed(() => response.value.form ? response.value.form.instructions : null);

  const formTitle = computed(() => {
    return response.value && response.value.form ? response.value.form.title : '';
  });

  const pdfTitle = computed(() => {
    return response.value && response.value.form ? `${response.value.title} - ${response.value.form.title}` : '';
  });

  const isPendingInReview = computed(() => response.value.status_key === 'pending' || response.value.status_key === 'in_review');

  const isSentBack = computed(() => response.value.status_key === 'sent_back');

  const questions = computed(() => {
    return (response.value?.form?.questions?.sort((a, b) => a.rank > b.rank ? 1 : -1)) || [];
  });

  const canViewComment = computed(() => {
    return userHasPermission('formResponseComment:read') && (!workflowStep.value || workflowStep.value.allow_comments || isSentBack.value);
  });

  const statusIcon = computed(() => {
    switch (response.value.status_key) {
      case 'pending':   return 'inbox-in';
      case 'in_review': return 'file-pen';
      case 'approved':  return 'thumbs-up';
      case 'declined':  return 'thumbs-down';
      case 'sent_back':  return 'arrow-turn-down-left';
    }
  });

  const statusLabel = computed(() => {
    switch (response.value.status_key) {
      case 'pending':   return 'Inbox';
      case 'in_review': return 'In Review';
      case 'approved':  return 'Approved';
      case 'declined':  return 'Declined';
      case 'sent_back':  return 'Sent Back';
    }
  });

  const statusPath = computed(() => response.value.status_key.replaceAll('_', '-').replaceAll('pending', 'inbox'));

  const onLastWorkflowStep = computed(() => response.value?.formResponseWorkflow?.on_last_step || false);

  const uploadMultipartParams = computed(() => {
    return {
      form_id: formId.value,
      opr_file_type_id: 5,
    };
  });

  const totalScore = computed(() => {
    let total = 0;
    Object.values(questionScores.value).forEach(score => {
      if (score) {
        total = total + parseFloat(score);
      }
    });
    return Math.round((total + Number.EPSILON) * 100) / 100;
  });

  const workflowStep = computed(() => (!workflowCompleted.value && response.value.formResponseWorkflow?.activeStep) || null);

  const workflowCompleted = computed(() => response.value.formResponseWorkflow && response.value.formResponseWorkflow.is_complete);

  const workflowStepLabel = computed(() => workflowStep.value?.label || '');

  const formWorkflowSteps = computed(() => response.value?.formResponseWorkflowSteps || []);

  onMounted(async () => {
    loadGroups();
    loadTrades();
    await loadResponse();
  });

  function goToList() {
    const status = response.value.status_key.replace("_", "-");
    const route = findRouteByName(`responses/${status}`);
    route.params = {
      ...route.params,
      responseId: responseId.value,
    };
    router.push(route);
  }

  function handleApprove() {
    handleStatusUpdate({ status: 'approved' });

    emailMessage.value = '';
    if (response.value.can_email && (!response.value.formResponseWorkflow || (response.value.formResponseWorkflow && onLastWorkflowStep.value))) {
      files.value = [];
      emailMessage.value = 'We have reviewed and approved your submission.';
      sendEmailActive.value = true;
    }
  }

  function handleDecline() {
   handleStatusUpdate({ status: 'declined' });

    emailMessage.value = '';
    if (response.value.can_email) {
      files.value = [];
      emailMessage.value = 'We appreciate your submission. Unfortunately at this time your submission has been declined.';
      sendEmailActive.value = true;
    }
  }

  function handleDelete() {
    Dialog({
      title: 'Delete Response',
      message: `Are you sure you want to delete this response?`,
      confirmText: 'Yes',
      canCancel: true,
      variant: 'warning',
      onCancel: () => {
      },
      onConfirm: () => {

        isLoading.value = true;

        formResponseStore.delete(response.value.id).then(() => {
          NotificationSuccess({
            message: `Response Deleted`,
          });

          goToList();
        }).catch((error) => {
          if (error.errors) {
            NotificationError({}, error);
          }
          else {
            NotificationError({
              message: error,
            });
          }
        }).finally(() => {
          isLoading.value = false;
        });
      },
    });
  }

  function handleFileDelete(fileToDelete) {
    files.value = files.value.filter(file => parseInt(file.id) !== parseInt(fileToDelete.id));
  }

  function handleFileUpdate(file, newName) {
    const payload = {
      name: newName,
    };

    return formStore.updateFile(formId.value, file.id, payload)
        .then((response) => {
          files.value = files.value.map(file => parseInt(file.id) === parseInt(response.data.id) ? response.data : file);
        });
  }

  function handleUploadedFile(file, uploadedResponse) {
    if (uploadedResponse?.response?.data) {
      files.value.push(uploadedResponse.response.data);
    }
  }

  function handleInbox() {
    handleStatusUpdate({ status: 'pending' });
  }

  function handlePDF() {

    isLoading.value = true;

    const payload = {
      params: {
        path: 'prequal/pdf',
      },
    };

    formResponseStore.pdf(responseId.value, pdfTitle.value, payload).then((response) => {
    }).catch((error) => {

    }).finally(() => {
      isLoading.value = false;
    });
  }

  function handleReview() {
    handleStatusUpdate({ status: 'in_review' });
  }

  function handleScore(answerId) {
    questionScores.value[answerId] = questionScores.value[answerId] !== null ? Math.round((questionScores.value[answerId] + Number.EPSILON) * 100) / 100 : null;

    isLoadingScores.value[answerId] = true;

    const payload = {
      params: {
        score: questionScores.value[answerId],
      },
    };

    formAnswerStore.update(answerId, payload)
      .then((response) => {
        questionScores.value[answerId] = response.data.score;
      }).catch((error) => {
        if (error.errors) {
          NotificationError({}, error);
        }
        else {
          NotificationError({
            message: error,
          });
        }
      }).finally(() => {
        isLoadingScores.value[answerId] = false;
      });
  }

  function handleSendBack() {
    const route = findRouteByName(`responses/show/send-back`);
    route.params = {
      ...route.params,
      responseId: responseId.value,
    };
    router.push(route);
  }

  function handleSendEmail() {

    if (!emailMessage.value) {
      NotificationError({
        message: "Email Message is Required",
      });

      return;
    }

    isLoading.value = true;

    const payload = {
      params: {
        email_message: emailMessage.value,
        file_id: files.value.map(file => file.id) || [],
      },
    };

    formResponseStore.sendEmail(responseId.value, payload)
        .then((response) => {
          NotificationSuccess({
            message: `Email Sent`,
          });
        }).catch((error) => {
      if (error.errors) {
        NotificationError({}, error);
      }
      else {
        NotificationError({
          message: error,
        });
      }
    }).finally(() => {
      sendEmailActive.value = false;
      emailMessage.value = '';
      isLoading.value = false;
    });
  }

  function handleSendEmailClose() {
    sendEmailActive.value = false;
    emailMessage.value = '';
  }

  function handleSendEmailUpdate(message) {
    emailMessage.value = message;
  }

  function handleStatusUpdate(params={}) {

    isLoading.value = true;

    const payload = {
      params: {
        ...params,
      },
    };

    formResponseStore.updateStatus(responseId.value, payload)
      .then(async () => {
        NotificationSuccess({
          message: `Response Updated`,
        });

        await loadResponse();

        if (response.value.formResponseWorkflow && !response.value.formResponseWorkflow.is_complete && isPendingInReview.value) {
          const route = findRouteByName(`overview`);
          router.push(route);
        }
      }).catch((error) => {
      if (error.errors) {
        NotificationError({}, error);
      }
      else {
        NotificationError({
          message: error,
        });
      }
      }).finally(() => {
        isLoading.value = false;
      });
  }

  function loadResponse(showIsLoading=true) {

    if (showIsLoading) {
      isLoading.value = true;
    }

    const payload = {
      params: {
        include: [
          'answers.values',
          'comments',
          'company.groups',
          'company.primaryContact',
          'company.trades',
          'form.questions.customField',
          'form.questions.options',
          'formResponseWorkflow.activeStep',
          'formResponseWorkflowSteps.user',
          'formResponseWorkflowSteps.workflowStep',
        ],
        include_can_email: true,
      },
    };

    return formResponseStore.show(responseId.value, payload)
      .then(() => {
        questionScores.value = {};
        isLoadingScores.value = {};
        answers.value.forEach((answer) => {
          questionScores.value[answer.id] = answer.score;
          isLoadingScores.value[answer.id] = false;
        });
      }).catch((error) => {
        if (error.errors) {
          NotificationError({}, error);
        }
        else {
          NotificationError({
            message: error,
          });
        }
      }).finally(() => {
        if (showIsLoading) {
          isLoading.value = false;
        }
      });
  }

  function loadGroups() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    groupStore.index(payload)
        .then(() => {
        }).finally(() => {
    });
  }

  function loadTrades() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    tradeStore.index(payload)
        .then(() => {
        }).finally(() => {
    });
  }

  function questionAnswers(joinID) {
    return answers.value.find((answer) => parseInt(answer.join_id) === parseInt(joinID)) || [];
  }

  function toggleWorkflowHistory() {
    showWorkflowHistory.value = !showWorkflowHistory.value;
  }

  function workflowStepDetails(step) {
    const status = step.is_approved ? 'Approved' : (step.is_declined ? 'Declined' : (step.is_sent_back ? 'Sent Back' : ''));
    return `${status} on ${step.completed_at}` + (step.user ? ` by ${step.user.first_name} ${step.user.last_name}` : ``);
  }

</script>

<style lang="scss" scoped>

#response-show {
  .page-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }

  .alt-row {
    background-color: #f4f9fd;
  }

  .label {
    &.workflow {
      font-weight: 700;
      margin-bottom: unset;
    }
  }

  .scores {
    &.is-secondary {
      color: $secondary;
    }
  }

  .score-input {
    position: relative;
  }
}
</style>

<style lang="scss">

#response-show {
  .body-horizontal-class {
    .field {
      text-align: left;
    }
  }

  .label-horizontal-class {
    flex-basis: 300px;
    flex-grow: unset;
    flex-shrink: unset;

    .label {
      margin-top: 0;
      text-align: left;
      line-height: normal;
    }
  }
}

</style>
