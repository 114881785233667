export default class MenuItem {
  constructor(config = {}) {

    const routeMeta = config.route && config.route.meta ? config.route.meta : {};

    this.activeWithParent = config.activeWithParent || false;
    this.count = config.count || null;
    this.displayInactive = config.displayInactive || false;
    this.hideTitle = config.hideTitle || false;
    this.icon = config.icon || routeMeta.icon || null; // icon prop
    this.iconPack = config.iconPack || routeMeta.iconPack || null; // icon prop
    this.imageUrl = config.imageUrl || null;
    this.menuItems = config.menuItems ? config.menuItems.filter(menuItem => menuItem.when === true || menuItem.when === null || !menuItem.hasOwnProperty('when')).map(menuItem => new MenuItem(menuItem)) : [];
    this.notificationCount = config.notificationCount || false;
    this.onClick = config.onClick || null;
    this.route = config.route || {}; // Vue Router Route
    this.secondaryMenuActive = config.secondaryMenuActive || false;
    this.title = config.title || routeMeta.title || '';
    this.usePsIcon = config.usePsIcon || false;
    this.userId = config.userId || null;
    this.when = config.when !== undefined ? config.when : null;
  }

  get shouldShowTitle() {
    return this.title && !this.hideTitle;
  }
}