<template>
    <nav id="navigation-global" class="dark">
        <div class="is-top">
          <NavMenu dark :menu="menu" />
          <NavMenu dark :menu="primaryMenu" />
        </div>

        <div class="is-bottom">
          <NavMenu dark :menu="bottomMenu" />
        </div>
    </nav>
</template>

<script setup>

  import { ref, computed, onMounted, watch } from "vue";

  import {
    findRouteByName,
    useAuthStore,
    useSystemStore,
  } from "@/internal";

  import globalMenu from '@/menus/global';
  import globalPrimaryMenu from '@/menus/global-primary';
  import NavMenu from '@/components/NavMenu.vue';
  import userMenu from '@/menus/user';
  import {useRoute, useRouter} from "vue-router";

  const authStore = useAuthStore();
  const systemStore = useSystemStore();
  const route = useRoute();
  const router = useRouter();
  const toggleNavPanel = systemStore.toggleNavPanel;
  const user = authStore.me;

  const formId = computed(() => {
    return route.params.formId || null;
  });

  const menu = computed(() => {
    return globalMenu({
      handleFavoritesClick: () => toggleNavPanel('favorites'),
      handleSearchClick: () => toggleNavPanel('search'),
      handleAddClick: () => toggleNavPanel('add'),
    });
  });

  const primaryMenu = computed(() => {
    return globalPrimaryMenu({
      formId: formId,
      user: user,
    });
  });

  const bottomMenu = computed(() => {
    return userMenu({
      handleAccountClick: () => toggleNavPanel('account'),
      handleHelpClick: () => toggleNavPanel('help'),
      handleSystemPreferencesClick: async () => {
        const route = findRouteByName('settings/system-preferences');
        await router.push(route);
      },
      handleLogOutClick: async () => {
        const route = findRouteByName('logout');
        await router.push(route);
      },
      handleSuperClick: () => toggleNavPanel('super'),
      user: user,
    });
  });

</script>

<style lang="scss" scoped>
    #navigation-global {
        background: $primary-light;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px 12.5px;
        z-index: 2000;

        &.dark {
          background: $primary;
        }
    }
</style>