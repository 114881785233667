import {defineStore} from "pinia";
import EmailLogAPI from "@/api/EmailLogAPI";
import {
  utcToLocal,
} from "@/internal";

export const useEmailLogStore = defineStore('email-log', {
  state: () => ({
    data: [],
  }),
  actions: {
    clear() {
      this.data = [];
    },
    find(id) {
      return this.data.find(data => parseInt(data.id) === parseInt(id)) || null;
    },
    index(payload) {
      const { params } = payload;

      return new EmailLogAPI().index(params)
      .then((response) => {
        this.data = response.data.map((data) => {
          return {
            ...data,
            sent_on: utcToLocal(data.sent_on),
            events: data.events?.map(event => {
              return {
                ...event,
                event_time: utcToLocal(event.event_time),
              };
            }),
          };
        });
        return response;
      });
    },
    export(name, payload) {
      const { params } = payload;

      return new EmailLogAPI().export(name, params)
        .then((response) => {
        });
    },
  },
  persist: true,
});
