import {defineStore} from "pinia";
import TradeAPI from "@/api/TradeAPI";

export const useSubTradeStore = defineStore('sub-trade', {
  state: () => ({
    data: [],
  }),
  actions: {
    find(id) {
      return this.data.find(data => parseInt(data.id) === parseInt(id)) || null;
    },
    index(payload) {
      const { params } = payload;
      params.prependURL = 'subs/';

      return new TradeAPI().index(params)
      .then((response) => {
        this.data = response.data;
        return response;
      });
    },
  },
  persist: true,
});
