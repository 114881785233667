import {defineStore} from "pinia";
import TradeAPI from "@/api/TradeAPI";

export const useTradeStore = defineStore('trade', {
  state: () => ({
    data: [],
  }),
  actions: {
    clear() {
      this.data = [];
    },
    find(id) {
      return this.data.find(data => parseInt(data.id) === parseInt(id)) || null;
    },
    index(payload) {
      const { params } = payload;

      return new TradeAPI().index(params)
      .then((response) => {
        this.data = response.data;
        return response;
      });
    },
  },
  persist: true,
});
