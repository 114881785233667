<template>
  <o-tooltip :label="value" multiline>
    <div @click="handleClick" class="name-column is-link truncate">
        {{value}}
    </div>
  </o-tooltip>
</template>

<script setup>

  import {toRefs} from "vue";

  const props = defineProps( {
    row: {
      required: true,
    },
    value: {
      required: true,
    },
  });
  const {
    row,
    value,
  } = toRefs(props);

  const emit = defineEmits([
    'click',
  ]);

  function handleClick() {
    emit('click', row.value);
  }

</script>

<style scoped lang="scss">
    .name-column {
      max-width: 200px;
    }
</style>