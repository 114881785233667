import {defineStore} from "pinia";
import {deserialize, serialize} from "jsonapi-fractal";
import FormAPI from "@/api/FormAPI";
import {
  utcToLocal,
} from "@/internal";

export const useSubFormStore = defineStore('sub-form', {
  state: () => ({
    data: [],
  }),
  actions: {
    find(id) {
      return this.data.find(data => parseInt(data.id) === parseInt(id)) || null;
    },
    show(id, payload) {
      const { params } = payload;
      params.prependURL = 'subs/';

      return new FormAPI().show(id, params)
        .then((response) => {
          return this.updateData(id, response);
        });
    },
    updateData(id, response) {
      const obj = {
        ...response.data,
        updated_at: utcToLocal(response.data.updated_at),
        emails: response.data.emails?.map(email => {
          return {
            ...email,
            sent_on: utcToLocal(email.sent_on),
          };
        }),
      };

      let index = null;
      for (index = 0; index < this.data.length; index++) {
        if (parseInt(this.data[index].id) === parseInt(id)) {
          this.data[index] = {
            ...obj,
          }
        }
      }
      if (index === this.data.length) {
        this.data[index] = obj;
      }

      return obj;
    },
  },
  persist: true,
});
