import {defineStore} from "pinia";
import {deserialize, serialize} from "jsonapi-fractal";
import FormResponseAPI from "@/api/Subs/FormResponseAPI";
import {
  utcToLocal,
} from "@/internal";

export const useSubFormResponseStore = defineStore('sub-form-response', {
  state: () => ({
    data: [],
    stats: {},
  }),
  actions: {
    show(payload) {
      const { params } = payload;

      return new FormResponseAPI().show(params)
        .then((response) => {
          const obj = {
            ...response.data,
          };

          let index = 0;
          for (index = 0; index < this.data.length; index++) {
            if (parseInt(this.data[index].id) === parseInt(obj.id)) {
              this.data[index] = {
                ...obj,
              }
            }
          }
          if (index === this.data.length) {
            this.data[index] = obj;
          }

          return obj;
        });
    },
    store(payload) {
      return new FormResponseAPI().store(payload)
        .then((response) => {
          return response;
        });
    },
    pdf(id, name, payload) {
      return new FormResponseAPI().pdf(id, name, payload)
        .then((response) => {
        });
    },
  },
  persist: true,
});
