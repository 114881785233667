<template>
  <Page>
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <template v-if="!fieldsFilled">
      <o-field label="Form Name*">
        <o-input v-model="formName" placeholder="Form Name" required maxlength="80"></o-input>
      </o-field>

      <o-field label="Select a Template or No Template*">
        <o-dropdown v-model="selectedTemplate"
                    aria-role="list"
                    menu-class="menu-class"
                    scrollable
                    close-on-click
        >
          <template #trigger="{ active }">
            <div class="button is-secondary is-outlined">
              <span>{{(selectedTemplate && selectedTemplate.name) || '--- Select ---'}}</span>
              <o-icon :icon="active ? 'caret-up' : 'caret-down'" pack="fas"></o-icon>
            </div>
          </template>

          <o-dropdown-item :value="{id: 0, name: 'No Template'}">No Template</o-dropdown-item>
          <o-dropdown-item :value="{id: 'basic', name: 'Basic Template'}">Basic Template</o-dropdown-item>
          <o-dropdown-item :value="form" v-for="form in forms">{{ form.name }}</o-dropdown-item>
        </o-dropdown>
      </o-field>

      <o-button class="is-primary" label="Continue" :disabled="!(formName.trim() && selectedTemplate)" @click="handleContinue"></o-button>
    </template>

    <div v-else>
      <CreateEdit :form="form" />
    </div>
  </Page>
</template>

<script setup>

  import Page from "@/components/Page.vue";
  import CreateEdit from "./CreateEdit.vue";

  import {
    useFormStore,
  } from "@/internal";

  import {computed, onMounted, ref} from "vue";
  import {v4 as uuidv4} from 'uuid';

  const formStore = useFormStore();
  const fieldsFilled = ref(false);
  const form = ref({
    name: '',
    title: '',
    instructions: '',
    email_message: '',
    email_button: '',
    subject_question_id: null,
    send_email_alert: false,
    send_email_subject: '',
    send_email_to_user_id: null,
    bypass_approval: false,
    approved_group_id: null,
    unapproved_group_id: null,
    businessUnit: null,
    workflow: null,
  });
  const formName = ref('');
  const isLoading = ref(false);
  const selectedTemplate = ref(null);

  const forms = computed(() => formStore.data || []);

  onMounted(() => {
    loadForms();
  });

  function loadBasicFormTemplate() {
    isLoading.value = true;

    const payload = {
      params: {
        include: [
        ],
      },
    };

    formStore.basicTemplate(payload)
      .then(response => {
        form.value = { ...response.data };
        form.value.id = null;
        form.value.name = formName.value;
        form.value.subject_join_id = response.data.subjectQuestion.id;
        form.value.questions = response.data.questions ? response.data.questions.map(question => {
          return {
            ...question,
            id: `avail_${uuidv4()}`,
          };
        }) : [];
        form.value.workflow = {
          ...response.data.workflow,
          id: null,
        };
        form.value.workflow.workflowSteps = response.data.workflow ? response.data.workflow.workflowSteps.map(workflowStep => {
          return {
            ...workflowStep,
            id: `new_${uuidv4()}`,
          };
        }) : [];
      }).finally(() => {
        isLoading.value = false;
        fieldsFilled.value = true;
      });
  }

  function handleContinue() {

    if (selectedTemplate.value.id === 'basic') {
      loadBasicFormTemplate();
    }
    else if (selectedTemplate.value.id > 0) {
      loadFormTemplate();
    }
    else {
      form.value.name = formName.value;
      form.value.questions = [];
      fieldsFilled.value = true;
    }
  }

  function loadForms() {
    isLoading.value = true;

    const payload = {
      params: {
        include: [
        ],
      },
    };

    formStore.available(payload)
        .finally(() => {
          isLoading.value = false;
        });
  }

  function loadFormTemplate() {
    isLoading.value = true;

    const payload = {
      params: {
        include: [
          'approvedGroup',
          'businessUnit',
          'questions.options',
          'sendEmailTo',
          'subjectQuestion',
          'unapprovedGroup',
          'workflow.workflowSteps.emailRecipient',
        ],
      },
    };

    formStore.show(selectedTemplate.value.id, payload)
        .then(() => {
          const templateForm = formStore.find(selectedTemplate.value.id);
          form.value = { ...templateForm };
          form.value.id = null;
          form.value.name = formName.value;
          form.value.subject_join_id = null;
          form.value.questions = templateForm && templateForm.questions ? templateForm.questions.map(question => {
            return {
              ...question,
              id: `avail_${uuidv4()}`,
            };
          }) : [];
          form.value.workflow = {
            ...templateForm.workflow,
            id: null,
          };
          form.value.workflow.workflowSteps = templateForm && templateForm.workflow ? templateForm.workflow.workflowSteps.map(workflowStep => {
            return {
              ...workflowStep,
              id: `new_${uuidv4()}`,
            };
          }) : [];
        }).finally(() => {
      isLoading.value = false;
      fieldsFilled.value = true;
    });
  }

</script>

<style lang="scss" scoped>

.menu-class {
  max-height: 300px;
}

</style>