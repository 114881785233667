import {defineStore} from "pinia";
import {deserialize, serialize} from "jsonapi-fractal";
import NonceAPI from "@/api/NonceAPI";
import {
  utcToLocal,
} from "@/internal";

export const useNonceStore = defineStore('nonce', {
  state: () => ({
    data: null,
  }),
  actions: {
    clear() {
      this.data = [];
    },
    getNonce(payload) {
      const { params } = payload;

      return new NonceAPI().getNonce(params)
        .then((response) => {
          this.data = response.data.data;
          return response;
        });
    },
  },
  persist: true,
});
