<template>
  <div class="dialog-display box">
    <div class="subtitle columns" v-if="title">
      {{ title }}
    </div>

    <div class="dialog-message columns">
      {{ message }}
    </div>

    <div class="columns has-text-right">
      <div class="column has-text-right">
        <o-button class="has-margin-right" variant="primary" @click="handleCancel" v-if="canCancel">{{ cancelText }}</o-button>
        <o-button variant="primary" @click="handleClick">{{ confirmText }}</o-button>
      </div>
    </div>
  </div>
</template>

<script setup>

  import {toRefs} from "vue";

  const emit = defineEmits([
    'close',
  ]);

  const props = defineProps({
    canCancel: {
      required: false,
      type: Boolean,
      default: false,
    },
    cancelText: {
      required: false,
      type: String,
      default: 'Cancel',
    },
    confirmText: {
      required: false,
      type: String,
      default: 'Ok',
    },
    title: {
      required: false,
      type: String,
      default: null,
    },
    message: {
      required: true,
      type: String,
    },
    onCancel: {
      required: false,
      type: Function,
      default: null,
    },
    onConfirm: {
      required: false,
      type: Function,
      default: null,
    },
  });
  const {
    canCancel,
    cancelText,
    confirmText,
    title,
    message,
    onCancel,
    onConfirm,
  } = toRefs(props);

  async function handleCancel() {
    if (onCancel.value) {
      await onCancel.value();
    }

    emit('close');
  }

  async function handleClick() {
    if (onConfirm.value) {
      await onConfirm.value();
    }

    emit('close');
  }

</script>

<style lang="scss" scoped>

  .dialog-display {
    &.box {
      margin-bottom: 0px !important;
      min-width: 400px;

      .subtitle {
        padding-bottom: .5em;
        border-bottom: 1px solid #7a7a7a !important;
      }

      .dialog-message {
        margin-top: .5em;
      }

      .has-margin-right {
        margin-right: .5em;
      }
    }
  }

</style>
