import { useOruga } from '@oruga-ui/oruga-next';

const oruga = useOruga();

export default function NotificationError(config = {}, error=null) {
  if (error && error.errors) {
    config.message = error.errors.map(e => e.detail || e.message).join(' ');

    oruga.notification.open({
      position: 'top-right',
      variant: 'danger',
      duration: 5000,
      ...config,
    });
  }
  else if (config.message) {

    oruga.notification.open({
      position: 'top-right',
      variant: 'danger',
      duration: 5000,
      ...config,
    });
  } else {
    console.error('Cannot find error message.');
  }
};
