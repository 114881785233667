import Approved from "@/pages/Responses/Approved.vue";
import Declined from "@/pages/Responses/Declined.vue";
import Inbox from "@/pages/Responses/Inbox.vue";
import InReview from "@/pages/Responses/InReview.vue";
import SendBack from "@/pages/Responses/SendBack.vue";
import SentBack from "@/pages/Responses/SentBack.vue";
import Show from "@/pages/Responses/Show.vue";
import ViewLog from "@/pages/Responses/ViewLog.vue";

export default [
  {
    name: 'responses',
    path: '/responses',
    component: Inbox,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'inboxes',
      title: 'Responses - Inbox',
    },
  },
  {
    name: 'responses/show',
    path: '/responses/:responseId',
    component: Show,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/show',
      ],
      icon: 'eye',
      useModelForTitle: 'form-response',
    },
  },
  {
    name: 'responses/inbox',
    path: '/responses/inbox',
    component: Inbox,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'inboxes',
      title: 'Responses - Inbox',
    },
  },
  {
    name: 'responses/inbox/show',
    path: '/responses/inbox/:responseId',
    component: Show,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/inbox',
        'responses/inbox/show',
      ],
      icon: 'eye',
      useModelForTitle: 'form-response',
    },
  },
  {
    name: 'responses/inbox/show/send-back',
    path: '/responses/inbox/:responseId/send-back',
    component: SendBack,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/inbox',
        'responses/inbox/show',
        'responses/inbox/show/send-back',
      ],
      icon: 'arrow-turn-down-left',
      title: 'Send Back',
    },
  },
  {
    name: 'responses/inbox/log',
    path: '/responses/inbox/:responseId/log',
    component: ViewLog,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/inbox',
        'responses/inbox/show',
        'responses/inbox/log',
      ],
      icon: 'eye',
      useModelForTitle: 'form-response',
    },
  },
  {
    name: 'responses/in-review',
    path: '/responses/in-review',
    component: InReview,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'file-pen',
      title: 'Responses - In Review',
    },
  },
  {
    name: 'responses/in-review/show',
    path: '/responses/in-review/:responseId',
    component: Show,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/in-review',
        'responses/in-review/show',
      ],
      icon: 'eye',
      useModelForTitle: 'form-response',
    },
  },
  {
    name: 'responses/in-review/show/send-back',
    path: '/responses/in-review/:responseId/send-back',
    component: SendBack,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/in-review',
        'responses/in-review/show',
        'responses/in-review/show/send-back',
      ],
      icon: 'arrow-turn-down-left',
      title: 'Send Back',
    },
  },
  {
    name: 'responses/in-review/log',
    path: '/responses/in-review/:responseId/log',
    component: ViewLog,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/in-review',
        'responses/in-review/show',
        'responses/in-review/log',
      ],
      icon: 'eye',
      useModelForTitle: 'form-response',
    },
  },
  {
    name: 'responses/approved',
    path: '/responses/approved',
    component: Approved,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'thumbs-up',
      title: 'Responses - Approved',
    },
  },
  {
    name: 'responses/approved/show',
    path: '/responses/approved/:responseId',
    component: Show,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/approved',
        'responses/approved/show',
      ],
      icon: 'eye',
      useModelForTitle: 'form-response',
    },
  },
  {
    name: 'responses/approved/show/send-back',
    path: '/responses/approved/:responseId/send-back',
    component: SendBack,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/approved',
        'responses/approved/show',
        'responses/approved/show/send-back',
      ],
      icon: 'arrow-turn-down-left',
      title: 'Send Back',
    },
  },
  {
    name: 'responses/approved/log',
    path: '/responses/approved/:responseId/log',
    component: ViewLog,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/approved',
        'responses/approved/show',
        'responses/approved/log',
      ],
      icon: 'eye',
      useModelForTitle: 'form-response',
    },
  },
  {
    name: 'responses/declined',
    path: '/responses/declined',
    component: Declined,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'thumbs-down',
      title: 'Responses - Declined',
    },
  },
  {
    name: 'responses/declined/show',
    path: '/responses/declined/:responseId',
    component: Show,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/declined',
        'responses/declined/show',
      ],
      icon: 'eye',
      useModelForTitle: 'form-response',
    },
  },
  {
    name: 'responses/declined/show/send-back',
    path: '/responses/declined/:responseId/send-back',
    component: SendBack,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/declined',
        'responses/declined/show',
        'responses/declined/show/send-back',
      ],
      icon: 'arrow-turn-down-left',
      title: 'Send Back',
    },
  },
  {
    name: 'responses/declined/log',
    path: '/responses/declined/:responseId/log',
    component: ViewLog,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/declined',
        'responses/declined/show',
        'responses/declined/log',
      ],
      icon: 'eye',
      useModelForTitle: 'form-response',
    },
  },
  {
    name: 'responses/show/send-back',
    path: '/responses/:responseId/send-back',
    component: SendBack,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses',
        'responses/show',
        'responses/show/send-back',
      ],
      icon: 'arrow-turn-down-left',
      title: 'Send Back',
    },
  },
  {
    name: 'responses/sent-back',
    path: '/responses/sent-back',
    component: SentBack,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'arrow-turn-down-left',
      title: 'Responses - Sent Back',
    },
  },
  {
    name: 'responses/sent-back/show',
    path: '/responses/sent-back/:responseId',
    component: Show,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/sent-back',
        'responses/sent-back/show',
      ],
      icon: 'eye',
      useModelForTitle: 'form-response',
    },
  },
  {
    name: 'responses/sent-back/log',
    path: '/responses/sent-back/:responseId/log',
    component: ViewLog,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'responses/sent-back',
        'responses/sent-back/show',
        'responses/sent-back/log',
      ],
      icon: 'eye',
      useModelForTitle: 'form-response',
    },
  },
]
