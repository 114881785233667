import {
  findRouteByName,
  Menu,
  userIsRole,
} from '@/internal';

export default (payload = {}) => {

  const { user } = payload;

  return new Menu({
    menuItems: [
      {
        route: findRouteByName('change-log'),
        when: userIsRole('god'),
      },
    ],
  });
};