<template>

    <header class="header">

        <nav class="navbar" role="navigation" aria-label="main navigation">

            <div class="navbar-brand">

                <router-link to="/" class="navbar-item">
                    <img src="https://assets.p-suite.io/prequal/preconPrequal-logo.svg" alt="PrecondPrequal" class="logo" style="height: 34px;">
                </router-link>

                <a
                        role="button"
                        class="navbar-burger burger"
                        aria-label="menu"
                        aria-expanded="false"
                        :class="{ 'is-active': navBarOpen }"
                        @click="toggleNavBar"
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>

            </div>

            <div class="navbar-menu" :class="{ 'is-active' : navBarOpen }">
                <div class="navbar-end"></div>
            </div>

        </nav>

    </header>

</template>

<script setup>

  import { ref, computed } from "vue";

  let navBarOpen = ref(false);

  const toggleNavBar = () => {
    navBarOpen.value = !navBarOpen.value;
  };

</script>

<style lang="scss" scoped>
    header.header {
        font-size: 16px;

        .logo {
            max-height: 2.25rem;
        }
    }
</style>