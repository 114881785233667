<template>
  <div class="form-details">
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <div class="title">Form Details</div>

    <o-field>
      <template #label>
        Name
        <o-tooltip label="Required">
          <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
        </o-tooltip>
      </template>
      <o-input v-model="form.name" required maxlength="80"></o-input>
    </o-field>

    <o-field label="Business Unit" v-if="businessUnits">
      <o-dropdown v-model="form.businessUnit"
                  aria-role="list"
                  menu-class="menu-class"
                  scrollable
                  close-on-click
      >
        <template #trigger="{ active }">
          <div class="button is-secondary is-outlined">
            <span>{{(form.businessUnit && form.businessUnit.name) || '--- Select ---'}}</span>
            <o-icon :icon="active ? 'caret-up' : 'caret-down'" pack="fas"></o-icon>
          </div>
        </template>

        <o-dropdown-item :value="businessUnit" v-for="businessUnit in businessUnits">{{ businessUnit.name }}</o-dropdown-item>
      </o-dropdown>
    </o-field>

    <o-field>
      <template #label>
        Title
        <o-tooltip label="Required">
          <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
        </o-tooltip>
      </template>
      <o-input v-model="form.title" required maxlength="150"></o-input>
    </o-field>

    <o-field label="Instructions">
      <o-input v-model="form.instructions" type="textarea"></o-input>
    </o-field>

    <o-field>
      <template #label>
        Submission Title
        <o-tooltip label="Required">
          <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
        </o-tooltip>
        <o-tooltip multiline
                   size="medium"
                   variant="secondary"
                   label='This data shows in the "Submission" column in your Responses Inbox'
        >
          <o-icon size="small" icon="circle-info" pack="fas" variant="secondary"></o-icon>
        </o-tooltip>
      </template>
      <o-dropdown v-model="form.subjectQuestion"
                  aria-role="list"
                  menu-class="menu-class"
                  scrollable
                  close-on-click
      >
        <template #trigger="{ active }">
          <div class="button is-secondary is-outlined">
            <span>{{(form.subjectQuestion && form.subjectQuestion.question) || '--- Select ---'}}</span>
            <o-icon :icon="active ? 'caret-up' : 'caret-down'" pack="fas"></o-icon>
          </div>
        </template>

        <o-dropdown-item :value="question" v-for="question in questions">{{ question.question }}</o-dropdown-item>
      </o-dropdown>
    </o-field>

    <o-field>
      <template #label>
        Send Email Alert Upon Form Response Submission
        <o-tooltip multiline
                   size="medium"
                   variant="secondary"
                   label="If you would like one of your users to be alerted upon each submitted form response, check this box and you will be able to choose which email will receive the alerts."
        >
          <o-icon icon="circle-info" pack="fas" variant="secondary" size="small" />
        </o-tooltip>
      </template>
      <o-switch v-model="form.send_email_alert" />
    </o-field>

    <template v-if="form.send_email_alert">
      <o-field>
        <template #label>
          Email Recipient
          <o-tooltip label="Required">
            <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
          </o-tooltip>
        </template>
        <o-dropdown v-model="form.sendEmailTo"
                    aria-role="list"
                    menu-class="menu-class"
                    scrollable
                    close-on-click
        >
          <template #trigger="{ active }">
            <div class="button is-secondary is-outlined">
              <span>{{(form.sendEmailTo && `${form.sendEmailTo.first_name} ${form.sendEmailTo.last_name} <${form.sendEmailTo.email}>`) || '--- Select ---'}}</span>
              <o-icon :icon="active ? 'caret-up' : 'caret-down'" pack="fas"></o-icon>
            </div>
          </template>

          <o-dropdown-item :value="user" v-for="user in users">{{ `${user.fullName} <${user.email}>` }}</o-dropdown-item>
        </o-dropdown>
      </o-field>

      <o-field>
        <template #label>
          Email Subject
          <o-tooltip label="Required">
            <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
          </o-tooltip>
        </template>
        <o-input v-model="form.send_email_subject" maxlength="150"></o-input>
      </o-field>
    </template>

    <o-field class="assign-group">
      <template #label>
        Assign subs that are marked "Approved/Qualified" to the following group
        <o-tooltip multiline
                   size="medium"
                   variant="secondary"
                   label="This allows you to automatically assign approved or unapproved subs into a Group.
                        You could also create a new Group for this purpose, for example &quot;Qualified 2012&quot; and &quot;Unqualified&quot;.
                        This can be helpful if you want to resend the form to those that haven't submitted a form, since you can resend the form to everyone EXCEPT those that have been assigned to these Groups."
        >
          <o-icon icon="circle-info" pack="fas" variant="secondary" size="small" />
        </o-tooltip>
      </template>

      <o-dropdown v-model="form.approvedGroup"
                  aria-role="list"
                  menu-class="menu-class"
                  scrollable
                  close-on-click
      >
        <template #trigger="{ active }">
          <div class="button is-secondary is-outlined">
            <span>{{(form.approvedGroup && form.approvedGroup.name) || '--- Select ---'}}</span>
            <o-icon :icon="active ? 'caret-up' : 'caret-down'" pack="fas"></o-icon>
          </div>
        </template>

        <o-dropdown-item :value="group" v-for="group in groups">{{ group.name }}</o-dropdown-item>
      </o-dropdown>

      <o-button variant="secondary" class="has-margin-left" @click="handleCreateGroup('approved')">Create New Group</o-button>
    </o-field>

    <o-field class="assign-group">
      <template #label>
        Assign subs that are marked "Unapproved/Unqualified" to the following group
        <o-tooltip multiline
                   size="medium"
                   variant="secondary"
                   label="This allows you to automatically assign approved or unapproved subs into a Group.
                        You could also create a new Group for this purpose, for example &quot;Qualified 2012&quot; and &quot;Unqualified&quot;.
                        This can be helpful if you want to resend the form to those that haven't submitted a form, since you can resend the form to everyone EXCEPT those that have been assigned to these Groups."
        >
          <o-icon icon="circle-info" pack="fas" variant="secondary" size="small" />
        </o-tooltip>
      </template>

      <o-dropdown v-model="form.unapprovedGroup"
                  aria-role="list"
                  menu-class="menu-class"
                  scrollable
                  close-on-click
      >
        <template #trigger="{ active }">
          <div class="button is-secondary is-outlined">
            <span>{{(form.unapprovedGroup && form.unapprovedGroup.name) || '--- Select ---'}}</span>
            <o-icon :icon="active ? 'caret-up' : 'caret-down'" pack="fas"></o-icon>
          </div>
        </template>

        <o-dropdown-item :value="group" v-for="group in groups">{{ group.name }}</o-dropdown-item>
      </o-dropdown>

      <o-button variant="secondary" class="has-margin-left" @click="handleCreateGroup('unapproved')">Create New Group</o-button>
    </o-field>

    <o-field>
      <template #label>
        Bypass Approval Requirement
        <o-tooltip multiline
                   size="medium"
                   variant="secondary"
                   label="For signup forms that you embed into your website, we recommend NOT turning on the Bypass Approval to avoid bogus or spam entries automatically.
                    If you are sending a simple &quot;Update Your Information&quot; form and do not want to have to physically view and approve each one, then turning on the Bypass Approval may be helpful to you."
        >
          <o-icon icon="circle-info" pack="fas" variant="secondary" size="small" />
        </o-tooltip>
      </template>

      <o-switch v-model="form.bypass_approval" />

      <o-notification
          variant="warning"
          role="alert"
          root-class="root-class"
      >
        <div class="has-margin-bottom-small">
          WARNING: DO NOT turn this on if you are embedding the form on your website. If "Bypass Approval Requirement" is on, bogus entries will automatically be added to your company/contacts database. This should only be turned on, if you want your existing subcontractor/companies to update their info.
        </div>
        <div>
          Submissions will <em>not</em> show up in your Inbox, but will show up in your Approved list. DO NOT turn this on, if you want to review and approve each submission.
        </div>
      </o-notification>
    </o-field>

  </div>

  <o-modal v-model:active="isCreateActive" :width="600">
    <div class="title">Create New Group</div>
    <GroupForm @cancel="handleCancelCreate" @saved="handleGroupCreated" />
  </o-modal>
</template>

<script setup>

  import GroupForm from "@/pages/Groups/Form.vue";

  import {
    useAuthStore,
    useGroupStore,
    useUserStore,
  } from "@/internal";

  import {computed, onMounted, ref, toRefs, watch} from "vue";

  const authStore = useAuthStore();
  const groupStore = useGroupStore();
  const userStore = useUserStore();
  const createGroupFor = ref(null);
  const isCreateActive = ref(false);
  const isLoading = ref(false);

  const props = defineProps({
    form: {
      required: true,
      type: Object,
    },
    questions: {
      required: true,
    },
  });
  const {
    form,
    questions,
  } = toRefs(props);

  const businessUnits = computed(() => authStore.me.associatedBusinessUnits || null);

  const groups = computed(() => groupStore.data || []);

  const users = computed(() => userStore.data.map(user => {
    return {
      ...user,
      fullName: `${user.first_name} ${user.last_name}`,
    }
  }) || null);

  onMounted(() => {
    authStore.getMe()
      .then(() => {
        setBusinessUnit();
      });

    loadUsers();

    if (form.value.id) {
      setSubjectQuestion();
    }
  });

  function handleCancelCreate() {
    isCreateActive.value = false;
    createGroupFor.value = null;
  }

  function handleCreateGroup(type) {
    isCreateActive.value = true;
    createGroupFor.value = type;
  }

  function handleGroupCreated(group) {
    isCreateActive.value = false;

    if (createGroupFor.value === 'approved') {
      form.value.approvedGroup = group;
    }
    else if (createGroupFor.value === 'unapproved') {
      form.value.unapprovedGroup = group;
    }

    createGroupFor.value = null;
    loadGroups();
  }

  function loadGroups() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    groupStore.index(payload)
        .then(() => {
        }).finally(() => {
    });
  }

  function loadUsers() {
    isLoading.value = true;

    const payload = {
      params: {
        include: [
        ],
        active: true,
      },
    };

    userStore.index(payload)
        .finally(() => {
          isLoading.value = false;
        });
  }

  function setBusinessUnit() {
    if (!form.value.businessUnit && businessUnits.value) {
      form.value.businessUnit = businessUnits.value.find(businessUnit => businessUnit.origin === true) || null;
    }
  }

  function setSubjectQuestion() {
    if (!questions.value || (form.value.subjectQuestion && !(questions.value.find(question => parseInt(question.question_id) === parseInt(form.value.subjectQuestion.question_id))))) {
      form.value.subjectQuestion = null;
    }
  }

  watch(questions, () => {
    setSubjectQuestion();
  });

</script>

<style lang="scss" scoped>

.form-details {
  .assign-group {
    .field.has-addons {
      .dropdown:not(:last-child) {

        .button {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }

    .button:not(:first-child) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}

</style>

<style lang="scss">

.form-details {
  .root-class {
    width: 80%;
  }
}

</style>
