<template>
  <Page :title="pageTitle" class="form-embed">
    <div>
      To embed the Form in your website, first set the dimensions of the form below. Then copy and paste the script in the box
      to the a place in your web page where you'd like the form to be displayed.
    </div>

    <o-field label="Width" class="dimensions has-margin-top-large" addons-class="addons-class">
      <o-slider :min="0" :max="1920" ticks variant="secondary" v-model="formWidth" />
      <o-input v-model="formWidth" class="has-margin-left-large has-margin-right-small dimension" /> px
    </o-field>

    <o-field label="Height" class="dimensions" addons-class="addons-class">
      <o-slider :min="0" :max="1400" ticks  variant="secondary" v-model="formHeight" />
      <o-input v-model="formHeight" class="has-margin-left-large has-margin-right-small dimension" /> px
    </o-field>

    <div class="has-margin-top-large">
      Copy and paste the script in the box below to the a place in your web page where you'd like the form to be displayed.
      The form will be built in the same location where the script is pasted.
    </div>
    <div v-if="isSupported">
      <o-tooltip label="Copy to Clipboard" variant="secondary">
        <o-button :label="!copied ? 'Copy' : 'Copied!'"
                  size="small"
                  icon-left="copy"
                  icon-pack="fas"
                  variant="secondary"
                  rounded
                  class="has-margin-top"
                  @click='copy(embedScript)'
        />
      </o-tooltip>
    </div>

    <div class="has-margin-top-large">
      <o-input type="textarea" v-model="embedScript" disabled class="disabled" autosize />
    </div>

    <div class="box has-margin-top-large">
      <div class="label">Form URL</div>
      <div>
        Here's a URL to the Form for companies not in your database that you may also use.
      </div>
      <a :href="form.form_url" target="_blank" class="form-url">{{form.form_url}}</a>
    </div>

  </Page>
</template>

<script setup>

  import {useRoute} from "vue-router";
  import _ from "lodash";
  import { useClipboard } from '@vueuse/core';

  import Page from "@/components/Page.vue";

  import {
    useFormStore,
  } from "@/internal";
  import {ref, computed} from "vue";

  const { text, copy, copied, isSupported } = useClipboard({legacy: true});
  const formStore = useFormStore();
  const route = useRoute();
  const formWidth = ref(1100);
  const formHeight = ref(800);
  const embedScriptURI = import.meta.env.VITE_APP_BASE_URL.replace(/http(s)?:/g, '')
      + (import.meta.env.VITE_APP_PORT ? ':'+import.meta.env.VITE_APP_PORT : '')
      + '/assets/'
      // + (import.meta.env.VITE_APP_ENV === 'production' ? '': `${import.meta.env.VITE_APP_ENV}-`)
      + 'showForm.js';

  const form = computed(() => {
    return formStore.find(formId.value);
  });

  const formId = computed(() => {
    return route.params.formId || null;
  });

  const pageTitle = computed(() => {
    return "Embed: " + (form.value ? form.value.name : '');
  });

  const embedScript = computed(() => {
    return _.unescape(`&lt;script type="text/javascript"&gt;
  <!--
    prequal_form = {
      key: ` + formId.value + `,
      id: ` + form.value.client_id + `,
      private_key: '` + form.value.private_key + `',
      width: '` + formWidth.value + `px',
      height: '` + formHeight.value + `px'
    };
  -->
&lt;/script&gt;
&lt;script type="text/javascript" src="` + embedScriptURI.toString() + `"&gt;&lt;/script&gt;
    `);
  });



</script>

<style lang="scss" scoped>

.form-url {
  color: $secondary;
}

</style>

<style lang="scss">

.form-embed {
  .input {
    &.disabled {
      background-color: unset;
      border-color: unset;
      box-shadow: unset;
      color: unset;
    }

    &.dimension {
      width: 4em;
    }
  }

  .field {
    &.dimensions {
      .addons-class {
        align-items: center;
      }
    }
  }
}

</style>