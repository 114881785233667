import { deserialize } from "jsonapi-fractal";

import {
  BaseAPI,
} from '@/internal';

export default class FormAPI extends BaseAPI {

  index(params) {
    return this.get({
      url: "forms",
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        links: res.links,
        paginator: res.meta && res.meta.pagination ? res.meta.pagination : {},
        data: deserialize(res),
      }
    })
  }

  show(id, params) {
    const { prependURL } = params;
    delete params.prependURL;

    const prepend = prependURL || '';

    return this.get({
      url: `${prepend}forms/${id}`,
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }

  store(data) {
    return this.post({
      url: `forms`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }

  update(id, data) {
    return this.put({
      url: `forms/${id}`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }

  archive(id) {
    return this.put({
      url: `forms/${id}/archive`,
      headers: [],
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }

  restore(id) {
    return this.put({
      url: `forms/${id}/restore`,
      headers: [],
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }

  destroy(id) {
    return this.delete({
      url: `forms/${id}`,
      headers: [],
    });
  }

  available(params) {
    return this.get({
      url: "forms/available",
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }

  send(id, data) {
    return this.post({
      url: `forms/${id}/send`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }

  files(id, params) {
    return this.get({
      url: `forms/${id}/files`,
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          links: res.links,
          paginator: res.meta && res.meta.pagination ? res.meta.pagination : {},
          data: deserialize(res),
        }
      })
  }

  updateFile(formId, fileId, data) {
    return this.put({
      url: `forms/${formId}/files/${fileId}`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        data: deserialize(res),
      }
    })
  }

  destroyFile(formId, fileId) {
    return this.delete({
      url: `forms/${formId}/files/${fileId}`,
      headers: [],
    });
  }

  previewFax(id, name, params) {
    return this.get({
      url: `forms/${id}/preview-fax`,
      headers: [],
      queryParams: params,
    }).then(res => res.blob())
    .then((response) => {
      const file = new Blob(
        [response],
        {type: 'application/pdf'},
      );
      const link = document.createElement('a');
      link.href =  URL.createObjectURL(file);
      link.download = `${name}.pdf`;
      document.body.append(link);
      link.click();
      link.remove();
    });
  }

  basicTemplate(params) {
    return this.get({
      url: `forms/basic-template`,
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
      return res;
    })
  }
};