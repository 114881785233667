<template>
    <nav id="the-breadcrumbs" class="breadcrumb" aria-label="breadcrumbs">
        <ul>
            <template v-if="breadcrumbs.length">
                <li v-for="(breadcrumb, i) in breadcrumbs"
                    :key="`breadcrumb-${i}`"
                    :class="{ 'is-active': breadcrumb.route && breadcrumb.route.name === route.name }"
                >
                    <router-link :to="breadcrumb.route">
                        {{breadcrumb.title?.replace(/(<([^>]+)>)/ig, '')}}
                    </router-link>
                </li>
            </template>
            <template v-else>
                <li>&nbsp;</li>
            </template>
        </ul>
    </nav>
</template>

<script setup>

  import _ from 'lodash';
  import { ref, computed } from "vue";
  import { useRoute } from "vue-router";
  import {
    findRouteByName,
    useFormStore,
    useFormQuestionStore,
    useFormResponseStore,
  } from "@/internal";

  const route = useRoute();
  const formStore = useFormStore();
  const formQuestionStore = useFormQuestionStore();
  const formResponseStore = useFormResponseStore();

  const breadcrumbs = computed(() => {
    const breadcrumbs = (route.meta && route.meta.breadcrumbs) ? route.meta.breadcrumbs : [];

    return breadcrumbs.map((breadcrumb) =>  {
      let title = '';
      let foundRoute = findRouteByName(breadcrumb);

      if (foundRoute && foundRoute.meta) {

        foundRoute.params = {
          ...route.params,
        };

        if (foundRoute.meta.useModelForTitle) {
          const modelName = _.camelCase(foundRoute.meta.useModelForTitle);
          let model = null;

          if (modelName === 'form') {
            model = form;
          }
          else if (modelName === 'formQuestion') {
            model = formQuestion;
          }
          else if (modelName === 'formResponse') {
            model = formResponse;
          }

          if (model) {
            const modelTitles = {
              form: 'name',
              formQuestion: 'question',
              formResponse: 'title',
            };

            title = model.value[modelTitles[modelName]];
          } else {
            title = 'Model Not Available';
          }
        }
        else if (foundRoute.meta.title) {
          title = foundRoute.meta.title;
        }
      }
      else {
        return false;
      }

      return {
        title,
        route: foundRoute,
      };
    }).filter(breadcrumb => breadcrumb !== false);
  });

  const form = computed(() => {
    const id = parseInt(route.params.formId);
    return formStore.find(id) || {};
  });

  const formQuestion = computed(() => {
    const id = parseInt(route.params.questionId);
    return formQuestionStore.find(id) || {};
  });

  const formResponse = computed(() => {
    const id = parseInt(route.params.responseId);
    return formResponseStore.find(id) || {};
  });

</script>

<style scoped lang="scss">
    #the-breadcrumbs {

    }
</style>
