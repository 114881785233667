<template>
  <div class="ps-modal">
    <div class="label has-margin-bottom" v-if="title">{{title}}</div>

    <div>
      {{message}}
    </div>

    <div class="is-flex is-justify-content-flex-end">
      <o-button :label="labelFalse" variant="primary" class="has-margin-right" @click="$emit('close', {action: responseFalse})" />
      <o-button :label="labelTrue" variant="primary" @click="$emit('close', {action: responseTrue})" />
    </div>
  </div>
</template>

<script setup>

import {toRefs} from "vue";

const props = defineProps({
  title: {
    required: false,
    default: null,
  },
  message: {
    required: true,
  },
  labelTrue: {
    required: false,
    default: "Ok",
  },
  labelFalse: {
    required: false,
    default: "Cancel",
  },
  responseTrue: {
    required: false,
    default: true,
  },
  responseFalse: {
    required: false,
    default: false,
  },
});
const {
  title,
  message,
  labelTrue,
  labelFalse,
  responseTrue,
  responseFalse,
} = toRefs(props);

</script>

<style scoped lang="scss">

.ps-modal {
  .has-margin-right {
    margin-right: .5em;
  }
}
</style>