<template>
    <div class="user-name-column no-wrap" v-if="value">
        {{ `${value.first_name} ${value.last_name}` }}
    </div>
</template>

<script setup>

import {toRefs} from "vue";

const props = defineProps({
  row: {
    required: true,
  },
  value: {
    required: true,
  },
});
const {
  row,
  value,
} = toRefs(props);

</script>

<style scoped lang="scss">
    .user-name-column {
        align-items: center;
        display: flex;
    }
</style>