<template>
  <div class="workflow-step">
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <div class="is-flex">
      <div class="step box has-margin-bottom-small" v-if="workflowStep">
        <div class="is-flex">
          <div class="step-drag has-margin-top">
            <o-icon icon="sort" pack="fas" variant="secondary" size="small" v-if="workflowStep.id && !disabled" />
          </div>

          <div class="step-text">
            <div class="label">{{ !workflowStep.id ? 'Add New ' : '' }}Approval Step{{ stepNumber !== null ? ` #${stepNumber}` : ''}}</div>

            <div class="is-flex">
              <div class="column">
                <o-field label="Label">
                  <o-input v-model="workflowStep.label" :disabled="disabled" maxlength="191"></o-input>
                </o-field>

                <o-field label="Send Email Alert to Process this Workflow Step">
                  <o-switch v-model="workflowStep.email_when_ready" :disabled="disabled"></o-switch>
                </o-field>

                <template v-if="workflowStep.email_when_ready">
                  <o-field>
                    <template #label>
                      Email Recipient
                      <o-tooltip label="Required">
                        <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
                      </o-tooltip>
                    </template>
                    <o-dropdown v-model="workflowStep.emailRecipient"
                                aria-role="list"
                                menu-class="menu-class"
                                scrollable
                                close-on-click
                                :disabled="disabled"
                    >
                      <template #trigger="{ active }">
                        <div class="button is-secondary is-outlined">
                          <span>{{(workflowStep.emailRecipient && `${workflowStep.emailRecipient.first_name} ${workflowStep.emailRecipient.last_name} <${workflowStep.emailRecipient.email}>`) || '--- Select ---'}}</span>
                          <o-icon :icon="active ? 'caret-up' : 'caret-down'" pack="fas"></o-icon>
                        </div>
                      </template>

                      <o-dropdown-item :value="user" v-for="user in users">{{ `${user.fullName} <${user.email}>` }}</o-dropdown-item>
                    </o-dropdown>
                  </o-field>

                  <o-field>
                    <template #label>
                      Email Subject
                      <o-tooltip label="Required">
                        <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
                      </o-tooltip>
                    </template>
                    <o-input v-model="workflowStep.email_subject" :disabled="disabled" maxlength="191"></o-input>
                  </o-field>
                </template>

              </div>
              <div class="column">
                <o-field label="Allow Send Back"
                         horizontal
                         body-horizontal-class="body-horizontal-class"
                         label-horizontal-class="label-horizontal-class"
                >
                  <o-switch v-model="workflowStep.allow_decline_send_back" :disabled="disabled"></o-switch>
                </o-field>

                <o-field label="Allow Decline"
                         horizontal
                         body-horizontal-class="body-horizontal-class"
                         label-horizontal-class="label-horizontal-class"
                >
                  <o-switch v-model="workflowStep.allow_decline" :disabled="disabled"></o-switch>
                </o-field>

                <o-field label="Allow Comments"
                         horizontal
                         body-horizontal-class="body-horizontal-class"
                         label-horizontal-class="label-horizontal-class"
                >
                  <o-switch v-model="workflowStep.allow_comments" :disabled="disabled"></o-switch>
                </o-field>
              </div>
            </div>
          </div>

          <div class="step-actions has-margin-top" v-if="workflowStep.id && !disabled">
            <o-tooltip label="Delete Step" position="top" variant="secondary">
              <button class="action-icon">
                <o-icon icon="trash-alt" pack="fas" variant="secondary" @click="handleDelete"/>
              </button>
            </o-tooltip>
          </div>
        </div>
      </div>

      <div class="is-flex is-align-items-center has-margin-left" v-if="!workflowStep.id">
        <div class="button is-secondary" @click="handleNewStep">
          <o-icon icon="circle-plus" class="has-margin-right" />
          Add
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>

  import {v4 as uuidv4} from 'uuid';
  import _ from "lodash";

  import {
    useUserStore,
  } from "@/internal";

  import {computed, inject, onMounted, ref, toRefs, watch} from "vue";

  const userStore = useUserStore();
  let emitter = null;
  const isLoading = ref(false);
  const workflowStep = ref({});

  const props = defineProps({
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    formWorkflowStep: {
      required: false,
    },
    stepNumber: {
      required: false,
      default: null,
    },
  });
  const {
    disabled,
    formWorkflowStep,
    stepNumber,
  } = toRefs(props);

  const users = computed(() => userStore.data.map(user => {
    return {
      ...user,
      fullName: `${user.first_name} ${user.last_name}`,
    }
  }) || null);

  onMounted(() => {

    if (!disabled.value) {
      emitter = inject('emitter');
    }

    if (formWorkflowStep.value) {
      workflowStep.value = {
        ...formWorkflowStep.value,
      };
    }
    else {
      setWorkflowStep();
    }
  });

  function handleDelete() {
    emitter.emit('deleteWorkflowStep', workflowStep.value);
  }

  function handleNewStep() {
    if (workflowStep.value.label) {
      workflowStep.value.id = `new_${uuidv4()}`;
      emitter.emit('addWorkflowStep', workflowStep.value);

      setWorkflowStep();
    }
  }

  function setWorkflowStep() {
    workflowStep.value = {
      id: null,
      label: '',
      email_when_ready: false,
      emailRecipient: null,
      email_subject: '',
      allow_decline_send_back: true,
      allow_decline: true,
      allow_comments: true,
    };
  }

  watch(() => _.cloneDeep(workflowStep.value), () => {
    if  (emitter && workflowStep.value.id) {
      emitter.emit('updateWorkflowStep', workflowStep.value);
    }
  });

</script>

<style lang="scss" scoped>

.workflow-step {

  .step {
    border: #eeeeee 5px solid;
    padding: 3px;

    .step-drag {
      min-width: 30px;
      display: flex;
      justify-content: center;
    }

    .step-text {
      width: 100%;
      margin-right: 5px;
      display: inline-grid;
      overflow-x: auto;
    }

    .step-actions {
      min-width: 35px;
      margin-right: 8px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .action-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
}

</style>

<style lang="scss">

.workflow-step {
  .body-horizontal-class {
    .field {
      text-align: left;
    }
  }

  .label-horizontal-class {
    flex-basis: 300px;
    flex-grow: unset;
    flex-shrink: unset;

    .label {
      margin-top: 0;
      text-align: left;
      line-height: normal;
    }
  }
}

</style>
