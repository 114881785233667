<template>
  <SubForm :form-id="formId" :client-id="clientId" :company-id="companyId" :private-key="privateKey" :resume-key="resumeKey" />
</template>

<script setup>

  import SubForm from "./SubForm.vue";

  import {
    useFormStore,
  } from "@/internal";

  import {computed, onMounted} from "vue";
  import {useRoute} from "vue-router";

  const formStore = useFormStore();
  const route = useRoute();

  const form = computed(() => {
    return formStore.find(formId.value);
  });

  const formId = computed(() => {
    return route.params.formId || null;
  });

  const clientId = computed(() => {
    return route.params.clientId || null;
  });

  const companyId = computed(() => {
    return route.params.companyId || null;
  });

  const privateKey = computed(() => {
    return route.params.privateKey || null;
  });

  const resumeKey = computed(() => {
    return route.params.resumeKey || null;
  });

</script>

<style scoped>

</style>