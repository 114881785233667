import {defineStore} from "pinia";
import FormAnswerAPI from "@/api/FormAnswerAPI.js";

export const useFormAnswerStore = defineStore('form-answer', {
  actions: {
    update(id, payload) {
      const { params } = payload;

      return new FormAnswerAPI().update(id, params)
        .then((response) => {
          return response;
        });
    },
  },
  persist: true,
});
