<template>
  <Page id="system-preferences">
    <o-loading
        :full-page="true"
        :active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <div class="label">Form Colors</div>
    <div class="box is-flex">
      <div class="column is-one-third has-margin-right-large">
        <o-field label="Primary Color"
                 class="no-wrap"
                 :variant="error('pf_body_background') ? 'danger' : ''"
                 :message="error('pf_body_background')"
                 label-class="label-class"
                 horizontal
        >
          <ColorPicker v-model:pureColor="formColors.bodyBackground" format="hex" disable-alpha />
        </o-field>

        <o-field label="Secondary Color"
                 class="no-wrap"
                 :variant="error('pf_border_color') ? 'danger' : ''"
                 :message="error('pf_border_color')"
                 label-class="label-class"
                 horizontal
        >
          <ColorPicker v-model:pureColor="formColors.borderColor" format="hex" disable-alpha />
        </o-field>

        <o-field label="Form Background"
                 class="no-wrap"
                 :variant="error('pf_form_background') ? 'danger' : ''"
                 :message="error('pf_form_background')"
                 label-class="label-class"
                 horizontal
        >
          <ColorPicker v-model:pureColor="formColors.formBackground" format="hex" disable-alpha />
        </o-field>

        <o-field label="Text Color"
                 class="no-wrap"
                 :variant="error('pf_form_text_color') ? 'danger' : ''"
                 :message="error('pf_form_text_color')"
                 label-class="label-class"
                 horizontal
        >
          <ColorPicker v-model:pureColor="formColors.formTextColor" format="hex" disable-alpha />
        </o-field>

        <o-field label="Submit Button Color"
                 class="no-wrap"
                 :variant="error('pf_button_color') ? 'danger' : ''"
                 :message="error('pf_button_color')"
                 label-class="label-class"
                 horizontal
        >
          <ColorPicker v-model:pureColor="formColors.buttonColor" format="hex" disable-alpha />
        </o-field>
      </div>

      <div class="column">
        <div class="box preview is-flex">
          <div class="left column is-two-thirds is-flex is-flex-direction-column is-justify-content-space-between"
               :style="`background-color: ${formColors.formBackground};color: ${formColors.formTextColor}`"
          >
            <div>
              <div class="subtitle" :style="`color: ${formColors.formTextColor}`">Form Title</div>
              <div class="label" :style="`color: ${formColors.formTextColor}`">Form Title</div>
              <input />
            </div>
            <div class="footer is-flex has-margin-top-large is-justify-content-flex-end" :style="`border-top: 1px solid ${formColors.bodyBackground};`">
              <div class="button is-small" :style="`background-color: ${formColors.borderColor};color: white;`">Save Progress</div>
              <div class="button is-small" :style="`background-color: ${formColors.buttonColor};color: white;`">Submit</div>
            </div>
          </div>
          <div class="right column is-one-third"
               :style="`background-color: ${formColors.bodyBackground};;color: ${formColors.formTextColor}`"
          >
            <div class="logo" :style="`border-color: ${formColors.borderColor};`">
              <img :src="logoURL" :title="clientName" width="80" v-if="logoURL">
              <div class="subtitle" :style="`color: ${formColors.formTextColor}`" v-else>{{clientName}}</div>
              <div class="subtitle has-margin-top is-capitalized" :style="`color: ${formColors.formTextColor}`">Form Title</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="label">File Upload Filtering</div>
    <div class="box has-margin-right-large">
      <o-field label="File Filter"
               class="no-wrap is-align-items-start"
               :variant="error('prequal_file_upload_filter') ? 'danger' : ''"
               :message="error('prequal_file_upload_filter')"
               label-class="label-class"
               horizontal
      >
        <div>
          <div class="is-flex is-flex-direction-column">
            <div class="has-margin-bottom">
              <o-radio v-model="fileUploadFilter" :native-value="0">
                <span>Unfiltered</span>
              </o-radio>
            </div>
            <div>
              <o-radio v-model="fileUploadFilter" :native-value="2">
                <span>Restrict documents to PDF, Word, and Excel</span>
              </o-radio>
            </div>
          </div>
        </div>
      </o-field>
    </div>

    <div class="has-text-right">
      <o-button class="is-primary has-margin-right" @click="handleCancel">Cancel</o-button>
      <o-button class="is-primary" @click="handleSave">Save</o-button>
    </div>

  </Page>
</template>

<script setup>

import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

import Page from "@/components/Page.vue";

import {
  NotificationError,
  NotificationSuccess,
  useAuthStore,
  useClientStore,
} from "@/internal";

import {computed, onMounted, ref} from "vue";

const authStore = useAuthStore();
const clientStore = useClientStore();
const isLoading = ref(false);

const client = computed(() => authStore.me?.client || null);
const errors = ref([]);
const fileUploadFilter = ref(0);

const formColors = ref( {
    formBackground: '',
    bodyBackground: '',
    borderColor: '',
    formTextColor: '',
    buttonColor: '',
});

const clientName = computed(() => client.value?.name || '');

const logoURL = computed(() => client.value?.logo_url || null);

onMounted(() => {
  loadMe();
});

function clearErrors() {
  errors.value = [];
}

function error(key) {
  return errors.value?.find(error => error.hasOwnProperty('key') && error.key === key)?.detail || '';
}

function handleCancel() {
  loadMe();
}

function handleSave() {

  isLoading.value = true;

  const payload = {
    id: client.value.id,
    pf_form_background: formColors.value.formBackground.replace('#', ''),
    pf_body_background: formColors.value.bodyBackground.replace('#', ''),
    pf_border_color: formColors.value.borderColor.replace('#', ''),
    pf_form_text_color: formColors.value.formTextColor.replace('#', ''),
    pf_button_color: formColors.value.buttonColor.replace('#', ''),
    prequal_file_upload_filter: fileUploadFilter.value !== 2 ? 0 : fileUploadFilter.value,
  };

  clientStore.update(payload)
    .then(() => {
      NotificationSuccess({
        message: `System Preferences Saved`,
      });
    }).catch((error) => {
      if (error.hasOwnProperty('errors')) {
        errors.value = error.errors;
      }
      else {
        NotificationError({
          message: error,
        });
      }
    }).finally(() => {
      isLoading.value = false;
      loadMe();
    });

}

function loadMe() {

  isLoading.value = true;

  const payload = {
    params: {
      include: [
      ],
    },
  };

  authStore.getMe(payload)
    .then(() => {
      formColors.value = {
        formBackground: '#' + client.value?.pf_form_background,
        bodyBackground: '#' + client.value?.pf_body_background,
        borderColor: '#' + client.value?.pf_border_color,
        formTextColor: '#' + client.value?.pf_form_text_color,
        buttonColor: '#' + client.value?.pf_button_color,
      };

      fileUploadFilter.value = client.value?.prequal_file_upload_filter;
    }).finally(() => {
      isLoading.value = false;
    });
}

</script>

<style lang="scss" scoped>

#system-preferences {
  .box {
     min-width: fit-content !important;

    .preview {
      height: 100%;
      border-color: #cccccc;

      .left {
        padding-bottom: 0;

        .footer {
          background-color: #f7f7f7;
          height: 4em;
          min-width: fit-content;
          padding: 10px 5px;
          margin-left: -.75em;
          margin-right: -.75em;
        }
      }

      .right {
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .logo {
        z-index: 10;
        border: 3px solid $secondary;
        border-radius: 0;
        padding: 3px;
        background-color: white;
      }

    }
  }
}

</style>

<style lang="scss">

#system-preferences {
  .box {

    .label-class {
      width: 150px;
    }
  }
}

</style>