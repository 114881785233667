<template>
  <Page id="send-back" :title="pageTitle">
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        :can-cancel="true"
        icon="rotate"
        icon-size="large"
    />

    <div class="label">Email</div>
    <div class="box">
      <SendEmail :message="emailMessage" :button="buttonText" @update="handleSendEmailUpdate" />
    </div>

<!--    <div class="label has-margin-top-large">Attachments</div>-->
<!--    <FileUploadTree :files="files"-->
<!--                    :api-url="formFileApiUrl"-->
<!--                    :upload-multipart-params="uploadMultipartParams"-->
<!--                    @uploaded="handleUploadedFile"-->
<!--                    :rename-file="handleFileUpdate"-->
<!--                    :delete-file="handleFileDelete"-->
<!--    />-->

    <div class="has-text-right has-margin-top-large">
      <o-button variant="primary" label="Send Back" @click="handleSendBack" />
    </div>

  </Page>
</template>

<script setup>

  import {useRoute, useRouter} from "vue-router";

  import Page from "@/components/Page.vue";
  import SendEmail from "@/components/SendEmail.vue";

  import {
    findRouteByName,
    NotificationError,
    NotificationSuccess,
    useFormStore,
    useFormResponseStore,
    useNonceStore,
  } from "@/internal";
  import {computed, onMounted, ref, watch} from "vue";
  import FileUploadTree from "@/components/FileUploadTree.vue";

  const formStore = useFormStore();
  const formResponseStore = useFormResponseStore();
  const nonceStore = useNonceStore();
  const currentRoute = useRoute();
  const router = useRouter();
  const buttonText = ref('');
  const emailMessage = ref('');
  const files = ref([]);
  const isLoading = ref(false);

  const nonce = computed(() => nonceStore.data || null);

  const response = computed(() => {
    return formResponseStore.find(responseId.value);
  });

  const responseId = computed(() => {
    return currentRoute.params.responseId || null;
  });

  const pageTitle = computed(() => {
    return 'Send Back' + (response.value?.title ? `: ${response.value.title}` : '');
  });

  const formId = computed(() => response.value?.form_id || null);

  const formFileApiUrl = computed(() => `${import.meta.env.VITE_APP_API_URL}/forms/${formId.value}/files`);

  const uploadMultipartParams = computed(() => {
    return {
      form_id: formId.value,
      opr_file_type_id: 5,
    };
  });

  onMounted(() => {
    loadResponse();
  });

  function handleFileDelete(fileToDelete) {
    files.value = files.value.filter(file => parseInt(file.id) !== parseInt(fileToDelete.id));
  }

  function handleFileUpdate(file, newName) {
    const payload = {
      name: newName,
    };

    return formStore.updateFile(formId.value, file.id, payload)
        .then((response) => {
          files.value = files.value.map(file => parseInt(file.id) === parseInt(response.data.id) ? response.data : file);
        });
  }

  function handleUploadedFile(file, uploadedResponse) {
    if (uploadedResponse?.response?.data) {
      files.value.push(uploadedResponse.response.data);
    }
  }

  async function handleSendBack() {

    await loadNonce();

    isLoading.value = true;

    const payload = {
      params: {
        email_button: buttonText.value,
        email_message: emailMessage.value,
        file_id: files.value.map(file => file.id) || [],
        nonce: nonce.value,
      },
    };

    formResponseStore.sendBack(responseId.value, payload)
      .then((response) => {
        NotificationSuccess({
          message: `Response Sent Back`,
        });

        const route = findRouteByName(currentRoute.name.replace("/send-back", ""));
        route.params = {
          ...route.params,
          responseId: responseId.value,
        };
        router.push(route);

      }).catch((error) => {
      if (error.errors) {
        NotificationError({}, error);
      }
      else {
        NotificationError({
          message: error,
        });
      }
      }).finally(() => {
        isLoading.value = false;
      });
  }

  function handleSendEmailUpdate(message, button) {
    emailMessage.value = message;
    buttonText.value = button;
  }

  function loadNonce() {
    isLoading.value = true;

    const payload = {
      params: {
        action: 'login',
        include: [
        ],
      },
    };

    return nonceStore.getNonce(payload)
        .then((response) => {
          return response;
        }).finally(() => {
          isLoading.value = false;
        });
  }

  function loadResponse() {

    isLoading.value = true;

    const payload = {
      params: {
        include: [
          'form',
        ],
      },
    };

    formResponseStore.show(responseId.value, payload)
      .then(() => {
        if (response.value.form.email_message) {
          emailMessage.value = response.value.form.email_message;
        }

        if (response.value.form.email_button) {
          buttonText.value = response.value.form.email_button;
        }
      }).finally(() => {
        isLoading.value = false;
      });
  }

</script>

<style lang="scss" scoped>

#send-back {
}
</style>

<style lang="scss">

#send-back {
}

</style>
