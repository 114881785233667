<template>
  <div class="form-workflow">
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <div class="title">Approval Workflow</div>
    <div class="subtitle">Optional for multi-step approval processes</div>

    <div class="box">
      <draggable v-model="formWorkflowSteps"
                 item-key="id"
                 tag="div"
                 :component-data="{name:'fade'}"
                 @start="drag=true"
                 @end="drag=false"
                 class="target-area"
                 v-if="formWorkflowSteps.length"
      >
        <template #item="{element, index}">
          <WorkflowStep class="is-draggable"
                        :form-workflow-step="element"
                        :step-number="index + 1"
                        :disabled="disabled"
          />
        </template>
      </draggable>

      <div v-else>
        Currently, there's no Workflow
      </div>
    </div>

    <WorkflowStep class="has-margin-top-large has-margin-left-large" />

  </div>
</template>

<script setup>

  import draggable from 'vuedraggable';
  import _ from "lodash";

  import {
  } from "@/internal";

  import {computed, onMounted, ref, toRefs, watch} from "vue";

  import WorkflowStep from "./WorkflowStep.vue";

  const isLoading = ref(false);
  const formWorkflowSteps = ref([]);

  const props = defineProps({
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    workflowSteps: {
      required: true,
      type: Array,
    },
  });
  const {
    disabled,
    workflowSteps,
  } = toRefs(props);

  const emit = defineEmits([
     'update',
  ]);

  onMounted(() => {
    formWorkflowSteps.value = workflowSteps.value;
  });

  watch(() => _.cloneDeep(formWorkflowSteps.value), () => {
    emit('update', formWorkflowSteps.value);
  });

  watch(() => _.cloneDeep(workflowSteps.value), () => {
    formWorkflowSteps.value = workflowSteps.value;
  });

</script>

<style lang="scss" scoped>

.form-workflow {
  .is-draggable {
    cursor: move;
  }
}

</style>