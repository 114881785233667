<template>
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        :can-cancel="true"
        icon="rotate"
        icon-size="large"
    />

    <IndexTable
        :action="formQuestionStore.index"
        :destroy="formQuestionStore.archive"
        :restore="formQuestionStore.restore"
        addLabel="Create New Question"
        :columns="defaultTableColumns"
        :default-status-filter="statusFilter"
        :display-status-filter="false"
        :includes="include"
        :items="formQuestionStore.data"
        paginated
        :pagination-position="paginationPosition"
        :paginator="paginator"
        resource="form-question"
        title="Questions"
        :default-sort-by="sortBy"
        @addClick="handleAdd"
        @response="handleResponse"
        @click="handleView"
    />
</template>

<script setup>

  import {ref, computed, onMounted, watch, toRefs, onBeforeMount} from "vue";
  import {useRouter} from "vue-router";

  import {
    useAuthStore,
    useFormQuestionStore,
    useSystemStore,
    findRouteByName,
  } from "@/internal";

  import IndexTable from "@/components/IndexTable.vue";

  const authStore = useAuthStore();
  const formQuestionStore = useFormQuestionStore();
  const systemStore = useSystemStore();
  const router = useRouter();
  const isLoading = ref(false);
  const include = [
    'formQuestionType',
  ];
  const sortBy = [
    'client_id',
    'question',
  ];
  const defaultTableColumns = [
    {
      field: 'id',
      label: 'ID',
      width: 40,
      numeric: true,
    },
    {
      field: 'db_retain',
      label: 'Saved to Company Data',
      component: 'DBRetainQuestionColumn',
    },
    {
      field: 'question',
      label: 'Question',
      component: 'HTMLClickColumn',
      sortable: true,
      editable: true,
    },
    {
      field: 'question_note',
      label: 'Note',
      component: 'HTMLColumn',
      editable: true,
    },
    {
      field: 'formQuestionType',
      label: 'Type',
      component: 'QuestionTypeColumn',
    },
  ];
  let paginator = ref({
    perPage: 10,
  });

  const props = defineProps({
    statusFilter: {
      required: false,
      type: String,
      default: 'active',
    },
  });
  const {
    statusFilter,
  } = toRefs(props);

  const paginationPosition = computed(() => {
    return "both";
  });

  function handleAdd() {
    const route = findRouteByName(`questions/create`);

    router.push(route);
  }

  function handleResponse(response) {
    if (response.paginator) {
      paginator.value = response.paginator;
    }
    else {
      paginator.value = {
        currentPage: 1,
        total: response.data.length,
      };
    }
  }

  function handleDelete() {

  }

  function handleView(item) {
    const route = findRouteByName(`questions/${statusFilter.value}/show`);

    route.params = {
      ...route.params,
      questionId: item.id,
    };

    router.push(route);
  }

</script>

<style scoped>

</style>