import { deserialize } from "jsonapi-fractal";

import {
  BaseAPI,
} from '@/internal';

export default class ClientAPI extends BaseAPI {

  update(id, data) {
    return this.put({
      url: `clients/${id}`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        data: deserialize(res),
      }
    })
  }
};