import SystemPreferences from "@/pages/Settings/SystemPreferences.vue";

export default [
  {
    name: 'settings/system-preferences',
    path: '/settings/system-preferences',
    component: SystemPreferences,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'cog',
      title: 'System Preferences',
    },
  },
]
