import {defineStore} from "pinia";
import {deserialize, serialize} from "jsonapi-fractal";
import FormResponseAPI from "@/api/FormResponseAPI";
import {
  utcToLocal,
} from "@/internal";

export const useFormResponseStore = defineStore('form-response', {
  state: () => ({
    data: [],
    stats: {},
  }),
  actions: {
    clear() {
      this.data = [];
    },
    find(id) {
      return this.data.find(data => parseInt(data.id) === parseInt(id)) || null;
    },
    index(payload) {
      const { params } = payload;

      return new FormResponseAPI().index(params)
      .then((response) => {
        this.data = response.data.map((data) => {
          return {
            ...data,
            submitted_at: utcToLocal(data.submitted_at),
          };
        });
        return response;
      });
    },
    show(id, payload) {
      const { params } = payload;

      return new FormResponseAPI().show(id, params)
        .then((response) => {
          const obj = {
            ...response.data,
            submitted_at: utcToLocal(response.data.submitted_at),
            formResponseWorkflowSteps: response.data.formResponseWorkflowSteps?.map((step) => {
              return {
                ...step,
                completed_at: utcToLocal(step.completed_at),
              }
            }) || [],
          };

          let index = 0;
          for (index = 0; index < this.data.length; index++) {
            if (parseInt(this.data[index].id) === parseInt(id)) {
              this.data[index] = {
                ...obj,
              }

              break;
            }
          }
          if (index === this.data.length) {
            this.data[index] = obj;
          }

          return obj;
        });
    },
    update(id, payload) {
      const { params } = payload;

      return new FormResponseAPI().update(id, params)
        .then((response) => {
          return response;
        });
    },
    delete(id) {
      return new FormResponseAPI().delete(id)
        .then((response) => {
          return response;
        });
    },
    updateStatus(id, payload) {
      const { params } = payload;

      return new FormResponseAPI().updateStatus(id, params)
        .then((response) => {
          return response;
        });
    },
    sendBack(id, payload) {
      const { params } = payload;

      return new FormResponseAPI().sendBack(id, params)
        .then((response) => {
          return response;
        });
    },
    sendEmail(id, payload) {
      const { params } = payload;

      return new FormResponseAPI().sendEmail(id, params)
        .then((response) => {
          return response;
        });
    },
    responseStats(payload) {
      const { params } = payload;

      return new FormResponseAPI().stats(params)
        .then((response) => {
          this.stats = response.data.data;
          return response;
        });
    },
    pdf(id, name, payload) {
      const { params } = payload;

      return new FormResponseAPI().pdf(id, name, params)
        .then((response) => {
        });
    },
  },
  persist: true,
});
