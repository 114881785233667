import {defineStore} from "pinia";
import {deserialize, serialize} from "jsonapi-fractal";
import FormFileAPI from "@/api/Subs/FormFileAPI";
import {
  utcToLocal,
} from "@/internal";

export const useSubFormFileStore = defineStore('sub-form-file', {
  state: () => ({
    data: [],
  }),
  actions: {
    store(id, payload) {

      return new FormFileAPI().store(id, payload)
        .then((response) => {
          return response;
        });
    },
  },
  persist: true,
});
