<template>
    <div class="locked-column no-wrap">
      <o-icon pack="fas" :icon="value ? 'lock' : 'lock-open'" :variant="value ? 'secondary' : 'light'" />
    </div>
</template>

<script setup>

import {toRefs} from "vue";

const props = defineProps({
  row: {
    required: true,
  },
  value: {
    required: true,
  },
});
const {
  row,
    value,
} = toRefs(props);

</script>

<style scoped lang="scss">
    .locked-column {
        align-items: center;
        display: flex;
        justify-content: center;
        padding-top: 11px;
    }
</style>
