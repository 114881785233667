import {defineStore} from "pinia";
import ClientAPI from "@/api/ClientAPI";

export const useClientStore = defineStore('client', {
  state: () => ({
    data: [],
  }),
  actions: {
    clear() {
      this.data = [];
    },
    find(id) {
      return this.data.find(data => parseInt(data.id) === parseInt(id)) || null;
    },
    update(payload) {
      const { id, params, ...rest } = payload;

      return new ClientAPI().update(id, rest)
        .then((response) => {
          return response;
        });
    },
  },
  persist: true,
});
