<template>
    <button class="toggle"
            :class="{
                'is-active': active,
                'is-rotated': rotated,
            }"
            @click="handleClick"
    >
        <o-icon icon="bars" size="is-small" v-if="active" />
        <o-icon icon="chevron-left" size="is-small" class="arrow" v-else />
    </button>
</template>

<script setup>

  import {toRefs} from "vue";

  const emit = defineEmits(['click']);

  const props = defineProps({
    active: {
      default: false,
      required: false,
      type: Boolean,
    },
    rotated: {
      default: false,
      required: false,
      type: Boolean,
    },
  });
  const {
    active,
    rotated,
  } = toRefs(props);

  function handleClick() {
    emit('click');
  }

</script>

<style lang="scss" scoped>
    .toggle {
        align-items: center;
        border: 0.5px solid $primary;
        border-radius: 50%;
        color: $primary;
        cursor: pointer;
        display: flex;
        height: 24px;
        justify-content: center;
        outline: none;
        width: 24px;

        .icon {
            align-items: center;
            display: flex;
            height: 20px;
            justify-content: center;
            position: relative;
            transition: all .4s;
            width:20px;

            &.arrow {
                left: -1px;
                top: 1px;
            }
        }

        &.is-rotated {
            .icon {
                left: 1px;
                top: 0;
                transform: rotate(180deg);
            }
        }
    }
</style>