<template>
    <span>Logging out...</span>
</template>

<script setup>

    import { onBeforeMount } from 'vue';
    import Auth from "@/auth";
    import {
      useAuthStore,
      useClientStore, useCompanyStore,
      useCustomFieldStore,
      useDivisionStore,
      useEmailLogStore,
      useFormQuestionStore,
      useFormQuestionTypeStore,
      useFormResponseCommentStore,
      useFormResponseStore,
      useFormStore,
      useGroupStore, useNonceStore, useSystemStore, useTradeStore, useUserStore
    } from "@/internal.js";

    const authStore = useAuthStore();
    const clientStore = useClientStore();
    const companyStore = useCompanyStore();
    const customFieldStore = useCustomFieldStore();
    const divisionStore = useDivisionStore();
    const emailLogStore = useEmailLogStore();
    const formQuestionStore = useFormQuestionStore();
    const formQuestionTypeStore = useFormQuestionTypeStore();
    const formResponseCommentStore = useFormResponseCommentStore();
    const formResponseStore = useFormResponseStore();
    const formStore = useFormStore();
    const groupStore = useGroupStore();
    const nonceStore = useNonceStore();
    const systemStore = useSystemStore();
    const tradeStore = useTradeStore();
    const userStore = useUserStore();

    onBeforeMount(()=>{
      authStore.clear();
      clientStore.clear();
      companyStore.clear();
      customFieldStore.clear();
      divisionStore.clear();
      emailLogStore.clear();
      formQuestionStore.clear();
      formQuestionTypeStore.clear();
      formResponseCommentStore.clear();
      formResponseStore.clear();
      formStore.clear();
      groupStore.clear();
      nonceStore.clear();
      systemStore.clear();
      tradeStore.clear();
      userStore.clear();

      Auth.logout();
    });

</script>
