<template>
  <div></div>
</template>

<script setup>

  import { onBeforeMount } from "vue";

  import Auth from "@/auth";
  import { useAuthStore } from "@/internal";

  const authStore = useAuthStore();

  onBeforeMount(() => {
    Auth.getToken()
        .then(Auth.setTokens)
        .then(() => authStore.getMe({
          params: {
            login: true,
          }
        }))
        .then(() => {
          const redirectPath = window.sessionStorage.getItem('redirectPath') || '/';
          localStorage.removeItem('redirectPath');
          location.replace(redirectPath);
        })
        .catch(err => {
          if(err.hasOwnProperty("error") && err.error == 'Invalid State'){
            Auth.logout();
            const redirectPath = window.sessionStorage.getItem('redirectPath') || '/';
            window.location.replace(redirectPath);
          }
          console.error(err);
        });
  });

</script>

<style lang="scss" scoped>

</style>
