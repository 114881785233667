<template>
    <div class="system-question-column">
      <o-tooltip label="Saved to Company Data" variant="secondary">
        <o-icon icon="thumbtack" variant="secondary" size="small" v-if="isDBRetainQuestion" />
      </o-tooltip>
    </div>
</template>

<script setup>

import {computed, toRefs} from "vue";

const props = defineProps({
  row: {
    required: true,
  },
  value: {
    required: true,
  },
});
const {
  row,
  value,
} = toRefs(props);

const isDBRetainQuestion = computed(() => value.value);

</script>

<style lang="scss" scoped>
    .system-question-column {
      display: flex;
    }
</style>

<style lang="scss">
    .system-question-column {
    }
</style>