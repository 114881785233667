<template>
    <div class="custom-field-input">
        <o-input :id="customField.id"
                 :name="customField.id"
                 :placeholder="customField.name"
                 :disabled="disabled"
                 :type="customField.is_multiline_text_type ? 'textarea' : 'input'"
                 maxlength="255"
                 v-model="answer"
                 v-if="customField.is_text_type || customField.is_multiline_text_type"
                 @blur="handleBlur"
        />

        <o-input :id="customField.id"
                 :name="customField.id"
                 :placeholder="customField.name"
                 :disabled="disabled"
                 type="number"
                 step="any"
                 v-model="answer"
                 v-if="customField.is_numeric_type"
                 @blur="handleBlur"
        />

        <o-input :id="customField.id"
                 :name="customField.id"
                 :placeholder="customField.name"
                 :disabled="disabled"
                 type="number"
                 step="0.01"
                 v-model="answer"
                 v-if="customField.is_currency_type"
                 @blur="handleBlur"
        />

<!--        NOT ALL CUSTOM FIELDS ARE CURRENTLY USED/IMPLEMENTED IN RFQ.  WHEN THEY ARE, THESE WILL NEED TO BE AND TESTED-->
<!--        <o-radio v-for="option in customField.options"-->
<!--                 :id="customField.id"-->
<!--                 :name="customField.id"-->
<!--                 :placeholder="customField.name"-->
<!--                 :disabled="disabled"-->
<!--                 v-model="answer"-->
<!--                 root-class="checkboxes"-->
<!--                 v-if="customField.is_radio_type"-->
<!--        >-->
<!--          {{option.value}}-->
<!--        </o-radio>-->

<!--        <o-checkbox v-for="option in customField.options"-->
<!--                    :id="customField.id"-->
<!--                    :name="customField.id"-->
<!--                    :placeholder="customField.name"-->
<!--                    :disabled="disabled"-->
<!--                    v-model="answer"-->
<!--                    root-class="checkboxes"-->
<!--                    v-if="customField.is_checkbox_type"-->
<!--        >-->
<!--          {{option.value}}-->
<!--        </o-checkbox>-->

<!--        <o-dropdown aria-role="list"-->
<!--                    :id="customField.id"-->
<!--                    :name="customField.id"-->
<!--                    :placeholder="customField.name"-->
<!--                    :disabled="disabled"-->
<!--                    menu-class="menu-class"-->
<!--                    scrollable-->
<!--                    v-model="answer"-->
<!--                    v-if="customField.is_dropdown_type"-->
<!--        >-->
<!--          <template #trigger="{ active }">-->
<!--            <div class="button is-secondary is-outlined">-->
<!--              <span>{{(answer && answer.value) || '-&#45;&#45; Select -&#45;&#45;'}}</span>-->
<!--              <o-icon :icon="active ? 'caret-up' : 'caret-down'" pack="fas"></o-icon>-->
<!--            </div>-->
<!--          </template>-->

<!--          <o-dropdown-item aria-role="listitem"-->
<!--                           v-for="option in customField.options"-->
<!--                           :value="option"-->
<!--          >-->
<!--            {{option.name}}-->
<!--          </o-dropdown-item>-->
<!--        </o-dropdown>-->

<!--        <div class="" v-if="customField.is_yes_no_type">-->
<!--          <o-radio v-for="option in customField.options"-->
<!--                   :id="customField.id"-->
<!--                   :name="customField.id"-->
<!--                   :placeholder="customField.name"-->
<!--                   :disabled="disabled"-->
<!--                   v-model="answer"-->
<!--          >-->
<!--            {{option.value}}-->
<!--          </o-radio>-->
<!--        </div>-->

        <o-input :id="customField.id"
                 :name="customField.id"
                 :placeholder="customField.name"
                 :disabled="disabled"
                 type="date"
                 v-model="answer"
                 v-if="customField.is_date_type"
                 @blur="handleBlur"
        />

        <o-input :id="customField.id"
                 :name="customField.id"
                 :placeholder="customField.name"
                 :disabled="disabled"
                 type="time"
                 v-model="answer"
                 v-if="customField.is_time_type"
                 @blur="handleBlur"
        />

        <o-input :id="customField.id"
                 :name="customField.id"
                 :placeholder="customField.name"
                 :disabled="disabled"
                 type="datetime-local"
                 v-model="answer"
                 v-if="customField.is_date_time_type"
                 @blur="handleBlur"
        />
    </div>
</template>

<script setup>

  import {computed, onMounted, ref, toRefs, watch} from "vue";

  import CurrencyValueInput from "@/components/Pickers/CurrencyValueInput.vue";

  import {
  } from "@/internal";

  const answer = ref();

  const props = defineProps({
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    customField: {
      required: true,
      type: Object,
    },
    value: {
      required: false,
    },
  });
  const {
    disabled,
    customField,
    value,
  } = toRefs(props);

  const emit = defineEmits([
      'input',
      'blur'
  ]);

  onMounted(() => {
    initAnswer();
  });

  function handleAnswerUpdate(value) {
    answer.value = value;
  }

  function handleBlur(){
    emit('blur');
  }

  function initAnswer() {
    answer.value = value.value;
  }

  watch(answer, () => {
    emit('input', answer.value);
  });

  watch(value, () => {
    initAnswer();
  });

</script>

<style lang="scss" scoped>
    .custom-field-input {
      margin-bottom: 1em;

      .multi-select {
        width: 100%;
        max-height: 300px;
        overflow-y: scroll;
      }

      .checkboxes {
        display: flex;
        margin-bottom: .5em;
      }

      .menu-class {
        max-height: 300px;
      }

    }
</style>

<style lang="scss">
    .custom-field-input {

      .field-addons {
        display: flex;
        flex-direction: column;

        .help {
          margin-top: 0;
          margin-bottom: .75em;
        }
      }
    }
</style>
