<template>
    <div class="company-column no-wrap" v-if="value">
      <ViewRFQCompany :company-id="value.id" :icon-only="true" />
        {{ value.name }}
    </div>
</template>

<script setup>

import {toRefs} from "vue";
import ViewRFQCompany from "@/components/ViewRFQCompany.vue";

const props = defineProps({
  row: {
    required: true,
  },
  value: {
    required: true,
  },
});
const {
  row,
  value,
} = toRefs(props);

</script>

<style scoped lang="scss">
    .company-column {
        align-items: center;
        display: flex;
    }
</style>