import {defineStore} from "pinia";
import {deserialize, serialize} from "jsonapi-fractal";
import FormAPI from "@/api/FormAPI";
import {
  utcToLocal,
} from "@/internal";
import FormQuestionAPI from "@/api/FormQuestionAPI.js";

export const useFormStore = defineStore('form', {
  state: () => ({
    data: [],
  }),
  actions: {
    clear() {
      this.data = [];
    },
    find(id) {
      return this.data.find(data => parseInt(data.id) === parseInt(id)) || null;
    },
    index(payload) {
      const { params } = payload;

      return new FormAPI().index(params)
      .then((response) => {
        this.data = response.data.map((data) => {
          return {
            ...data,
            updated_at: utcToLocal(data.updated_at),
            emails: data.emails?.map(email => {
              return {
                ...email,
                sent_on: utcToLocal(email.sent_on),
              };
            }),
          };
        });
        return response;
      });
    },
    show(id, payload) {
      const { params } = payload;

      return new FormAPI().show(id, params)
        .then((response) => {
          return this.updateData(id, response);
        });
    },
    store(payload) {
      return new FormAPI().store(payload)
        .then((response) => {
          return response;
        });
    },
    update(payload) {
      const { id, params, ...rest } = payload;

      return new FormAPI().update(id, rest)
        .then((response) => {
          return response;
        });
    },
    archive(id) {
      return new FormAPI().archive(id)
        .then((response) => {
          return this.updateData(id, response);
        });
    },
    restore(id) {
      return new FormAPI().restore(id)
        .then((response) => {
          return this.updateData(id, response);
        });
    },
    delete(id) {
      return new FormAPI().destroy(id)
        .then((response) => {
          return response;
        });
    },
    available(payload) {
      const { params } = payload;

      return new FormAPI().available(params)
        .then((response) => {
          this.data = response.data.map((data) => {
            return {
              ...data,
              updated_at: utcToLocal(data.updated_at),
              emails: data.emails?.map(email => {
                return {
                  ...email,
                  sent_on: utcToLocal(email.sent_on),
                };
              }),
            };
          });
          return response;
        });
    },
    send(id, payload) {
      return new FormAPI().send(id, payload)
        .then((response) => {
          return response;
        });
    },
    files(id, payload) {
      const { params } = payload;

      return new FormAPI().files(id, params)
        .then((response) => {
          const form = {
            ...this.find(id),
            files: response.data.map((file) => {
              return {
                ...file,
                uploaded_on: utcToLocal(file.uploaded_on),
              };
            }),
          };

          let index = null;
          for (index = 0; index < this.data.length; index++) {
            if (parseInt(this.data[index].id) === parseInt(id)) {
              this.data[index] = {
                ...form,
              }
            }
          }
          if (index === this.data.length) {
            this.data[index] = form;
          }

          return form.files;
        });
    },
    updateFile(formId, fileId, payload) {
      const { params, ...rest } = payload;

      return new FormAPI().updateFile(formId, fileId, rest)
        .then((response) => {
          return response;
        });
    },
    deleteFile(formId, fileId) {
      return new FormAPI().destroyFile(formId, fileId)
        .then((response) => {
          return response;
        });
    },
    previewFax(id, name, payload) {
      const { params } = payload;

      return new FormAPI().previewFax(id, name, params)
        .then((response) => {
        });
    },
    updateData(id, response) {
      const obj = {
        ...response.data,
        updated_at: utcToLocal(response.data.updated_at),
        emails: response.data.emails?.map(email => {
          return {
            ...email,
            sent_on: utcToLocal(email.sent_on),
          };
        }),
      };

      let index = null;
      for (index = 0; index < this.data.length; index++) {
        if (parseInt(this.data[index].id) === parseInt(id)) {
          this.data[index] = {
            ...obj,
          }

          break;
        }
      }
      if (index === this.data.length) {
        this.data[index] = obj;
      }

      return obj;
    },
    basicTemplate(payload) {
      const { params } = payload;
      return new FormAPI().basicTemplate(params)
        .then((response) => {
          return response;
        });
    },
  },
  persist: true,
});
