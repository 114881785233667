import { createApp }  from 'vue';
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { library } from '@fortawesome/fontawesome-svg-core';
import router from "@/router";
import App from './App.vue'
import Oruga from "@oruga-ui/oruga-next";
import { bulmaConfig } from '@oruga-ui/theme-bulma'

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import CompanyColumn from '@/components/TableColumns/CompanyColumn.vue';
import DBRetainQuestionColumn from '@/components/TableColumns/DBRetainQuestionColumn.vue';
import EmailEventColumn from '@/components/TableColumns/EmailEventColumn.vue';
import EmailEventsDetailsColumn from '@/components/TableColumns/EmailEventsDetailsColumn.vue';
import HTMLColumn from '@/components/TableColumns/HTMLColumn.vue';
import HTMLClickColumn from '@/components/TableColumns/HTMLClickColumn.vue';
import LockedColumn from '@/components/TableColumns/LockedColumn.vue';
import NameColumn from '@/components/TableColumns/NameColumn.vue';
import QuestionTypeColumn from '@/components/TableColumns/QuestionTypeColumn.vue';
import TextColumn from '@/components/TableColumns/TextColumn.vue';
import UserNameColumn from '@/components/TableColumns/UserNameColumn.vue';
import WorkflowColumn from '@/components/TableColumns/WorkflowColumn.vue';

import {
  faAcorn,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowAltRight,
  faArrowRight,
  faArrowAltUp,
  faArrowsAlt,
  faArrowTurnDownLeft,
  faArrowUp,
  faArrowsV,
  faBallotCheck,
  faBan,
  faBars,
  faBell,
  faBuilding,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faChalkboard,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCity,
  faClock,
  faCog,
  faComment,
  faComments,
  faCommentCheck,
  faCopy,
  faCreditCard,
  faCube,
  faEdit,
  faDownload,
  faEllipsisHAlt,
  faEnvelope,
  faEnvelopeOpen,
  faExclamationCircle,
  faExternalLink,
  faEye,
  faFilter,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileImage,
  faFileImport,
  faFileInvoice,
  faFilePdf,
  faFilePen,
  faFilePlus,
  faFiles,
  faFileSignature,
  faFileSlash,
  faFileUpload,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faGlobeAmericas,
  faGraduationCap,
  faHammer,
  faHardHat,
  faHistory,
  faIdCard,
  faInbox,
  faInboxes,
  faInboxIn,
  faLifeRing,
  faListCheck,
  faLock,
  faLongArrowLeft,
  faLongArrowRight,
  faMailbox,
  faMapMarkedAlt,
  faMessagesQuestion,
  faMousePointer,
  faNetworkWired,
  faNewspaper,
  faObjectGroup,
  faPaperPlane,
  faPennant,
  faPencil,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faReceipt,
  faRocket,
  faRulerTriangle,
  faSearch,
  faShareSquare,
  faSpinner,
  faSquare,
  faSquareCheck,
  faStar,
  faStickyNote,
  faSync,
  faSyncAlt,
  faTableCellsLarge,
  faThumbsDown,
  faThumbsUp,
  faThumbTack,
  faTimes,
  faTimesCircle,
  faTimesOctagon,
  faTools,
  faTrafficCone,
  faTrashAlt,
  faTrashRestore,
  faUndo,
  faUpload,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";

import {
  faAddressCard as fasAddressCard,
  faAngleDown as fasAngleDown,
  faAngleLeft as fasAngleLeft,
  faAngleRight as fasAngleRight,
  faAngleUp as fasAngleUp,
  faArrowLeft as fasArrowLeft,
  faArrowRight as fasArrowRight,
  faArrowsAlt as fasArrowsAlt,
  faArrowTurnDownLeft as fasArrowTurnDownLeft,
  faArrowUp as fasArrowUp,
  faArrowUpToLine as fasArrowUpToLine,
  faAsterisk as fasAsterisk,
  faBuilding as fasBuilding,
  faCaretUp as fasCaretUp,
  faCaretDown as fasCaretDown,
  faChartLine as fasChartLine,
  faCircleInfo as fasCircleInfo,
  faCircleNotch as fasCircleNotch,
  faCity as fasCity,
  faComment as fasComment,
  faCopy as fasCopy,
  faDiagramSankey as fasDiagramSankey,
  faEnvelope as fasEnvelope,
  faEye as fasEye,
  faFiles as fasFiles,
  faFileExcel as fasFileExcel,
  faFilePdf as fasFilePdf,
  faFilePen as fasFilePen,
  faFilePlus as fasFilePlus,
  faFileSlash as fasFileSlash,
  faInboxes as fasInboxes,
  faInboxIn as fasInboxIn,
  faListCheck as fasListCheck,
  faLock as fasLock,
  faLockOpen as fasLockOpen,
  faMailbox as fasMailbox,
  faMessageQuestion as fasMessageQuestion,
  faMessagesQuestion as fasMessagesQuestion,
  faPenToSquare as fasPenToSquare,
  faRotate as fasRotate,
  faSort as fasSort,
  faThumbsDown as fasThumbsDown,
  faThumbsUp as fasThumbsUp,
  faThumbTack as fasThumbTack,
  faTrashAlt as fasTrashAlt,
  faTriangleExclamation as fasTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faAcorn,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowAltRight,
  faArrowRight,
  faArrowAltUp,
  faArrowsAlt,
  faArrowTurnDownLeft,
  faArrowUp,
  faArrowsV,
  faBallotCheck,
  faBan,
  faBars,
  faBell,
  faBuilding,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faChalkboard,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCity,
  faClock,
  faCog,
  faComment,
  faComments,
  faCommentCheck,
  faCopy,
  faCreditCard,
  faCube,
  faEdit,
  faDownload,
  faEllipsisHAlt,
  faEnvelope,
  faEnvelopeOpen,
  faExclamationCircle,
  faExternalLink,
  faEye,
  faFilter,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileImage,
  faFileImport,
  faFileInvoice,
  faFilePdf,
  faFilePen,
  faFilePlus,
  faFiles,
  faFileSignature,
  faFileSlash,
  faFileUpload,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faGlobeAmericas,
  faGraduationCap,
  faHammer,
  faHardHat,
  faHistory,
  faIdCard,
  faInbox,
  faInboxes,
  faInboxIn,
  faLifeRing,
  faListCheck,
  faLock,
  faLongArrowLeft,
  faLongArrowRight,
  faMailbox,
  faMapMarkedAlt,
  faMessagesQuestion,
  faMousePointer,
  faNetworkWired,
  faNewspaper,
  faObjectGroup,
  faPaperPlane,
  faPennant,
  faPencil,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faReceipt,
  faRocket,
  faRulerTriangle,
  faSearch,
  faShareSquare,
  faSpinner,
  faSquare,
  faSquareCheck,
  faStar,
  faStickyNote,
  faSync,
  faSyncAlt,
  faTableCellsLarge,
  faThumbsDown,
  faThumbsUp,
  faThumbTack,
  faTimes,
  faTimesCircle,
  faTimesOctagon,
  faTools,
  faTrafficCone,
  faTrashAlt,
  faTrashRestore,
  faUndo,
  faUpload,
  faUsers,
  fasAddressCard,
  fasAngleDown,
  fasAngleLeft,
  fasAngleRight,
  fasAngleUp,
  fasArrowLeft,
  fasArrowRight,
  fasArrowsAlt,
  fasArrowTurnDownLeft,
  fasArrowUp,
  fasArrowUpToLine,
  fasAsterisk,
  fasBuilding,
  fasCaretUp,
  fasCaretDown,
  fasChartLine,
  fasCircleInfo,
  fasCircleNotch,
  fasCity,
  fasComment,
  fasCopy,
  fasDiagramSankey,
  fasEnvelope,
  fasEye,
  fasFiles,
  fasFileExcel,
  fasFilePdf,
  fasFilePen,
  fasFilePlus,
  fasFileSlash,
  fasInboxes,
  fasInboxIn,
  fasListCheck,
  fasLock,
  fasLockOpen,
  fasMailbox,
  fasMessageQuestion,
  fasMessagesQuestion,
  fasPenToSquare,
  fasRotate,
  fasSort,
  fasThumbsDown,
  fasThumbsUp,
  fasThumbTack,
  fasTrashAlt,
  fasTriangleExclamation,
);


const customBulmaConfig = {
  ...bulmaConfig,
  iconComponent: 'font-awesome-icon',
  iconPack: 'fal',
  customIconPacks: {
    fal: {
      sizes: {
        default: 'lg',
        'is-small': 'sm',
        'is-medium': 'lg',
        'is-large': '3x',
        'is-extra-large': '5x',
      },
      iconPrefix: '',
    },
    fas: {
      sizes: {
        default: 'lg',
        'is-small': 'sm',
        'is-medium': 'lg',
        'is-large': '3x',
        'is-extra-large': '5x',
      },
      iconPrefix: '',
    },
  },
}


// Intercom

if (import.meta.env.VITE_APP_INTERCOM_APP_ID) {

  window.intercomSettings = {
    app_id: import.meta.env.VITE_APP_INTERCOM_APP_ID,
    hide_default_launcher: true,
    background_color: '#107e67',
    product: 'PipelinePrequal',
  };

  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${import.meta.env.VITE_APP_INTERCOM_APP_ID}`;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
}


import '@/assets/scss/app.scss';

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const vueApp = createApp(App);

vueApp.use(pinia)
vueApp.use(router);
vueApp.use(Oruga, customBulmaConfig)

vueApp.component('font-awesome-icon', FontAwesomeIcon);

vueApp.component('CompanyColumn', CompanyColumn);
vueApp.component('DBRetainQuestionColumn', DBRetainQuestionColumn);
vueApp.component('EmailEventsDetailsColumn', EmailEventsDetailsColumn);
vueApp.component('EmailEventColumn', EmailEventColumn);
vueApp.component('HTMLColumn', HTMLColumn);
vueApp.component('HTMLClickColumn', HTMLClickColumn);
vueApp.component('LockedColumn', LockedColumn);
vueApp.component('NameColumn', NameColumn);
vueApp.component('QuestionTypeColumn', QuestionTypeColumn);
vueApp.component('TextColumn', TextColumn);
vueApp.component('UserNameColumn', UserNameColumn);
vueApp.component('WorkflowColumn', WorkflowColumn);

vueApp.mount("#app");
