<template>
  <Page class="overview">
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        :can-cancel="true"
        icon="rotate"
        icon-size="large"
    >
    </o-loading>

    <div class="label">Responses Submitted within the Past Day</div>
    <o-pagination
        class="has-margin-bottom-large"
        :total="paginator.total"
        :per-page="parseInt(paginator.per_page)"
        :simple="false"
        :current="paginator.current_page"
        @change="loadResponses"
    />
    <o-table :data="responses"
             backend-pagination
             v-if="!isLoadingResponse"
    >
      <template #empty>
        <div class="empty">
          <o-icon icon="inbox" size="is-large"/>
          <br/>
          <div class="title has-text-centered">
            No Responses Received
          </div>
        </div>
      </template>

      <o-table-column
          v-for="column in defaultTableColumns"
          v-bind="column"
          #default="{ row }"
          v-slot="props"
      >
        <component v-if="column.component"
                   :is="column.component"
                   :row="row"
                   :value="row[column.field]"
                   @click="handleViewResponse"
        />

        <div class="no-wrap"
             v-else
        >
          {{row[column.field]}}
        </div>
      </o-table-column>
    </o-table>
    <o-pagination
        class="has-margin-top-large"
        :total="paginator.total"
        :per-page="parseInt(paginator.per_page)"
        :simple="false"
        :current="paginator.current_page"
        @change="loadResponses"
    />

    <div class="label">Responses Overview</div>
    <div class="is-flex">
      <div class="response-label"><router-link class="is-link is-secondary" :to="`/responses/inbox`">Inbox</router-link></div>
      <div>{{ stats.num_pending }}</div>
    </div>
    <div class="is-flex">
      <div class="response-label"><router-link class="is-link is-secondary" :to="`/responses/in-review`">In Review</router-link></div>
      <div>{{ stats.num_in_review }}</div>
    </div>
    <div class="is-flex">
      <div class="response-label"><router-link class="is-link is-secondary" :to="`/responses/approved`">Approved</router-link></div>
      <div>{{ stats.num_approved }}</div>
    </div>
    <div class="is-flex">
      <div class="response-label"><router-link class="is-link is-secondary" :to="`/responses/declined`">Declined</router-link></div>
      <div>{{ stats.num_declined }}</div>
    </div>
    <div class="is-flex">
      <div class="response-label"><router-link class="is-link is-secondary" :to="`/responses/sent-back`">Sent Back</router-link></div>
      <div>{{ stats.num_sent_back }}</div>
    </div>

  </Page>
</template>

<script setup>

import {DateTime} from "luxon";
import { ref, computed, onMounted, watch } from "vue";
import {useRouter} from "vue-router";

import Page from "@/components/Page.vue";

import {
  findRouteByName,
  useFormResponseStore,
} from "@/internal";

const formResponseStore = useFormResponseStore();

const defaultTableColumns = [
  {
    field: 'submitted_at',
    label: 'Submitted On',
    sortable: true,
  },
  {
    field: 'title',
    label: 'Submission',
    component: 'NameColumn',
    sortable: true,
    editable: true,
  },
  {
    field: 'formUser',
    label: 'Approver',
    component: 'UserNameColumn',
  },
  {
    field: 'company',
    label: 'Company',
    component: 'CompanyColumn',
  },
  {
    field: 'status',
    label: 'Status',
  },
  {
    field: 'formResponseWorkflow',
    label: 'Workflow (If Applicable)',
    component: 'WorkflowColumn',
  },
];
const isLoading = ref(false);
const isLoadingResponse = ref(false);
const pageRequest = ref(1);
const paginator = ref({
  per_page: 10,
});
const router = useRouter();

const responses = computed(() => {
  return formResponseStore.data.map(response => {

    let statusLabel;
    switch (response.status_key) {
        case 'pending':   statusLabel = 'Inbox';
                            break;
        case 'in_review': statusLabel = 'In Review';
                            break;
        case 'approved':  statusLabel = 'Approved';
                            break;
        case 'declined':  statusLabel = 'Declined';
                            break;
        case 'sent_back':  statusLabel = 'Sent Back';
                            break;
    }

    return {
      ...response,
      status: statusLabel,
    };
  }) || [];
})

const stats = computed(() => formResponseStore.stats || {});

onMounted(() => {
  loadResponses();
  loadResponseStats();
});

function loadResponses(page=1) {

  pageRequest.value = page;

  isLoading.value = true;
  isLoadingResponse.value = true;

  const payload = {
    params: {
      include: [
        'company',
        'formUser',
        'formResponseWorkflow.activeStep',
      ],
      page: {
        offset: pageRequest.value,
        limit: paginator.value.per_page || 10,
      },
      submitted_since: DateTime.utc().minus({days: 1}).toFormat('yyyy-MM-dd ' + '00:00:00'),
      sort: '-submitted_at',
    },
  };

  formResponseStore.index(payload)
      .then((response) => {
        if (response.paginator) {
          paginator.value = response.paginator;
        }
        else {
          paginator.value = {
            current_page: 1,
            per_page: 10,
            total: response.data.length,
          };
        }
      }).finally(() => {
        isLoading.value = false;
        isLoadingResponse.value = false;
      });
}

function loadResponseStats() {

  isLoading.value = true;

  const payload = {
    params: {
      include: [
      ],
    },
  };

  formResponseStore.responseStats(payload)
      .then((response) => {
      }).finally(() => {
        isLoading.value = false;
      });
}

function handleViewResponse(item) {
  const route = findRouteByName(`responses/show`);

  route.params = {
    ...route.params,
    responseId: item.id,
  };

  router.push(route);
}

</script>

<style lang="scss" scoped>

.overview {

  .empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5% 0;

    .title {
      margin-top: 1rem;
    }
  }

  .response-label {
    width: 130px;
  }
}

</style>

<style lang="scss">

.overview {

  .pagination-previous,
  .pagination-next,
  .pagination-link {
    color: $secondary;
  }

  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: $secondary !important;
  }

  .pagination-previous:hover,
  .pagination-next:hover,
  .pagination-link:hover,
  .pagination-previous[disabled],
  .pagination-previous.is-disabled,
  .pagination-next[disabled],
  .pagination-next.is-disabled,
  .pagination-link[disabled],
  .pagination-link.is-disabled {
    color: $secondary;
  }
}

</style>