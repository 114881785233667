import {
  findRouteByName,
  Menu,
  userHasPermission,
} from '@/internal';

import Auth from "@/auth";

export default (payload = {}) => {

  const {
    formId,
    responseId,
    user,
  } = payload;

  return new Menu({
    menuItems: [
      {
        title: 'Overview',
        route: findRouteByName('overview'),
        when: userHasPermission('formResponse:read'),
        icon: 'chart-line',
        iconPack: 'fal',
      },
      {
        title: 'Responses',
        route: findRouteByName('responses'),
        when: userHasPermission('formResponse:read'),
        icon: 'inboxes',
        iconPack: 'fal',
        menuItems: [
          {
            route: {
              ...findRouteByName('responses/inbox'),
              params: {
              },
            },
            title: 'Inbox',
            icon: 'inbox-in',
            iconPack: 'fal',
            activeWithParent: true,
          },
          {
            route: {
              ...findRouteByName('responses/in-review'),
              params: {
              },
            },
            title: 'In Review',
            icon: 'file-pen',
            iconPack: 'fal',
          },
          {
            route: {
              ...findRouteByName('responses/approved'),
              params: {
              },
            },
            title: 'Approved',
            icon: 'thumbs-up',
            iconPack: 'fal',
          },
          {
            route: {
              ...findRouteByName('responses/declined'),
              params: {
              },
            },
            title: 'Declined',
            icon: 'thumbs-down',
            iconPack: 'fal',
          },
          {
            route: {
              ...findRouteByName('responses/sent-back'),
              params: {
              },
            },
            title: 'Sent Back',
            icon: 'arrow-turn-down-left',
            iconPack: 'fal',
          },
        ],
      },
      {
        title: 'Forms',
        route: findRouteByName('forms'),
        when: userHasPermission('form:read'),
        icon: 'files',
        iconPack: 'fal',
        menuItems: [
          {
            route: {
              ...findRouteByName('forms/active'),
              params: {
              },
            },
            title: 'Active',
            icon: 'files',
            iconPack: 'fal',
            activeWithParent: true,
          },
          {
            route: {
              ...findRouteByName('forms/archived'),
              params: {
              },
            },
            title: 'Archived',
            icon: 'file-slash',
            iconPack: 'fal',
          },
          {
            route: {
              ...findRouteByName('forms/create'),
              params: {
              },
            },
            when: userHasPermission('form:create'),
            title: 'Create New Form',
            icon: 'file-plus',
            iconPack: 'fal',
          },
        ],
      },
      {
        title: 'Form Questions',
        route: findRouteByName('questions'),
        when: userHasPermission('formQuestion:read'),
        icon: 'messages-question',
        iconPack: 'fal',
        menuItems: [
          {
            route: {
              ...findRouteByName('questions/active'),
              params: {
              },
            },
            title: 'Active',
            icon: 'messages-question',
            iconPack: 'fal',
            activeWithParent: true,
          },
          {
            route: {
              ...findRouteByName('questions/create'),
              params: {
              },
            },
            when: userHasPermission('formQuestion:create'),
            title: 'Create New Question',
            icon: 'file-plus',
            iconPack: 'fal',
          },
        ],
      },
    ],
  });
};
