import { deserialize } from "jsonapi-fractal";

import {
  BaseAPI,
} from '@/internal';

export default class FormResponseAPI extends BaseAPI {

  index(params) {
    return this.get({
      url: "form-responses",
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        links: res.links,
        paginator: res.meta && res.meta.pagination ? res.meta.pagination : {},
        data: deserialize(res),
      }
    })
  }

  show(id, params) {
    return this.get({
      url: `form-responses/${id}`,
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      });
  }

  store(data) {
    return this.post({
      url: `form-responses`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        data: deserialize(res),
      }
    })
  }

  update(id, data) {
    return this.put({
      url: `form-responses/${id}`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      });
  }

  delete(id) {
    return super.delete({
      url: `form-responses/${id}`,
      headers: [],
    }).then(res => {
      if (res.ok) {
        return res;
      } else {
        return this.processResponse(res);
      }
    }).then(res => {
        return {
          data: deserialize(res),
        }
      });
  }

  updateStatus(id, data) {
    return this.put({
      url: `form-responses/${id}/update-status`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        data: deserialize(res),
      }
    });
  }

  sendBack(id, data) {
    return this.put({
      url: `form-responses/${id}/send-back`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        data: deserialize(res),
      }
    });
  }

  sendEmail(id, data) {
    return this.post({
      url: `form-responses/${id}/send-email`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        data: deserialize(res),
      }
    });
  }

  stats(params) {
    return this.get({
      url: "form-responses/stats",
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        data: res,
      }
    });
  }

  pdf(id, name, params) {
    return this.get({
      url: `form-responses/${id}/pdf`,
      headers: [],
      queryParams: params,
    }).then(res => res.blob())
      .then((response) => {
        const file = new Blob(
          [response],
          {type: 'application/pdf'},
        );
        const link = document.createElement('a');
        link.href =  URL.createObjectURL(file);
        link.download = `${name}.pdf`;
        document.body.append(link);
        link.click();
        link.remove();
      });
  }
};