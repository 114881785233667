import { deserialize } from "jsonapi-fractal";

import {
  AxiosInstance,
  BaseAPI,
} from '@/internal';

export default class FormResponseAPI extends BaseAPI {

  show(params) {
    return this.get({
      url: `subs/form-responses`,
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        data: deserialize(res),
      }
    });
  }

  async store(data) {
    return AxiosInstance({
      url: `subs/form-responses/store`,
      data: data,
      method: 'POST',
    }).then(res => this.processResponse(res)
    ).catch((error) => {
      return this. processAxiosError(error);
    }).then(res => {
      return {
        data: deserialize(res),
      }
    });
  }

  pdf(id, name, params) {
    return this.get({
      url: `subs/form-responses/${id}/pdf`,
      headers: [],
      queryParams: params,
    }).then(res => res.blob())
      .then((response) => {
        const file = new Blob(
          [response],
          {type: 'application/pdf'},
        );
        const link = document.createElement('a');
        link.href =  URL.createObjectURL(file);
        link.download = `${name}.pdf`;
        document.body.append(link);
        link.click();
        link.remove();
      });
  }
};