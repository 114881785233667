import {defineStore} from "pinia";
import SystemAPI from "@/api/SystemAPI";
import appData from '/package';
import {
  BaseAPI,
  JsonApiDataStore,
} from '@/internal';

const api = new BaseAPI();

export const useSystemStore = defineStore('system', {
  state: () => ({
    navPanel: '',
    navPanelOpen: false,
    searchResults: [],
    trades: [],
    updateAvailable: false,
    updateType: null,
    version: null,
  }),
  actions: {
    clear() {
      this.navPanel = '';
      this.navPanelOpen = false;
      this.searchResults = [];
      this.trades = [];
      this.updateAvailable = false;
      this.updateType = null;
      this.version = null;
    },
    search(payload) {
      const { params } = payload;

      return new SystemAPI().search(params)
        .then((response) => {
          this.searchResults = response.data;
          return response;
        });
    },
    status() {
      return api.get('/system/status').then((response) => {
        const { version } = response.data;
        const apiVersion = appData.version.split('.');
        const appVersion = version.split('.');

        this.version = version;
        this.updateAvailable = version !== appData.version;

        if (apiVersion[0] !== appVersion[0]) {
          this.updateType = 'major';
        } else if (apiVersion[1] !== appVersion[1]) {
          this.updateType = 'minor';
        } else if (apiVersion[2] !== appVersion[2]) {
          this.updateType = 'patch';
        } else {
          this.updateType = null;
        }
      });
    },
    toggleNavPanel(panel) {
      if (this.navPanelOpen) {
        this.setNavPanel('');
        setTimeout(() => {
          this.setNavPanel(panel);
        }, 200);
      }
      else {
        this.setNavPanel(panel);
      }
    },
    setNavPanel(panel) {
      this.navPanel = panel || '';
      this.navPanelOpen = panel.length > 0;
    },
  },
  persist: true,
});
