import Index from "@/pages/Forms/Index.vue";
import Archived from "@/pages/Forms/Archived.vue";
import Create from "@/pages/Forms/Create.vue";
import Edit from "@/pages/Forms/Edit.vue";
import Embed from "@/pages/Forms/Embed.vue";
import Preview from "@/pages/Forms/Preview.vue";
import Send from "@/pages/Forms/Send.vue";
import Show from "@/pages/Forms/Show.vue";
import ViewLog from "@/pages/Forms/ViewLog.vue";

export default [
  {
    name: 'forms',
    path: '/forms',
    component: Index,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'files',
      title: 'Active Forms',
    },
  },
  {
    name: 'forms/show',
    path: '/forms/:formId/show',
    component: Show,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'forms/show',
      ],
      icon: 'eye',
      useModelForTitle: 'form',
    },
  },
  {
    name: 'forms/active',
    path: '/forms/active',
    component: Index,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'files',
      title: 'Active Forms',
    },
  },
  {
    name: 'forms/active/edit',
    path: '/forms/active/:formId/edit',
    component: Edit,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'forms/active',
        'forms/active/show',
        'forms/active/edit',
      ],
      icon: 'eye',
      useModelForTitle: 'form',
    },
  },
  {
    name: 'forms/active/embed',
    path: '/forms/active/:formId/embed',
    component: Embed,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'forms/active',
        'forms/active/show',
        'forms/active/embed',
      ],
      icon: 'table-cells-large',
      useModelForTitle: 'form',
    },
  },
  {
    name: 'forms/active/send',
    path: '/forms/active/:formId/send',
    component: Send,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'forms/active',
        'forms/active/show',
        'forms/active/send',
      ],
      icon: 'eye',
      useModelForTitle: 'form',
    },
  },
  {
    name: 'forms/active/show',
    path: '/forms/active/:formId/show',
    component: Show,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'forms/active',
        'forms/active/show',
      ],
      icon: 'eye',
      useModelForTitle: 'form',
    },
  },
  {
    name: 'forms/active/log',
    path: '/forms/active/:formId/log',
    component: ViewLog,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'forms/active',
        'forms/active/show',
        'forms/active/log',
      ],
      icon: 'eye',
      useModelForTitle: 'form',
    },
  },
  {
    name: 'forms/archived',
    path: '/forms/archived',
    component: Archived,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'file-slash',
      title: 'Archived Forms',
    },
  },
  {
    name: 'forms/archived/show',
    path: '/forms/archived/:formId/show',
    component: Show,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'forms/archived',
        'forms/archived/show',
      ],
      icon: 'eye',
      useModelForTitle: 'form',
    },
  },
  {
    name: 'forms/archived/log',
    path: '/forms/archived/:formId/log',
    component: ViewLog,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'forms/archived',
        'forms/archived/show',
        'forms/archived/log',
      ],
      icon: 'eye',
      useModelForTitle: 'form',
    },
  },
  {
    name: 'forms/create',
    path: '/forms/create',
    component: Create,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'forms/active'
      ],
      icon: 'file-plus',
      title: 'Create New Form',
    },
  },
  {
    name: 'subs/forms/preview',
    path: '/forms/:formId/preview',
    component: Preview,
    meta: {
      requiresAuth: false,
      breadcrumbs: [
      ],
      icon: 'eye',
      useModelForTitle: 'form',
    },
  },
]
