<template>
  <Page :title="pageTitle">
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <CreateEdit :form="form" v-if="form" />
  </Page>
</template>

<script setup>

  import Page from "@/components/Page.vue";
  import CreateEdit from "./CreateEdit.vue";

  import {
    useFormStore,
  } from "@/internal";

  import {useRoute} from "vue-router";
  import {computed, onMounted, ref} from "vue";

  const formStore = useFormStore();
  const route = useRoute();
  const isLoading = ref(false);

  const form = computed(() => {
    return formStore.find(formId.value);
  });

  const formId = computed(() => {
    return route.params.formId || null;
  });

  const pageTitle = computed(() => {
    return "Edit: " + (form.value ? form.value.name : '');
  });

  onMounted(() => {
    loadForm();
  });

  function loadForm() {

    isLoading.value = true;

    const payload = {
      params: {
        include: [
          'approvedGroup',
          'businessUnit',
          'questions.options',
          'sendEmailTo',
          'subjectQuestion',
          'unapprovedGroup',
          'workflow.workflowSteps.emailRecipient',
        ],
      },
    };

    formStore.show(formId.value, payload).finally(() => {
      isLoading.value = false;
    });
  }

</script>

<style lang="scss" scoped>

</style>