import { deserialize } from "jsonapi-fractal";

import {
  BaseAPI,
} from '@/internal';

export default class FormAnswerAPI extends BaseAPI {

  update(id, data) {
    return this.patch({
      url: `form-answers/${id}`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }
};