<template>
    <div class="email-labels-column no-wrap">
      <div class="labels">
        <div class="item" :class="{'highlight' : value}" v-if="label.toLowerCase() === 'processed'">
          <o-icon icon="arrow-alt-right"/>
          Processed
        </div>
        <div class="item" :class="{'highlight' : value}" v-if="label.toLowerCase() === 'deferred'">
          <o-icon icon="clock"/>
          Deferred
        </div>
        <div class="item" :class="{'highlight' : value}" v-if="label.toLowerCase() === 'dropped'">
          <o-icon icon="times-octagon"/>
          Dropped
        </div>
        <div class="item" :class="{'highlight' : value}" v-if="label.toLowerCase() === 'delivered'">
          <o-icon icon="check"/>
          Delivered
        </div>
        <div class="item" :class="{'highlight' : value}" v-if="label.toLowerCase() === 'bounced'">
          <o-icon icon="arrow-alt-up"/>
          Bounced
        </div>
        <div class="item" :class="{'highlight' : value}" v-if="label.toLowerCase() === 'opened'">
          <o-icon icon="envelope-open"/>
          Opened
        </div>
        <div class="item" :class="{'highlight' : value}" v-if="label.toLowerCase() === 'clicked'">
          <o-icon icon="globe-americas"/>
          Clicked
        </div>
      </div>
    </div>
</template>

<script setup>

import {toRefs} from "vue";

const props = defineProps({
  label: {
    required: true,
    type: String,
  },
  row: {
    required: true,
  },
  value: {
    required: false,
  },
});
const {
  label,
  row,
  value,
} = toRefs(props);

</script>

<style lang="scss" scoped>
    .email-labels-column {
        align-items: center;
        display: flex;
        width: 90px;

      .labels {
        display: flex;
        min-width: 81px;

        .item {
          align-items: center;
          border: 1px solid $grey-lighter;
          border-radius: 3px;
          display: flex;
          flex: 1;
          flex-direction: column;
          font-size: .8em;
          justify-content: center;
          margin: 2.5px;
          padding: 10px 2.5px 5px;
          color: $grey-lighter;

          &.highlight {
            border-color: $secondary;
            color: $secondary;
          }

          .icon {
            margin-bottom: 5px;
          }
        }
      }
    }
</style>