<template>
  <Page :title="pageTitle">
    <o-loading
        :full-page="true"
        :active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <template v-slot:addon>
      <div class="tag is-warning" v-if="form.archived">Archived</div>
      <div class="tag is-warning" v-else-if="canEdit && !canSend && !isLoading">Note: Complete Form in order to Send</div>
    </template>

    <template v-slot:controls>
      <div class="buttons is-flex-wrap-nowrap">
        <router-link class="button is-primary" :to="`/forms/${status}/${formId}/edit`" v-if="canEdit">
          <o-icon icon="pencil" class="has-margin-right" />
          Edit
        </router-link>
        <div class="button is-primary" @click="handleArchive" v-if="canArchive">
          <o-icon icon="archive" class="has-margin-right" />
          Archive
        </div>
        <div class="button is-primary" @click="handleRestore" v-if="canRestore">
          <o-icon icon="trash-restore" class="has-margin-right" />
          Restore
        </div>
        <div class="button is-primary" @click="handleDelete" v-if="canDelete">
          <o-icon icon="trash-alt" class="has-margin-right" />
          Delete
        </div>
        <router-link class="button is-primary" :to="`/forms/${status}/${formId}/send`" v-if="canSend">
          <o-icon icon="envelope" class="has-margin-right" />
          Send
        </router-link>
      </div>
      <div class="buttons is-flex-wrap-nowrap">
        <div class="button is-primary" @click="handlePreview" v-if="canRead">
          <o-icon icon="eye" class="has-margin-right" />
          Preview
        </div>
        <router-link class="button is-primary" :to="`/forms/${status}/${formId}/log`" v-if="form.emails_exist">
          <o-icon icon="list-check" class="has-margin-right" />
          View Log
        </router-link>
        <router-link class="button is-primary" :to="`/forms/${status}/${formId}/embed`" v-if="!form.archived && canSend">
          <o-icon icon="table-cells-large" class="has-margin-right" />
          Embed
        </router-link>
      </div>
    </template>

    <div id="form" v-if="form">
      <div class="subtitle">Form Details</div>
      <div class="box form-box">
        <o-field>
          <template #label>
            Form Name
            <o-tooltip label="Required" v-if="!form.name">
              <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
            </o-tooltip>
          </template>
          {{form.name}}
        </o-field>

        <o-field label="Business Unit" v-if="businessUnits">{{(form.businessUnit && form.businessUnit.name) || 'Not Specified'}}</o-field>

        <o-field>
          <template #label>
            Title
            <o-tooltip label="Required" v-if="!form.title">
              <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
            </o-tooltip>
          </template>
          {{form.title}}
        </o-field>

        <o-field label="Instructions">
          <HTMLText :value="form.instructions" class="has-margin-bottom-large" />
        </o-field>

        <o-field>
          <template #label>
            Submission Title
            <o-tooltip label="Required" v-if="!form.subjectQuestion || !form.subjectQuestion.question">
              <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
            </o-tooltip>
          </template>
          {{(form.subjectQuestion && form.subjectQuestion.question) || 'Not Specified'}}
        </o-field>

        <o-field label="Send Email Alert Upon Form Response Submission"
                 body-horizontal-class="body-horizontal-class"
                 label-horizontal-class="label-horizontal-class"
                 horizontal
        >
          <o-switch v-model="form.send_email_alert" disabled />

          <template v-if="form.send_email_alert">
            <o-field class="has-margin-right">
              <template #label>
                Email Recipient
                <o-tooltip label="Required" v-if="!form.sendEmailTo">
                  <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
                </o-tooltip>
              </template>
              {{(form.sendEmailTo && `${form.sendEmailTo.first_name} ${form.sendEmailTo.last_name} <${form.sendEmailTo.email}>`) || 'Not Specified'}}
            </o-field>

            <o-field>
              <template #label>
                Email Subject
                <o-tooltip label="Required" v-if="!form.send_email_subject">
                  <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
                </o-tooltip>
              </template>
              {{ form.send_email_subject }}
            </o-field>
        </template>
      </o-field>

        <o-field label='Assign subs that are marked "Approved/Qualified" to the following group'
        >
          {{(form.approvedGroup && form.approvedGroup.name) || 'Not Specified'}}
        </o-field>

        <o-field label='Assign subs that are marked "Unapproved/Unqualified" to the following group'
        >
          {{(form.unapprovedGroup && form.unapprovedGroup.name) || 'Not Specified'}}
        </o-field>

        <o-field label="Bypass Approval Requirement"
                 body-horizontal-class="body-horizontal-class"
                 label-horizontal-class="label-horizontal-class"
                 horizontal
        >
          <o-switch v-model="form.bypass_approval" disabled />
        </o-field>
      </div>

      <div class="subtitle">Form Questions
        <o-tooltip label="Required" class="is-uppercase" v-if="!questions || !questions.length">
          <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
        </o-tooltip>
      </div>
      <div class="box form-box">
        <template v-for="question in questions">
          <div>
            <QuestionPicker :question="question" disabled :preview-mode="true" />
          </div>
        </template>
      </div>

      <div class="subtitle">Workflow
        <o-tooltip label="Workflow Incomplete" class="is-uppercase" v-if="workflowIncomplete">
          <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
        </o-tooltip>
      </div>
      <div class="box form-box">
        <template v-for="(step, index) in workflowSteps" v-if="workflowSteps.length">
          <WorkflowStep :form-workflow-step="step"
                        :step-number="index + 1"
                        disabled
          />
        </template>

        <div v-else>
          No Workflow
        </div>
      </div>

    </div>
  </Page>
</template>

<script setup>

  import {useRoute, useRouter} from "vue-router";

  import HTMLText from "@/components/HTMLText.vue";
  import QuestionPicker from "@/components/Pickers/QuestionPicker.vue";
  import Page from "@/components/Page.vue";
  import WorkflowStep from "./WorkflowStep.vue";

  import {
    Dialog,
    findRouteByName,
    useAuthStore,
    useFormStore,
    useGroupStore,
    useTradeStore,
    userHasPermission,
  } from "@/internal";
  import {computed, onMounted, ref} from "vue";

  const authStore = useAuthStore();
  const formStore = useFormStore();
  const groupStore = useGroupStore();
  const tradeStore = useTradeStore();
  const route = useRoute();
  const router = useRouter();
  const isLoading = ref(false);

  const businessUnits = computed(() => authStore.me.associatedBusinessUnits || null);

  const canArchive = computed(() => {
    return userHasPermission('form:archive') && !form.value.archived;
  });

  const canDelete = computed(() => {
    return userHasPermission('form:delete') && !form.value.form_locked && form.value.archived;
  });

  const canEdit = computed(() => {
    return userHasPermission('form:update') && !form.value.form_locked && !form.value.archived;
  });

  const canRead = computed(() => {
    return userHasPermission('form:read');
  });

  const canSend = computed(() => {
    return userHasPermission('form:update') && !form.value.archived && questions.value.length
        && form.value.name && form.value.title && (form.value.subject_question_id || parseInt(form.value.subject_question_id) === 0)
        && (!form.value.send_email_alert || (form.value.send_email_alert && form.value.send_email_to_user_id && form.value.send_email_subject))
        && !questions.value.filter((question) => {
          const optionsType = question.is_group_multi_select_type || question.is_group_single_select_type || question.is_trade_multi_select_type || question.is_trade_single_select_type || question.is_checkbox_type || question.is_radio_type || question.is_dropdown_type;
          return optionsType && (!question.options || !question.options.length);
        }).length
        && !workflowIncomplete.value;
  });

  const canRestore = computed(() => {
    return userHasPermission('form:restore') && form.value.archived;
  });

  const form = computed(() => {
    return (!isLoading.value && formStore.find(formId.value)) || {};
  });

  const formId = computed(() => {
    return route.params.formId || null;
  });

  const pageTitle = computed(() => {
    return form.value ? form.value.name : '';
  });

  const groups = computed(() => groupStore.data || []);

  const questions = computed(() => {
    return (form.value && form.value.questions && form.value.questions.sort((a, b) => a.rank > b.rank ? 1 : -1)) || [];
  });

  const status = computed(() => {
    return form.value ? (form.value.archived ? 'archived' : 'active') : '';
  });

  const trades = computed(() => tradeStore.data || []);

  const workflowIncomplete = computed(() => workflowSteps.value.filter((step) => {
      return step.email_when_ready && (!step.emailRecipient || !step.email_subject);
    }).length
  );

  const workflowSteps = computed(() => {
    return (form.value && form.value.workflow && form.value.workflow.workflowSteps) || [];
  });

  onMounted(() => {
    loadForm();
    loadGroups();
    loadTrades();
  });

  function loadForm() {
    isLoading.value = true;

    const payload = {
      params: {
        include: [
          'approvedGroup',
          'businessUnit',
          'questions.customField',
          'questions.options',
          'sendEmailTo',
          'subjectQuestion',
          'unapprovedGroup',
          'workflow.workflowSteps.emailRecipient',
        ],
      },
    };

    formStore.show(formId.value, payload).finally(() => {
      isLoading.value = false;
    });
  }

  function loadGroups() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    groupStore.index(payload)
        .then(() => {
        }).finally(() => {
    });
  }

  function loadTrades() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    tradeStore.index(payload)
        .then(() => {
        }).finally(() => {
    });
  }

  function handleArchive() {
    if (!canArchive.value) {
      return;
    }

    isLoading.value = true;

    formStore.archive(formId.value)
      .then(() => {
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  function handlePreview() {
    const route = findRouteByName(`subs/forms/preview`);
    route.params = {
      ...route.params,
      formId: formId.value,
    };
    const routeData = router.resolve(route);

    window.open(routeData.href, '_blank');
  }

  function handleRestore() {
    if (!canRestore.value) {
      return;
    }

    isLoading.value = true;

    formStore.restore(formId.value)
        .then(() => {
          loadForm();
        })
        .finally(() => {
          isLoading.value = false;
        });
  }

  function deleteForm() {
    if (!canDelete.value) {
      return;
    }

    isLoading.value = true;

    formStore.delete(formId.value)
        .finally(() => {
          isLoading.value = false;

          const route = findRouteByName(`forms/archived`);
          router.push(route);
        });
  }

  function handleDelete() {

    Dialog({
      title: 'Delete Form',
      message: `Are you sure you want to delete this form?`,
      confirmText: 'Yes',
      canCancel: true,
      variant: 'warning',
      onCancel: () => {
      },
      onConfirm: () => {
        deleteForm();
      },
    });
  }

</script>

<style lang="scss" scoped>

#form {

}

</style>

<style lang="scss">

#form {
  .body-horizontal-class {
    .field {
      text-align: left;
    }
  }

  .label-horizontal-class {
    flex-basis: 300px;
    flex-grow: unset;
    flex-shrink: unset;

    .label {
      margin-top: 0;
      text-align: left;
      line-height: normal;
    }
  }
}

</style>