<template>
    <NavMenu :menu="menu" />
</template>

<script setup>

import NavMenu from '../NavMenu.vue';

import {
  Menu,
} from '@/internal';

import {computed} from "vue";

const emit = defineEmits([
   'close',
]);

const menu = computed(() => {
  return new Menu({
    title: 'Help',
    menuItems: [
      {
        icon: 'comment',
        title: 'Chat with Support',
        iconPack: 'fal',
        onClick: () => {
          emit('close');
          if (import.meta.env.VITE_APP_INTERCOM_APP_ID) {
            Intercom('show');
          } else {
            alert('No Intercom on local. On Staging on Production this will launch the Intercom window.');
          }
        },
      },
    ],
  });
});

</script>