<template>
  <div id="question-answer">
    <o-field addons-class="field-addons"
             :label-horizontal-class="isInstructionType ? 'label-horizontal-instruction-class' : 'label-horizontal-class'"
             label-class="label-class"
             root-class="root-class"
             horizontal
    >
      <template #label>
        <HTMLText :value="question.question" />
        <HTMLText class="help" v-if="question.question_note" :value="question.question_note" />
      </template>

      <div v-if="!isInstructionType">
        <template v-if="isGroupType && groups?.length">
          <div class="is-flex" v-for="group in groups">
            <div>{{group.name}}</div>
            <div class="label is-secondary has-margin-left" v-if="isGroupAdded(group)">(Added)</div>
          </div>
          <template v-if="removedGroups">
            <div class="is-flex" v-for="group in removedGroups">
              <div class="removed-data">{{group.name}}</div>
              <div class="label is-secondary has-margin-left">(Removed)</div>
            </div>
          </template>
        </template>
        <template v-else-if="isTradeType && trades?.length">
          <div class="is-flex" v-for="trade in trades">
            <div>{{trade.short_label}}</div>
            <div class="label is-secondary has-margin-left" v-if="isTradeAdded(trade)">(Added)</div>
          </div>
          <template v-if="removedTrades">
            <div class="is-flex" v-for="trade in removedTrades">
              <div class="removed-data">{{trade.short_label}}</div>
              <div class="label is-secondary has-margin-left">(Removed)</div>
            </div>
          </template>
        </template>
        <template v-else>
          <div v-for="answer in answers.values">
            <div v-if="isUploadType && answer.value" class="is-flex is-align-items-center">
              <a class="button is-secondary is-outlined has-margin-right" :href="formatValue(answer.value)" target="_blank">View File</a>
              <span class="help">{{filename(answer.value)}}</span>
            </div>
            <HTMLText v-else-if="isMultiLineTextType" :value="formatValue(answer.value)" />
            <div class="is-flex" v-else>
              {{formatValue(answer.value)}}
              <div v-if="isCompanyDataAdded(answer) || isCustomDataAdded(answer)" class="is-flex">
                <div class="label is-secondary has-margin-left">(Added)</div>
              </div>
            </div>
            <div v-if="isCompanyDataChanged(answer)" class="is-flex">
              <div class="label is-secondary has-margin-right">Original Data:</div>
              <div>{{isCompanyType ? company[getTransformedColumnName(question.db_company_column)] : company.primaryContact[getTransformedColumnName(question.db_contact_column)]}}</div>
            </div>
            <div v-else-if="isCompanyDataRemoved(answer)" class="is-flex">
              <div class="removed-data">{{isCompanyType ? company[getTransformedColumnName(question.db_company_column)] : company.primaryContact[getTransformedColumnName(question.db_contact_column)]}}</div>
              <div class="label is-secondary has-margin-left">(Removed)</div>
            </div>
            <template v-else-if="removedCustomValues && removedCustomValues.length">
              <div class="is-flex" v-for="customFieldValue in removedCustomValues">
                <div class="removed-data">{{customFieldValue}}</div>
                <div class="label is-secondary has-margin-left">(Removed)</div>
              </div>
            </template>
            <div v-else-if="isCustomDataChanged(answer)" class="is-flex">
              <div class="label is-secondary has-margin-right">Original Data:</div>
              <div v-for="customValue in companyCustomFieldValues">
                {{customValue}}
              </div>
            </div>
          </div>
        </template>
      </div>
    </o-field>
  </div>
</template>

<script setup>

import {computed, onMounted, ref, toRefs} from "vue";
import {DateTime} from "luxon";

import HTMLText from "@/components/HTMLText.vue";

import {
  useGroupStore,
  useTradeStore,
  utcToLocal,
} from "@/internal";
import _ from "lodash";

const groupStore = useGroupStore();
const tradeStore = useTradeStore();
const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});


const props = defineProps({
  answers: {
    required: true,
  },
  company: {
    required: true,
    default: null,
  },
  question: {
    required: true,
    type: Object,
  },
});
const {
  answers,
  company,
  question,
} = toRefs(props);

const companyCustomFieldValues = computed(() => {
  if (!isCustomType.value) {
    return [];
  }

  const questionCustomFieldKey = `custom_field_${question.value.customField.id}`;
  const customField = company.value?.custom_fields?.filter(field => {
    const keys = Object.keys(field);
    return keys[0] === questionCustomFieldKey;
  }) || null;

  return customField?.length ? (isCustomMultiValueType.value ? customField[0][questionCustomFieldKey].value : [customField[0][questionCustomFieldKey].value]) : [];
});

const isCompanyType = computed(() => {
  return isDbRetain.value && !isGroupType.value && !isTradeType.value && question.value.db_company_column;
});

const isCompanyContactType = computed(() => {
  return isDbRetain.value && !isGroupType.value && !isTradeType.value && question.value.db_contact_column;
});

const isDbRetain = computed(() => question.value.db_retain);

const isCurrencyType = computed(() => {
  return question.value.is_currency_type || question.value.customField?.is_currency_type;
});

const isCustomType = computed(() => {
  return question.value.is_custom_type;
});

const isCustomMultiValueType = computed(() => {
  return isCompanyType.value && question.value.is_checkbox_type;
});

const isDateTimeType = computed(() => {
  return question.value.is_datetime_type || question.value.customField?.is_date_time_type;
});

const isGroupType = computed(() => {
  return question.value.is_group_multi_select_type || question.value.is_group_single_select_type;
});

const isInstructionType = computed(() => {
  return question.value.is_instruction_type;
});

const isMultiLineTextType = computed(() => {
  return question.value.is_multi_line_text_type;
});

const isTimeType = computed(() => {
  return question.value.is_time_type || question.value.customField?.is_time_type;
});

const isTradeType = computed(() => {
  return question.value.is_trade_multi_select_type || question.value.is_trade_single_select_type;
});

const isUploadType = computed(() => {
  return question.value.is_upload_type;
});

const groups = computed(() => {
  return answers.value?.values?.length ? (answers.value.values.map(answer => groupStore.find(answer.value)).filter(answer => answer !== null)) : [];
});

const trades = computed(() => {
  return answers.value?.values?.length ? (answers.value.values?.map(answer => tradeStore.find(answer.value)).filter(answer => answer !== null)) : [];
});

const questionOptionValueIds = computed(() => question.value?.options.map(option => option.value_id) || []);

const removedCustomValues = computed(() => {
  return company.value && isCustomType.value ? companyCustomFieldValues.value?.filter((companyValue) => !answers.value?.values?.filter((answer) => (!isCustomMultiValueType.value && answer.value) || answer.value === companyValue).length) : null;
});

const removedGroups = computed(() => {
  return company.value && isGroupType.value ? _.difference(company.value.groups, groups.value).filter(group => questionOptionValueIds.value.includes(parseInt(group.id))) : null;
});

const removedTrades = computed(() => {
  return company.value && isTradeType.value ? company.value.trades.filter((companyTrade) => questionOptionValueIds.value.includes(parseInt(companyTrade.id)) && !trades.value.filter((trade) => trade.id === companyTrade.id).length) : null;
});

function filename(value) {
  return value.substring(value.lastIndexOf('/')+1);
}

function formatValue(value) {
  if (value && value.toLowerCase() !== '[no response]') {
    if (isCurrencyType.value) {
      return USDollar.format(value);
    } else if (isDateTimeType.value) {
      // return utcToLocal(value); //TBD - currently RFQ is not saving time in UTC or any timezone and saving as what the user input
      return DateTime.fromISO(value).toFormat('yyyy-LL-dd t');
    } else if (isTimeType.value) {
      // return utcToLocal(value); //TBD - currently RFQ is not saving time in UTC or any timezone and saving as what the user input
      return DateTime.fromISO(value).toFormat('t');
    } else if (isMultiLineTextType.value) {
      return value.replace(/(\r\n|\r|\n)/g, '<br>');
    } else if (isUploadType.value) {
      return `${import.meta.env.VITE_OPR_URL}${value}`;
    }
  }

  return value;
}

function getTransformedColumnName(dbField) {
  if (dbField === 'companyName') {
    return "name";
  }
  if (dbField === 'emailAddress') {
    return "email";
  }

  return _.snakeCase(dbField);
}

function isCompanyDataAdded(answer) {
  return (company.value && isCompanyType.value && answer.value !== company.value[getTransformedColumnName(question.value.db_company_column)] && answer.value && !company.value[getTransformedColumnName(question.value.db_company_column)])
    || (company.value && isCompanyContactType.value && answer.value !== company.value.primaryContact[getTransformedColumnName(question.value.db_contact_column)] && answer.value && !company.value.primaryContact[getTransformedColumnName(question.value.db_contact_column)]);
}

function isCompanyDataChanged(answer) {
  return (company.value && isCompanyType.value && answer.value !== company.value[getTransformedColumnName(question.value.db_company_column)] && answer.value && company.value[getTransformedColumnName(question.value.db_company_column)])
    || (company.value && isCompanyContactType.value && answer.value !== company.value.primaryContact[getTransformedColumnName(question.value.db_contact_column)] && answer.value && company.value.primaryContact[getTransformedColumnName(question.value.db_contact_column)]);
}

function isCompanyDataRemoved(answer) {
  return (company.value && isCompanyType.value && !answer.value && company.value[getTransformedColumnName(question.value.db_company_column)])
      || (company.value && isCompanyContactType.value && !answer.value && company.value.primaryContact[getTransformedColumnName(question.value.db_contact_column)]);
}

function isCustomDataAdded(answer) {
  return company.value && ((isCustomType.value && !isCustomMultiValueType.value && (!companyCustomFieldValues.value.length || !companyCustomFieldValues.value[0]) && answer.value)
      || (isCustomMultiValueType.value && !companyCustomFieldValues.value?.filter(companyValue => companyValue === answer.value).length));
}

function isCustomDataChanged(answer) {
console.log("CHANGED", isCustomType.value, companyCustomFieldValues.value, answer.value)
  return company.value && isCustomType.value && !isCustomMultiValueType.value && (!!companyCustomFieldValues.value.length && !!companyCustomFieldValues.value[0]) && answer.value && !companyCustomFieldValues.value?.filter(companyValue => companyValue === answer.value).length;
}

function isGroupAdded(group) {
  return company.value && isGroupType.value && !company.value.groups.filter((companyGroup) => questionOptionValueIds.value.includes(companyGroup.id) && companyGroup.id === group.id).length;
}

function isTradeAdded(trade) {
  return company.value && isTradeType.value && !company.value.trades.filter((companyTrade) => questionOptionValueIds.value.includes(parseInt(companyTrade.id)) && companyTrade.id === trade.id).length;
}

</script>

<style lang="scss" scoped>
.question-answer {

  .multi-select {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
  }

  .checkboxes {
    display: flex;
    margin-bottom: .5em;
  }

  .menu-class {
    max-height: 300px;
  }
}
</style>

<style lang="scss">
#question-answer {

  .field-addons {
    display: flex;
    flex-direction: column;
  }

  .help {
    margin-bottom: .5em;
    font-weight: 400;
  }

  .label-horizontal-class {
    flex-basis: 250px !important;
  }

  .label-horizontal-instruction-class {
    flex-basis: 450px !important;
    text-align: left;
  }

  label {
    .help {
      text-transform: initial;
    }
  }

  .label-class {
    line-height: normal;
  }

  .root-class {
  }
}

.label {
  &.is-secondary {
    color: $secondary !important;
  }
}

.removed-data {
  text-decoration: line-through;
}

</style>
