<template>
    <div class="question-type-column no-wrap" v-if="value">
        {{ value.name }}
    </div>
</template>

<script setup>

import {toRefs} from "vue";

const props = defineProps({
  row: {
    required: true,
  },
  value: {
    required: true,
  },
});
const {
  row,
  value,
} = toRefs(props);

</script>

<style scoped lang="scss">
    .question-type-column {
    }
</style>