import { deserialize } from "jsonapi-fractal";

import {
  BaseAPI,
} from '@/internal';

export default class DivisionAPI extends BaseAPI {

  index(params) {
    return this.get({
      url: "divisions",
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        links: res.links,
        paginator: res.meta && res.meta.pagination ? res.meta.pagination : {},
        data: deserialize(res),
      }
    })
  }

};