import { deserialize } from "jsonapi-fractal";

import {
  BaseAPI,
} from '@/internal';

export default class FormQuestionAPI extends BaseAPI {

  index(params) {
    return this.get({
      url: "form-questions",
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        links: res.links,
        paginator: res.meta && res.meta.pagination ? res.meta.pagination : {},
        data: deserialize(res),
      }
    })
  }

  show(id, params) {
    return this.get({
      url: `form-questions/${id}`,
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }

  store(data) {
    return this.post({
      url: `form-questions`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }

  update(id, data) {
    return this.put({
      url: `form-questions/${id}`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }

  archive(id) {
    return this.put({
      url: `form-questions/${id}/archive`,
      headers: [],
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }

  restore(id) {
    return this.put({
      url: `form-questions/${id}/restore`,
      headers: [],
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }

  delete(id) {
    return super.delete({
      url: `form-questions/${id}`,
      headers: [],
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }
};