<template>
  <o-loading
      :full-page="false"
      :active="isLoading"
      icon="rotate"
      icon-size="large"
  />
  <div>
    <div class="is-inline-flex is-align-items-center" v-if="uploadedFileName">
      <a class="button is-secondary is-outlined has-margin-right" :href="fileURL" target="_blank">View File</a>
      <span class="help">{{filename(uploadedFileName)}}</span>
      <o-icon icon="trash-alt" title="Remove File" class="has-margin-left" variant="secondary" @click="handleRemoveFile"/>
    </div>
    <div class="file-upload-picker" v-bind="getRootProps()" v-else>
      <div class="help" v-if="fileTypesAccepted">File Types Allowed: {{fileTypesAccepted}}</div>
      <input v-bind="getInputProps()" v-if="!disabled" />
      <div class="file-upload content has-text-centered hover-secondary" :class="{'disabled': disabled}">
        <p>
          <o-icon icon="upload" size="medium"/>
        </p>
        <p>{{ dropHereText }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
//
//  NOTE: This component currently only handles single file upload since that's all that's needed currently.
//

import { useDropzone } from "vue3-dropzone";

import {computed, ref, toRefs, watch} from "vue";
import {
  NotificationError,
  useSubFormFileStore,
} from "@/internal";

const subFormFileStore = useSubFormFileStore();
const isLoading = ref(false);
const uploadedFileName = ref('');
const filteredUpload = ref([
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
]);

const props = defineProps({
  disabled: {
    required: false,
    type: Boolean,
    default: false,
  },
  formId: {
    required: true,
  },
  fileName: {
    default: null,
    required: true,
  },
  fileTypes: {
    type: Object,
    default: [],
    required: false,
  },
  headers: {
    type: Object,
    default: {},
    required: false,
  },
  multiple: {
    default: false,
    required: false,
    type: Boolean,
  },
});
const {
  disabled,
  formId,
  fileName,
  fileTypes,
  headers,
  multiple,
} = toRefs(props);

const emit = defineEmits([
  'disableSubmit',
  'input',
]);

const { getRootProps, getInputProps, ...rest } = useDropzone({
  disabled: disabled.value,
  multiple: false,
  onDrop,
  accept: fileTypes.value?.length ? fileTypes.value : null,
});

const dropHereText = computed(() => {
  return `Drop your file${ multiple.value === true ? 's' : '' } here or click to upload`;
});

const fileTypesAccepted = computed(() => fileTypes.value && fileTypes.value.length ? fileTypes.value.join(", ") : null);

const fileURL = computed(() => `${import.meta.env.VITE_OPR_URL}${uploadedFileName.value}`);

const useHeaders = computed(() => {
  return {
    "Content-Type": "multipart/form-data",
    ...headers.value,
  }
});

function filename(value) {
  return value.substring(value.lastIndexOf('/')+1);
}

function handleRemoveFile() {
  uploadedFileName.value = "";
  emit("input", uploadedFileName.value);
}

function onDrop(acceptFiles, rejectReasons) {
  if (rejectReasons?.length && rejectReasons[0].errors?.length) {
    NotificationError({}, rejectReasons[0]);
  }
  else {
    saveFiles(acceptFiles);
  }
}

function saveFiles(files) {

  if (!files || !files.length) {
    return;
  }

  isLoading.value = true;
  emit('disableSubmit', true);

  subFormFileStore.store(formId.value, files[0])
    .then((response) => {
      uploadedFileName.value = response.data?.filename || '';
      emit("input", uploadedFileName.value);
    })
    .catch((err) => {
      NotificationError({
        message: 'Server Error',
      });
      console.error(err);
    })
    .finally(() => {
      emit('disableSubmit', false);
      isLoading.value = false;
    });
}

watch(fileName, () => {
  uploadedFileName.value = fileName.value;
});

</script>

<style lang="scss" scoped>

.file-upload-picker {
  .file-upload {
    display: block;
    padding: .25em;
    border: 1px dashed #b5b5b5;
    border-radius: 6px;
    cursor: pointer;

    &.hover-secondary {
      &:hover {
        border-color: #0067b8;
        background-color: rgba(0, 103, 184, 0.05);
      }

      &.disabled {
        cursor: no-drop;
        opacity: 50%;
      }
    }

    &.hover-primary {
      &:hover {
        border-color: #107e67;
        background-color: rgba(62, 61, 178, 0.05);
      }
    }
  }

  #file-upload-status {
    font-style: italic;
  }
}

</style>
