import {defineStore} from "pinia";
import {deserialize, serialize} from "jsonapi-fractal";
import PendingFormResponseAPI from "@/api/Subs/PendingFormResponseAPI";
import {
  utcToLocal,
} from "@/internal";

export const useSubPendingFormResponseStore = defineStore('sub-pending-form-response', {
  state: () => ({
    data: [],
    stats: {},
  }),
  actions: {
    show(payload) {
      const { params } = payload;

      return new PendingFormResponseAPI().show(params)
        .then((response) => {
          const obj = {
            ...response.data,
          };

          let index = 0;
          for (index = 0; index < this.data.length; index++) {
            if (parseInt(this.data[index].id) === parseInt(obj.id)) {
              this.data[index] = {
                ...obj,
              }
            }
          }
          if (index === this.data.length) {
            this.data[index] = obj;
          }

          return obj;
        });
    },
    store(payload) {
      return new PendingFormResponseAPI().store(payload)
        .then((response) => {
          return response;
        });
    },
  },
  persist: true,
});
