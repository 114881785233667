<template>
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        icon="rotate"
        icon-size="large"
    />

  <div class="group-form has-margin-bottom-large">
    <o-field label="Name*"
             :variant="error('name') ? 'danger' : ''"
             :message="error('name')"
             required
    >
      <o-input v-model="groupValue.name" maxlength="255"></o-input>
    </o-field>

    <o-field label="Description"
             :variant="error('description') ? 'danger' : ''"
             :message="error('description')"
    >
      <o-input v-model="groupValue.description" type="textarea"></o-input>
    </o-field>

  </div>

  <div class="has-text-right">
    <o-button class="is-primary has-margin-right" @click="handleCancel">Cancel</o-button>
    <o-button class="is-primary" @click="handleSave">{{saveButtonLabel}}</o-button>
  </div>
</template>

<script setup>

  import {
    NotificationSuccess,
    useGroupStore,
  } from "@/internal";

  import {computed, onMounted, ref, toRefs} from "vue";

  const groupStore = useGroupStore();
  const emptyGroup = {
    name: '',
    description: '',
  };
  const errors = ref([]);
  const isLoading = ref(false);

  const props = defineProps({
    groupValue: {
      required: false,
      type: Object,
      default: {
        name: '',
        description: '',
      },
    },
  });
  const {
    groupValue,
  } = toRefs(props);

  const emit = defineEmits([
      'cancel',
      'saved',
  ]);

  const groups = computed(() => groupStore.data || []);

  const isNew = computed(() => !groupValue.value.id);

  const saveButtonLabel = computed(() => isNew.value ? 'Add' : 'Save');

  function clearErrors() {
    errors.value = [];
  }

  function error(key) {
    return errors.value?.find(error => error.hasOwnProperty('key') && error.key === key)?.detail || '';
  }

  function handleCancel() {
    if (isNew.value) {
      groupValue.value = emptyGroup;
    }
    emit('cancel');
  }

  function handleSave() {

    isLoading.value = true;
    clearErrors();

    const payload = {
      id: isNew.value ? null : groupValue.value.id,
      name: groupValue.value.name,
      description: groupValue.value.description,
    };

    const action = isNew.value ? groupStore.store : groupStore.update;

    action(payload).then((response) => {
      NotificationSuccess({
        message: `Group ${isNew.value ? 'Added' : 'Saved'}`,
      });

      if (isNew.value) {
        groupValue.value = emptyGroup;
      }
      emit('saved', response.data);
    }).catch((error) => {
      if (error.hasOwnProperty('errors')) {
        errors.value = error.errors;
      }
      else {
        NotificationError({
          message: error,
        });
      }
    }).finally(() => {
      isLoading.value = false;
    });

  }

</script>

<style lang="scss" scoped>

.group-form {

  .action-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }

  .multi-select {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
  }

  .checkboxes {
    display: flex !important;
    margin-bottom: .5em;
  }

  .sort-icon {
    cursor: pointer;
  }

  .sort-icon-placeholder {
    color: transparent !important;
  }
}

.menu-class {
  max-height: 300px;
}

</style>

<style lang="scss">

.group-form {
  .addons-class {
    display: block !important;
  }

  .input-class {
    width: 500px;
  }
}

</style>
