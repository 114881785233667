import Login from "@/pages/Login.vue";
import Logout from "@/pages/Logout.vue";
import Callback from "@/pages/Callback.vue";

export default [
  {
    name: "login",
    path: "/login",
    component: Login
  },
  {
    name: "logout",
    path: "/logout",
    component: Logout
  },
  {
    name: "callback",
    path: "/callback",
    component: Callback,
  }
]
