import {
  findRouteByName,
  Menu,
  userHasPermission,
  userIsRole,
} from '@/internal';

export default (payload = {}) => {

  const {
    handleAccountClick,
    handleHelpClick,
    handleSystemPreferencesClick,
    handleLogOutClick,
    handleSuperClick,
    hideTitles,
    numUnreadNotifications,
    user,
  } = payload;

  const hideTitle = hideTitles !== undefined ? hideTitles : true;
  const title = payload.title || '';

  return new Menu({
    title,
    menuItems: [
      // {
      //   hideTitle,
      //   icon: 'rocket',
      //   onClick: handleSuperClick,
      //   title: 'Pipeline Staff',
      //   when: userIsRole('super'),
      // },
      {
        hideTitle,
        icon: 'question-circle',
        onClick: handleHelpClick,
        title: 'Help',
        iconPack: 'fal',
      },
      // {
      //   hideTitle,
      //   route: findRouteByName('notifications'),
      //   // count: user.unreadNotifications,
      //   notificationCount: true,
      //   // userId: user.id,
      // },
      // {
      //   hideTitle,
      //   icon: 'cog',
      //   onClick: handleAccountClick,
      //   title: 'Account',
      //   when: userHasPermission('client:read'),
      // },
      {
        hideTitle,
        icon: 'cog',
        onClick: handleSystemPreferencesClick,
        title: 'Settings',
        when: userHasPermission('client:update'),
        iconPack: 'fal',
      },
      {
        hideTitle,
        icon: 'lock',
        onClick: handleLogOutClick,
        title: 'Log Out',
        iconPack: 'fal',
      },
      // {
      //   hideTitle,
      //   icon: 'user-circle',
      //   imageUrl: user && user.photo ? user.photo.url : null,
      //   route: {
      //     name: 'me',
      //   },
      //   title: 'Profile',
      // },
    ]
  });
}