<template>
    <div class="email-events-details-column no-wrap" v-if="events.length">
      <div @click="handleClick" class="button is-transparent is-borderless">
        <span class="is-secondary">{{showDetails ? 'Hide' : 'Show'}}</span>
        <o-icon :icon="showDetails ? 'caret-down' : 'caret-up'" pack="fas" variant="secondary" class="has-margin-left" />
      </div>
      <div v-if="showDetails">
        <o-table :data="events">
          <o-table-column
              v-for="column in columns"
              v-bind="column"
              #default="{ row }">
            {{ row[column.field]}}
          </o-table-column>
        </o-table>
      </div>
    </div>
</template>

<script setup>

  import {computed, ref, toRefs} from "vue";

  const columns = [
    {
      field: 'name',
      label: 'Event',
    },
    {
      field: 'date',
      label: 'Date',
    },
    {
      field: 'details',
      label: 'Details',
    },
  ];
  const showDetails = ref(false);

  const props = defineProps( {
    row: {
      required: true,
    },
    value: {
      required: true,
    },
  });
  const {
    row,
    value,
  } = toRefs(props);

  const events = computed(() => row.value.events.map(event => {
    return {
      name: eventName(event),
      date: event.event_time,
      details: eventDetails(event),
    };
  }) || null);

  function eventDetails(event) {
    if (event.deferred) {
      return event.response + " " + event.attempt;
    }
    else if (event.dropped) {
      return event.reason;
    }
    else if (event.bounced) {
      return event.status + " " + event.reason + " " + event.type;
    }
    else if (event.clicked) {
      return event.url;
    }
    else {
      return event.response;
    }
  }

  function eventName(event) {
    if (event.deferred) {
      return "Deferred";
    }
    else if (event.opened) {
      return "Opened";
    }
    else if (event.spam) {
      return "Spam";
    }
    else if (event.dropped) {
      return "Dropped";
    }
    else if (event.bounced) {
      return "Bounced";
    }
    else if (event.clicked) {
      return "Clicked";
    }
    else if (event.delivered) {
      return "Delivered";
    }
    else if (event.processed) {
      return "Processed";
    }
    else if (event.unsubscribed) {
      return "Unsubscribed";
    }
    else if (event.response === "Email Relayed") {
      return "Relayed";
    }
    else {
      return '';
    }
  }

  function handleClick() {
    showDetails.value = !showDetails.value;
  }

</script>

<style scoped lang="scss">
    .email-events-details-column {
      .is-secondary {
        color: $secondary;
      }
    }
</style>