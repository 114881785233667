<template>
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        :can-cancel="true"
        icon="rotate"
        icon-size="large"
    />

    <IndexTable
        :action="formStore.index"
        :archive="formStore.archive"
        :restore="formStore.restore"
        :destroy="formStore.delete"
        addLabel="Create New Form"
        :columns="defaultTableColumns"
        :default-items-per-page="50"
        :default-status-filter="statusFilter"
        :display-status-filter="false"
        default-sort-by="updated_at"
        default-sort-order="desc"
        :includes="include"
        :items="formStore.data"
        paginated
        :pagination-position="paginationPosition"
        :paginator="paginator"
        resource="form"
        title="Forms"
        @click="handleShow"
        @response="handleResponse"
        @addClick="handleAdd"
        @editClick="handleEdit"
        @previewClick="handlePreview"
        @sendClick="handleSend"
        @viewLogClick="handleViewLog"
    />
</template>

<script setup>

  import {ref, computed, onMounted, watch, toRefs, onBeforeMount} from "vue";
  import {useRouter} from "vue-router";

  import {
    findRouteByName,
    userHasPermission,
    useAuthStore,
    useFormStore,
  } from "@/internal";

  import IndexTable from "@/components/IndexTable.vue";

  const authStore = useAuthStore();
  const formStore = useFormStore();
  const router = useRouter();
  const isLoading = ref(false);
  const include = [];
  const defaultTableColumns = [
    {
      field: 'id',
      label: 'ID',
      width: 40,
      numeric: true,
    },
    {
      field: 'form_locked',
      label: 'Locked',
      component: 'LockedColumn',
      sortable: true,
    },
    {
      field: 'name',
      label: 'Name',
      component: 'NameColumn',
      sortable: true,
      editable: true,
    },
    {
      field: 'title',
      label: 'Title',
      sortable: true,
      editable: true,
    },
    {
      field: 'updated_at',
      label: 'Last Updated',
      sortable: true,
    },
  ];
  let paginator = ref({
    perPage: 10,
  });

  const props = defineProps({
    statusFilter: {
      required: false,
      type: String,
      default: 'active',
    },
  });
  const {
    statusFilter,
  } = toRefs(props);

  const canArchive = computed(() => {
    return userHasPermission('form:archive');
  });

  const canDelete = computed(() => {
    return userHasPermission('form:delete');
  });

  const canEdit = computed(() => {
    return userHasPermission('form:update');
  });

  const canRestore = computed(() => {
    return userHasPermission('form:restore');
  });

  const formStatus = computed(() => {
    return statusFilter.value === 'archived' ? '/archived' : '/active';
  });
  const paginationPosition = computed(() => {
    return "both";
  });

  function handleResponse(response) {
    if (response.paginator) {
      paginator.value = response.paginator;
    }
    else {
      paginator.value = {
        currentPage: 1,
        total: response.data.length,
      };
    }
  }

  function handleAdd() {
    const route = findRouteByName(`forms/create`);

    router.push(route);
  }

  function handleEdit(item) {
    if (!canEdit.value || form.value.form_locked || form.value.archived) {
      return;
    }

    const route = findRouteByName(`forms${formStatus.value}/edit`);

    route.params = {
      ...route.params,
      formId: item.id,
    };

    router.push(route);
  }

  function handlePreview(item) {
    const route = findRouteByName(`subs/forms/preview`);
    route.params = {
      ...route.params,
      formId: item.id,
    };
    const routeData = router.resolve(route);

    window.open(routeData.href, '_blank');
  }

  function handleSend(item) {
    const route = findRouteByName(`forms${formStatus.value}/send`);

    route.params = {
      ...route.params,
      formId: item.id,
    };

    router.push(route);
  }

  function handleShow(item) {
    const route = findRouteByName(`forms${formStatus.value}/show`);

    route.params = {
      ...route.params,
      formId: item.id,
    };

    router.push(route);
  }

  function handleViewLog(item) {
    const route = findRouteByName(`forms${formStatus.value}/log`);

    route.params = {
      ...route.params,
      formId: item.id,
    };

    router.push(route);
  }

</script>

<style scoped>

</style>