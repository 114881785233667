<template>
  <div id="file-upload-tree">
    <o-loading
        :full-page="true"
        :active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <div class="box is-flex">
      <div class="column is-two-fifths">
        <OFileUploader
            hover-color="blue"
            :options="uploadOptions"
            @added="handleUploadAddedFiles"
            @error="handleUploadFileError"
            @progress="handleUploadFileProgress"
            @uploaded="handleUploadedFiles"
        />
      </div>
      <div class="column is-three-fifths" v-if="files.length">
        <div class="label">Attach</div>
        <div class="box recipients is-flex is-flex-direction-column">
          <FileTree :files="files"
                    :select-tools="selectable"
                    v-model:selected-files="selectedFiles"
                    :rename-file="handleFileUpdate"
                    :delete-file="handleFileDelete"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {onMounted, ref, toRefs, watch} from "vue";

import OFileUploader from '@pipelinesuite/fileuploaderjs/src/OFileUploader.vue';
import {FileTree} from 'ps-ui';

import {
  NotificationError,
  NotificationSuccess,
} from "@/internal";
import {computed} from "vue";

const isLoading = ref(false);

const props = defineProps({
  apiUrl: {
    required: true,
    type: String,
  },
  files: {
    required: true,
    type: Array,
  },
  selectedFiles: {
    required: false,
    default: [],
  },
  deleteFile: {
    required: false,
    type: Function,
    default: null,
  },
  renameFile: {
    required: false,
    type: Function,
    default: null,
  },
  selectable: {
    required: false,
    type: Boolean,
    default: false,
  },
  uploadMultiple: {
    required: false,
    type: Boolean,
    default: true,
  },
  uploadMultipartParams: {
    required: false,
    type: Object,
    default: {},
  },
});
const {
  apiUrl,
  files,
  selectedFiles,
  deleteFile,
  renameFile,
  uploadMultiple,
  uploadMultipartParams,
} = toRefs(props);

const emit = defineEmits([
  'uploaded',
  'update:selectedFiles',
]);

const uploadOptions = computed(() => {
    return {
      url: apiUrl.value,
      headers: {
        Authorization: {
          toString() {
            return  `Bearer ${window.sessionStorage.getItem('access_token')}`;
          },
        },
      },
      chunk_size: import.meta.env.VITE_APP_FILE_CHUNK_SIZE,
      filters : {
        max_file_size : import.meta.env.VITE_APP_FILE_MAX_SIZE,
      },
      multipart_params: {
        ...uploadMultipartParams.value,
      },
      multi_selection: uploadMultiple.value,
    };
});

onMounted(async() => {
});

function handleFileDelete(file) {
  deleteFile.value(file);
}

function handleFileUpdate(file, newName) {
  renameFile.value(file, newName);
}

function handleUploadAddedFiles(uploader, files) {
  isLoading.value = true;
  uploader.start();
}

function handleUploadFileProgress(uploader, file) {
}

function handleUploadedFiles(uploader, file, result) {
  isLoading.value = false;
  emit('uploaded', file, result);
}

function handleUploadFileError(uploader, error) {
  const message = error.file ? `${error.file.name}: ${error.message}` : error.message;
  NotificationError(message);
}

watch(selectedFiles, ()=> {
  emit('update:selectedFiles', selectedFiles.value);
});

</script>

<style lang="scss" scoped>

#file-upload-tree {
}

</style>

<style lang="scss">

#file-upload-tree {
  .menu-class {
    max-height: 300px;
  }

  .file-name {
    border: 0;
  }

  .b-checkbox {
    &.checkbox {
      align-items: flex-start;
    }
  }
}

</style>