<template>
  <div id="email-log-legend">
    <div class="is-flex">
      <div class="label has-margin-right">Legend</div>
      <div class="is-flex is-align-items-center has-margin-bottom" @click="toggleDetailedLegend">
        <span class="is-link is-secondary">({{ showDetailedLegend ? 'Hide' : 'Show' }} Detailed Legend</span>
        <o-icon class="has-margin-left-small" :icon="showDetailedLegend ? 'caret-down' : 'caret-up'" pack="fas" variant="secondary" />
        <span class="is-link is-secondary">)</span>
      </div>
    </div>
    <div class="legend" v-if="!showDetailedLegend">
      <div class="item">
        <o-icon icon="arrow-alt-right"/> Processed
      </div>
      <div class="item">
        <o-icon icon="clock"/> Deferred
      </div>
      <div class="item">
        <o-icon icon="times-octagon"/> Dropped
      </div>
      <div class="item">
        <o-icon icon="check"/> Delivered
      </div>
      <div class="item">
        <o-icon icon="arrow-alt-up"/> Bounced
      </div>
      <div class="item">
        <o-icon icon="envelope-open"/> Opened
      </div>
      <div class="item">
        <o-icon icon="globe-americas"/> Clicked
      </div>
    </div>

    <div class="box has-margin-top" v-else>
      <div class="legend has-margin-bottom-large">
        <div class="item has-margin-right">
          <o-icon icon="arrow-alt-right"/> Processed
        </div>
        <div>
          The email has been received by the mail server and is ready to be delivered.
        </div>
      </div>

      <div class="legend has-margin-bottom-large">
        <div class="item has-margin-right">
          <o-icon icon="clock"/> Deferred
        </div>
        <div>
          Recipient's email server temporarily rejected message. We will make further attempts to deliver the message (up to 72 hours). This is normal to occur and can be due to the receiving server being too busy, the recipients email box being full or cool down periods implemented by the receiving server, in order to avoid spam.
        </div>
      </div>

      <div class="legend has-margin-bottom-large">
        <div class="item has-margin-right">
          <o-icon icon="times-octagon"/> Dropped
        </div>
        <div>
          Emails flagged as "dropped" are messages that have been automatically marked as "do not send" (these emails never leave our email server). We recommend these emails are addressed immediately in order to permit delivery of your message. The goal should be to eliminate all Drops on future email logs. The following are the Drop reasons you will see when you click "Show" on the email log:
          <ul class="list-disc">
            <li>Invalid</li>
            <li>Spam Reporting Address</li>
            <li>Bounced Address</li>
          </ul>

          <div class="label has-margin-top-large">Managing Dropped Emails</div>
          <ul>
            <li><div class="label">Invalid</div>
              The email address is either not in the correct format (contains certain special characters and/or spaces) or the email address does not exist. This is often due to a typo on entry. Check for misspellings or accidental characters, edit and resend the email. There are a couple easy ways to edit and resend:

              <ul class="list-decimal">
                <li>View Bidders, click on the bad email address and update it right on that screen, then click on the company name and "Send Invitation".</li>
                <li>Go to the Company Record, edit their email, then View/Send Invite. Find the company in the pull-down list and click "Send to Individual".</li>
              </ul>
            </li>

            <li>
              <div class="label has-margin-top-large">Spam Reporting Address</div>
              <div class="has-margin-bottom">
                This recipient has previously marked an email as "spam" and we were informed by their email provider. These email addresses are added to our "Do Not Send" list in order to protect your sender reputation, which impacts the deliverability of all emails sent.
              </div>
              <div class="has-margin-bottom">
                If you know they want to receive your emails, simply let us know the email and we will remove it from the "Do Not Send" list (via <a href="mailto:support@pipelinesuite.com">support@pipelinesuite.com</a>, or create a support ticket in the Help menu. Paste the email and ask us to remove it from the Spam list).
              </div>
              <div class="has-margin-bottom">
                If they do not want to receive your emails, we recommend deleting their email address (or the contact) from your database.
              </div>
            </li>

            <li>
              <div class="label has-margin-top-large">Bounced Address</div>
              <div class="has-margin-bottom">
                An email previously sent to this address was bounced (the email was sent back to us by the recipient's server). This can occur because the address is invalid (domain doesn’t exist or recipient is unknown) or if the recipient’s inbox is full. These email addresses are added to our "Do Not Send" list in order to protect your sender reputation, which impacts the deliverability of all emails sent.
              </div>
              <div class="has-margin-bottom">
                If you believe the email is valid, simply let us know the email and we will remove it from the "Do Not Send" list and you can attempt to send to the email address again.
              </div>
            </li>
          </ul>

          If you would like further help with dropped emails and to help improve your email delivery, please email <a href="mailto:support@pipelinesuite.com">support@pipelinesuite.com</a> or call 949-222-0400.
        </div>
      </div>

      <div class="legend has-margin-bottom-large">
        <div class="item has-margin-right">
          <o-icon icon="check"/> Delivered
        </div>
        <div>
          The email has been received by the mail server and is ready to be delivered.
        </div>
      </div>

      <div class="legend has-margin-bottom-large">
        <div class="item has-margin-right">
          <o-icon icon="arrow-alt-up"/> Bounced
        </div>
        <div>
          Receiving server could not or would not accept the email (usually because the person is no longer with the company). Your goal should be to eliminate all Bounces on future email logs.

          <div class="label">Managing Bounced Emails</div>

          <ul class="list-decimal">
            <li>
              Sort by the Bounce column heading (click the column heading to group the Bounces together).
            </li>
            <li>
              Edit email address and resend:
              <ul class="list-disc">
                <li>
                  Click the Company Name on the email log to see the pop-up of their contact info. Call and get their correct email address.
                </li>
                <li>
                  Click "Go To" in the upper right to open their record. Edit the email and Update.
                </li>
                <li>
                  Go back to the first tab which shows the pop-up of their company info and find the "Send Invitation" link (and/or find the Notice you want to resend below that).
                </li>
              </ul>
            </li>
          </ul>

          If you find a bounced email that is valid and would like further help, please email <a href="mailto:support@pipelinesuite.com">support@pipelinesuite.com</a>.
        </div>
      </div>

      <div class="legend has-margin-bottom-large">
        <div class="item has-margin-right">
          <o-icon icon="envelope-open"/> Opened
        </div>
        <div>
          Recipient has opened the HTML email.
        </div>
      </div>

      <div class="legend has-margin-bottom-large">
        <div class="item has-margin-right">
          <o-icon icon="globe-americas"/> Clicked
        </div>
        <div>
          Recipient clicked on a link within the email.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

  import {computed, ref} from "vue";

  const showDetailedLegend = ref(false);

 function toggleDetailedLegend() {
    showDetailedLegend.value = !showDetailedLegend.value;
  }

</script>

<style lang="scss" scoped>

#email-log-legend {
  .is-secondary {
    color: $secondary;
  }

  .legend {
    display: flex;

    .item {
      align-items: center;
      border: 1px solid $secondary;
      border-radius: 3px;
      display: flex;
      //flex: 1;
      flex-direction: column;
      font-size: .8em;
      justify-content: center;
      margin: 2.5px;
      padding: 10px 2.5px 5px;
      min-width: 69.75px;
      max-height: 65.2px;
      color: $secondary;

      &.has-margin-right {
        margin-right: 1em !important;
      }

      .icon {
        margin-bottom: 5px;
      }
    }

    ul {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;

      &.list-decimal {
        list-style-type: decimal;
      }

      &.list-disc {
        list-style-type: disc;
      }
    }
  }
}
</style>