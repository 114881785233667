<template>
  <o-loading
      :full-page="true"
      v-model:active="isLoading"
      icon="rotate"
      icon-size="large"
  />

  <o-steps animated
           rounded
           variant="secondary"
           step-content-class="step-content-class"
           step-navigation-class="step-navigation-class"
           icon-pack="fas"
           :has-navigation="true"
           v-model="activeStep"
           >

    <template #navigation="{ previous, next }">
      <div class="navigation">
        <o-button
            outlined
            icon-pack="fas"
            icon-left="angle-left"
            :disabled="previous.disabled"
            @click.prevent="previous.action"
        >
          Previous
        </o-button>
        <o-button
            outlined
            icon-pack="fas"
            icon-right="angle-right"
            :disabled="next.disabled"
            @click.prevent="next.action"
        >
          Next
        </o-button>
      </div>
    </template>

    <div class="columns">
      <div class="column has-text-right">
        <o-button class="is-primary has-margin-right-large" variant="secondary" @click="handleSave">Save</o-button>
        <o-button class="is-primary" @click="handleSend" v-if="isFormComplete">Save and Send</o-button>
      </div>
    </div>

    <o-step-item step="1"
                 value="1"
                 label="Questions"
                 icon="messages-question"
                 icon-pack="fas"
                 :clickable="isStep1Clickable"
    >
      <Questions v-model:questions="formQuestions"
                 @update="handleQuestionsUpdate"
                 @create="handleCreateQuestion"
                 ref="questionsRef"
                 v-if="formQuestions !== null"
      />
    </o-step-item>

    <o-step-item step="2"
                 value="2"
                 label="Details"
                 icon="circle-info"
                 icon-pack="fas"
                 :clickable="isStep2Clickable"
                 @activate="detailsViewed = true"
    >
      <Details v-model:form="form" :questions="formQuestions" />
    </o-step-item>

    <o-step-item step="3"
                 value="3"
                 label="Optional Workflow"
                 icon="diagram-sankey"
                 icon-pack="fas"
                 :clickable="isStep3Clickable"
    >
      <Workflow v-model:workflow-steps="formWorkflowSteps" @update="handleWorkflowStepsSort" v-if="formWorkflowSteps" />
    </o-step-item>
  </o-steps>

  <o-modal v-model:active="isCreateQuestionActive" :width="600">
    <div class="title">Create New Question</div>
    <Form @cancel="handleCancelCreate" @saved="handleQuestionCreated" />
  </o-modal>
</template>

<script setup>

  import mitt from 'mitt';

  import Questions from "./Questions.vue";
  import Details from "./Details.vue";
  import Workflow from "./Workflow.vue";

  import {
    findRouteByName,
    NotificationSuccess,
    useFormStore,
  } from "@/internal";

  import {computed, onBeforeUnmount, onMounted, provide, ref, toRefs, watch} from "vue";
  import {useRoute, useRouter} from "vue-router";
  import Form from "@/pages/Questions/Form.vue";
  import stripHTML from "@/use/stripHTML.js";

  const formStore = useFormStore();
  const route = useRoute();
  const router = useRouter();
  const activeStep = ref("1");
  const detailsViewed = ref(false);
  const emitter = mitt();
  const formQuestions = ref(null);
  const formWorkflowSteps = ref(null);
  const isCreateQuestionActive = ref(false);
  const isLoading = ref(false);
  const isStep1Clickable = ref(true);
  const isStep2Clickable = ref(true);
  const isStep3Clickable = ref(true);
  const questionsRef = ref(null);

  const props = defineProps({
    form: {
      required: true,
      type: Object,
    },
  });
  const {
    form,
  } = toRefs(props);

  const isFormComplete = computed(() => {
    return formQuestions.value?.length
        && form.value?.name && form.value.title && form.value.subjectQuestion
        && (!form.value.send_email_alert || (form.value.send_email_alert && form.value.send_email_to_user_id && form.value.send_email_subject))
        && !formQuestions.value.filter((question) => {
          const optionsType = question.is_group_multi_select_type || question.is_group_single_select_type || question.is_trade_multi_select_type || question.is_trade_single_select_type || question.is_checkbox_type || question.is_radio_type || question.is_dropdown_type;
          return optionsType && (!question.options || !question.options.length);
        }).length
        && !formWorkflowSteps.value.filter((step) => {
          return step.email_when_ready && (!step.emailRecipient || !step.email_subject);
        }).length
        && (!isNew.value || (isNew.value && detailsViewed.value));
  });

  const isNew = computed(() => !form.value.id);

  emitter.on('addWorkflowStep', handleWorkflowStepAdd);
  emitter.on('deleteWorkflowStep', handleWorkflowStepDelete);
  emitter.on('updateWorkflowStep', handleWorkflowStepUpdate);

  provide('emitter', emitter);

  onMounted(() => {
    formQuestions.value = form.value.questions ? form.value.questions.map(question => {
        return {
          ...question,
          join_id: null,
        };
      }) : [];
    formWorkflowSteps.value = form.value.workflow ? form.value.workflow.workflowSteps : [];
  })

  function handleCancelCreate() {
    isCreateQuestionActive.value = false;
  }

  function handleCreateQuestion() {
    isCreateQuestionActive.value = true;
  }

  function handleQuestionCreated(question) {
    isCreateQuestionActive.value = false;

    formQuestions.value.push({
      ...question,
      nonHTMLQuestion: stripHTML(question.question),
      is_required: false,
      join_id: null,
      question_id: question.id,
      id: null,
    });

    questionsRef.value.loadAvailableQuestions();
  }

  function handleQuestionsUpdate(questions) {
    formQuestions.value = questions;
  }

  function handleWorkflowStepsSort(workflowSteps) {
    formWorkflowSteps.value = workflowSteps;
  }

  function handleWorkflowStepAdd(step) {
    formWorkflowSteps.value.push(step);
  }

  function handleWorkflowStepDelete(step) {
    formWorkflowSteps.value = formWorkflowSteps.value.filter(workflowStep => workflowStep.id !== step.id);
  }

  function handleWorkflowStepUpdate(workflowStep) {
    formWorkflowSteps.value = formWorkflowSteps.value.map(step => step.id === workflowStep.id ? workflowStep : step);
  }

  function handleSaveAndSend(send=false) {

    isLoading.value = true;

    const payload = {
      ...form.value,
      id: isNew.value ? null : form.value.id,
      approved_group_id: form.value.approvedGroup ? form.value.approvedGroup.id : null,
      unapproved_group_id: form.value.unapprovedGroup ? form.value.unapprovedGroup.id : null,
      business_unit_id: form.value.businessUnit ? form.value.businessUnit.id : null,
      subject_join_id: form.value.subjectQuestion ? form.value.subjectQuestion.id : null,
      subject_question_id: form.value.subjectQuestion?.question_id !== null ? form.value.subjectQuestion?.question_id : null,
      send_email_to_user_id: form.value.sendEmailTo ? form.value.sendEmailTo.id : null,
      questions: formQuestions.value.map(question => {
        return {
          ...question,
          id: isNaN(parseInt(question.id)) ? null: question.id,
        };
      }),
      workflow: {
        ...form.value.workflow,
        workflowSteps: formWorkflowSteps.value.map(step => {
            return {
              ...step,
              id: step.id.toString().startsWith('new_') ? null : step.id,
              email_recipient_id: step.emailRecipient ? step.emailRecipient.id : null,
            };
          }),
      },
    };

    const action = isNew.value ? formStore.store : formStore.update;

    action(payload).then((response) => {
      NotificationSuccess({
        message: `Form ${isNew.value ? 'Added' : 'Saved'}`,
      });

      if (send) {
        handleGoToSend(response.data.id);
      }
      else {
        handleView(response.data.id);
      }
    }).catch((error) => {

    }).finally(() => {
      isLoading.value = false;
    });
  }

  function handleSend() {
    handleSaveAndSend(true);
  }

  function handleSave() {
    handleSaveAndSend();
  }

  function handleGoToSend(formID) {
    const route = findRouteByName(`forms/active/send`);

    route.params = {
      ...route.params,
      formId: formID,
    };

    router.push(route);
  }

  function handleView(formID) {
    const route = findRouteByName(`forms/active/show`);

    route.params = {
      ...route.params,
      formId: formID,
    };

    router.push(route);
  }

</script>

<style lang="scss" scoped>

.b-steps {
  margin-bottom: 3em;
}

.menu-class {
  max-height: 300px;
}

.navigation {
  margin-top: 1em;
  text-align: right;

  background: $primary-light;
  border-top: 1px solid $primary;
  padding: 15px 45px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100;

  .button:not(last-child) {
    margin-right: .5em;
  }

  .button {
    color: $secondary;

    &:hover:enabled {
      border-color: $secondary;
      color: $secondary;
    }

    &:hover:disabled {
      color: $secondary;
    }
  }

  .icon {
    color: $secondary;
  }
}

</style>

<style lang="scss">

.step-content-class {
  margin-top: 2em;
}

</style>
