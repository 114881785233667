<template>
  <Page :title="pageTitle" id="form-send">
    <o-loading
        :full-page="true"
        :active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <template v-slot:controls>
      <div class="buttons is-flex-wrap-nowrap">
        <router-link class="button is-primary" :to="`/forms/${status}/${formId}/log`" v-if="form?.emails_exist">
          <o-icon icon="list-check" class="has-margin-right" />
          View Log
        </router-link>
      </div>
    </template>

    <div class="label">Recipient(s)</div>
    <div class="box is-flex">
      <div class="column is-one-fifth">
        <o-field label="Send To">
          <o-dropdown v-model="sendTo"
                      aria-role="list"
                      menu-class="menu-class"
                      scrollable
                      close-on-click
          >
            <template #trigger="{ active }">
              <div class="button is-secondary is-outlined">
                <span>{{ (sendTo && sendTo.label) || '--- Select ---'}}</span>
                <o-icon :icon="active ? 'caret-up' : 'caret-down'" pack="fas"></o-icon>
              </div>
            </template>

            <o-dropdown-item :value="option" v-for="option in sendToOptions">{{ option.label }}</o-dropdown-item>
          </o-dropdown>
        </o-field>

        <div v-if="sendTo && sendTo.options">
          <div class="has-margin-bottom" v-for="option in sendTo.options">
            <o-radio v-model="sendToOption" :native-value="option.key">{{option.label}}</o-radio>
          </div>
        </div>
      </div>

      <div class="column is-two-fifths">
        <div v-if="sendTo">
          <div v-if="sendTo.key === 'company'">
            <o-field label="Select a Company">
              <o-autocomplete
                  v-model="searchCompanyName"
                  open-on-focus
                  rounded
                  expanded
                  icon="search"
                  clearable
                  clear-on-select
                  max-height="500"
                  keep-open
                  :data="companies"
                  field="label"
                  @select="handleCompanyRecipient"
              >
                <template #empty>No results found</template>
              </o-autocomplete>
            </o-field>
          </div>

          <div v-else-if="sendTo.key === 'divisions'">
            <div v-if="sendToOption">
              <div v-if="sendToOption === 'select'">
                <div class="label">
                  <o-checkbox v-model="selectAll" @click="handleSelectAll">Select All</o-checkbox>
                </div>
                <div class="box recipients is-flex is-flex-direction-column">
                  <div class="has-margin-bottom" v-for="division in divisions">
                    <o-checkbox v-model="recipients" :native-value="division.id">{{division.full_label}}</o-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="sendTo.key === 'trades'">
            <div v-if="sendToOption">
              <div v-if="sendToOption === 'select'">
                <div class="label">
                  <o-checkbox v-model="selectAll" @click="handleSelectAll">Select All</o-checkbox>
                </div>
                <div class="box recipients is-flex is-flex-direction-column">
                  <div class="has-margin-bottom" v-for="trade in trades">
                    <o-checkbox v-model="recipients" :native-value="trade.id">{{trade.short_label}}</o-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="sendTo.key === 'groups'">
            <div v-if="sendToOption">
              <div v-if="sendToOption === 'select'">
                <div class="label">
                  <o-checkbox v-model="selectAll" @click="handleSelectAll">Select All</o-checkbox>
                </div>
                <div class="box recipients is-flex is-flex-direction-column">
                  <div class="has-margin-bottom" v-for="group in groups">
                    <o-checkbox v-model="recipients" :native-value="group.id">{{group.name}}</o-checkbox>
                  </div>
                </div>
              </div>
              <div v-else-if="sendToOption === 'except'">
                <div class="label">
                  <o-checkbox v-model="selectAll" @click="handleSelectAll">Select All</o-checkbox>
                </div>
                All Groups except:
                <div class="box recipients is-flex is-flex-direction-column">
                  <div class="has-margin-bottom" v-for="group in groups">
                    <o-checkbox v-model="recipients" :native-value="group.id">{{group.name}}</o-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="column is-two-fifths">
        <div class="label">Recipient(s)</div>
        <div v-if="sendTo && sendTo.key === 'groups' && sendToOption && sendToOption === 'except'">
          All Groups except:
        </div>

        <div class="box is-flex recipients is-flex-direction-column" v-if="(sendTo && ['me', 'company', 'companies'].includes(sendTo.key)) || (sendToOption && sendToOption === 'all') || recipients.length">
          <div v-if="sendTo.key === 'me'">
            Myself
          </div>

          <div v-else-if="sendTo.key === 'company'">
            <div v-for="company in recipients" class="is-flex">
              <div class="has-margin-right">{{ `${company.name ? company.name.trim() : ''}` + (company.primaryContact ? `: ${company.primaryContact.first_name} ${company.primaryContact.last_name}` : '') }}</div>
              <o-icon icon="trash-alt" variant="primary" alt="Remove" clickable @click="handleRemoveCompanyRecipient(company.id)" />
            </div>
          </div>

          <div v-else-if="sendTo.key === 'companies'">
            <div>All Companies</div>
          </div>

          <div v-else-if="sendTo.key === 'divisions'">
            <div v-if="sendToOption">
              <div v-if="sendToOption === 'select'">
                <div v-for="division in selectedDivisions">{{division.full_label}}</div>
              </div>
              <div v-else-if="sendToOption === 'all'">
                All Divisions
              </div>
            </div>
          </div>

          <div v-else-if="sendTo.key === 'trades'">
            <div v-if="sendToOption">
              <div v-if="sendToOption === 'select'">
                <div v-for="trade in selectedTrades">{{trade.short_label}}</div>
              </div>
              <div v-else-if="sendToOption === 'all'">
                All Trades
              </div>
            </div>
          </div>

          <div v-else-if="sendTo.key === 'groups'">
            <div v-if="sendToOption">
              <div v-if="sendToOption === 'select'">
                <div v-for="group in selectedGroups">{{group.name}}</div>
              </div>
              <div v-else-if="sendToOption === 'all'">
                All Groups
              </div>
              <div v-else-if="sendToOption === 'except'">
                <div v-for="group in selectedGroups">{{group.name}}</div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="label">Email</div>
    <div class="box">
      <SendEmail :message="form?.email_message" :button="form?.email_button" :form-title="form?.title" @update="handleSendEmailUpdate" />
    </div>

    <div class="label has-margin-top-large">Attachments</div>
    <FileUploadTree :files="formFiles"
                    :api-url="formFileApiUrl"
                    :upload-multipart-params="uploadMultipartParams"
                    :selectable="true"
                    v-model:selected-files="selectedFiles"
                    @uploaded="loadFormFiles"
                    :rename-file="handleFileUpdate"
                    :delete-file="handleFileDelete"
    />

    <div class="label">Fax Options</div>
    <div class="box is-flex">
      <div class="column is-three-quarters">
        <o-field label="Display Trades as text area instead of listing all trade options">
          <o-switch v-model="displayTradesAsTextArea" />
        </o-field>
      </div>
      <div class="column is-one-quarter">
        <o-button class="is-outlined" variant="primary" @click="handlePreviewFax">Preview Fax</o-button>
      </div>
    </div>

    <div class="is-flex is-justify-content-flex-end">
      <o-button variant="primary" :disabled="disableSend" @click="handleSend">Send</o-button>
    </div>

    <div class="has-margin-top-large">&nbsp;</div>
    <div class="box has-margin-top-large">
      <div class="label">Form URL</div>
      <div>
        Here's a URL to the Form for companies not in your database that you may also use.
      </div>
      <a :href="form?.form_url" target="_blank" class="form-url">{{form?.form_url}}</a>
    </div>
  </Page>
</template>

<script setup>

  import {useRoute, useRouter} from "vue-router";
  import {onMounted, ref, watch} from "vue";

  import Page from "@/components/Page.vue";
  import SendEmail from "@/components/SendEmail.vue";
  import FileUploadTree from "@/components/FileUploadTree.vue";

  import {
    findRouteByName,
    NotificationError,
    NotificationSuccess,
    useAuthStore,
    useCompanyStore,
    useDivisionStore,
    useFormStore,
    useGroupStore,
    useNonceStore,
    useTradeStore,
  } from "@/internal";
  import {computed} from "vue";

  const authStore = useAuthStore();
  const companyStore = useCompanyStore();
  const divisionStore = useDivisionStore();
  const formStore = useFormStore();
  const groupStore = useGroupStore();
  const nonceStore = useNonceStore();
  const tradeStore = useTradeStore();
  const route = useRoute();
  const router = useRouter();
  const buttonText = ref('');
  const displayTradesAsTextArea = ref(true);
  const emailMessage = ref('');
  const files = ref([]);
  const isLoading = ref(false);
  const recipients = ref([]);
  const searchCompanyName = ref('');
  const selectAll = ref(false);
  const selectedFiles = ref([]);
  const sendTo = ref(null);
  const sendToOption = ref(null);
  const sendToOptions = ref([
    {
      key: 'me',
      label: 'Myself',
      options: null,
    },
    {
      key: 'company',
      label: 'Individuals',
      options: null,
    },
    {
      key: 'companies',
      label: 'All Companies',
      options: null,
    },
    {
      key: 'divisions',
      label: 'Divisions',
      options: [
        {
          key: 'select',
          label: 'Selected Divisions',
        },
        {
          key: 'all',
          label: 'All Divisions',
        },
      ],
    },
    {
      key: 'trades',
      label: 'Trades',
      options: [
        {
          key: 'select',
          label: 'Selected Trades',
        },
        {
          key: 'all',
          label: 'All Trades',
        },
      ],
    },
    {
      key: 'groups',
      label: 'Groups',
      options: [
        {
          key: 'select',
          label: 'Selected Groups',
        },
        {
          key: 'except',
          label: 'All Groups Except Selected',
        },
      ],
    },
  ]);
  const user = authStore.me;

  const form = computed(() => {
    return formStore.find(formId.value);
  });

  const formId = computed(() => {
    return route.params.formId || null;
  });

  const pageTitle = computed(() => {
    return "Send: " + (form.value ? form.value.name : '');
  });

  const companies = computed(() => {
    return companyStore.data.map((company) => {
      return {
        ...company,
        label: `${company.name ? company.name.trim() : ''}` + (company.primaryContact ? `: ${company.primaryContact.first_name} ${company.primaryContact.last_name}` : ''),
      };
    }) || [];
  });

  const disableSend = computed(() => {
    return !((sendTo.value && sendTo.value.key === 'me') || (sendTo.value?.key === 'companies') || (sendToOption.value && sendToOption.value === 'all') || recipients.value.length);
  });

  const divisions = computed(() => divisionStore.data.sort((a, b) => a.full_label > b.full_label ? 1 : -1) || []);

  const formFiles = computed(() => formStore.find(formId.value)?.files || []);

  const formFileApiUrl = computed(() => `${import.meta.env.VITE_APP_API_URL}/forms/${formId.value}/files`);

  const groups = computed(() => groupStore.data || []);

  const nonce = computed(() => nonceStore.data || null);

  const status = computed(() => {
    return form.value ? (form.value.archived ? 'archived' : 'active') : '';
  });

  const uploadMultipartParams = computed(() => {
    return {
      form_id: formId.value,
    };
  });

  const selectedDivisions = computed(() => {
    return divisions.value.filter((division) => recipients.value.includes(division.id));
  });

  const selectedGroups = computed(() => {
    return groups.value.filter((group) => recipients.value.includes(group.id));
  });

  const selectedTrades = computed(() => {
    return trades.value.filter((trade) => recipients.value.includes(trade.id));
  });

  const trades = computed(() => tradeStore.data || []);

  onMounted(async() => {
    await loadForm();
    loadFormFiles();
    loadCompanies();
    loadDivisions();
    loadGroups();
    loadTrades();

    if (form.value.email_message) {
      emailMessage.value = form.value.email_message;
    }

    if (form.value.email_button) {
      buttonText.value = form.value.email_button;
    }
  });

  function handleCompanyRecipient(company) {
    if (company) {
      if (recipients.value.filter(recipient => parseInt(recipient.id) === parseInt(company.id)).length === 0)
      recipients.value.push(company);
      searchCompanyName.value = '';
    }
  }

  function handleRemoveCompanyRecipient(companyID) {
      recipients.value = recipients.value.filter(company => parseInt(company.id) !== parseInt(companyID));
  }

  function handlePreviewFax() {

    isLoading.value = true;

    const payload = {
      params: {
        omit_trades: displayTradesAsTextArea.value,
        path: 'prequal/pdf',
      },
    };

    formStore.previewFax(formId.value, form.value.title, payload).then((response) => {
    }).catch((error) => {

    }).finally(() => {
      isLoading.value = false;
    });
  }

  function handleSelectAll() {
    selectAll.value = !selectAll.value;

    if (sendTo.value && sendToOption.value && ['select', 'except'].includes(sendToOption.value)) {
      if (sendTo.value.key === 'groups') {
        recipients.value = selectAll.value ? groups.value.map(group => group.id) : [];
      } else if (sendTo.value.key === 'trades') {
        recipients.value = selectAll.value ? trades.value.map(trade => trade.id) : [];
      } else if (sendTo.value.key === 'divisions') {
        recipients.value = selectAll.value ? divisions.value.map(division => division.id) : [];
      }
    }
  }

  function handleFileDelete(file) {
    selectedFiles.value = selectedFiles.value.filter((selectedFile) => parseInt(selectedFile.id) !== parseInt(file.id));
    return formStore.deleteFile(form.value.id, file.id)
      .then(() => {
        loadFormFiles();
      });
  }

  function handleFileUpdate(file, newName) {
    const payload = {
      name: newName,
    };

    return formStore.updateFile(form.value.id, file.id, payload)
      .then(() => {
        loadFormFiles();
      });
  }

  async function handleSend() {

    if (disableSend.value) {
      return;
    }

    await loadNonce();

    isLoading.value = true;

    const payload = {
      nonce: nonce.value,
      email_message: emailMessage.value,
      email_button: buttonText.value,
      omit_trades: displayTradesAsTextArea.value,
      file_id: selectedFiles.value.map(file => file.id) || [],
    };

    if (sendTo.value.key === 'me') {
      payload.user_id = user.id;
    }
    else if (sendTo.value.key === 'company') {
      payload.company_id = recipients.value.map((company) => company.id);
    }
    else if (sendTo.value.key === 'companies') {
      payload.company_id = '*';
    }
    else if (sendTo.value.key === 'divisions') {
      payload.division_id = sendToOption.value === 'all' ? divisions.value.map((division) => division.id) : recipients.value;
    }
    else if (sendTo.value.key === 'trades') {
      payload.trade_id = sendToOption.value === 'all' ? trades.value.map((trade) => trade.id) : recipients.value;
    }
    else if (sendTo.value.key === 'groups') {
      payload.group_id = sendToOption.value === 'all' ? groups.value.map((group) => group.id) : recipients.value;

      if (sendToOption.value === 'except') {
        payload.except = true;
      }
    }

    formStore.send(form.value.id, payload).then((response) => {
      NotificationSuccess({
        message: `Form Sent`,
      });

      handleViewForm();
    }).catch((error) => {
      if (error.errors) {
        NotificationError({}, error);
      }
      else {
        NotificationError({
          message: error,
        });
      }
    }).finally(() => {
      isLoading.value = false;
    });
  }

  function handleSendEmailUpdate(message, button) {
    emailMessage.value = message;
    buttonText.value = button;
  }

  function handleViewForm() {
    const route = findRouteByName(`forms/active/show`);

    route.params = {
      ...route.params,
      formId: form.value.id,
    };

    router.push(route);
  }

  function loadCompanies() {
    if (searchCompanyName.value.length < 3) {
      return;
    }

    const payload = {
      params: {
        q: searchCompanyName.value,
        include: [
          'primaryContact',
        ],
      },
    };

    companyStore.index(payload)
        .then(() => {
        }).finally(() => {
    });
  }

  function loadDivisions() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    divisionStore.index(payload)
        .then(() => {
        }).finally(() => {
    });
  }

  function loadForm() {
    isLoading.value = true;

    const payload = {
      params: {
        include: [
          'approvedGroup',
          'businessUnit',
          'questions.customField',
          'questions.options',
          'sendEmailTo',
          'subjectQuestion',
          'unapprovedGroup',
          'workflow.workflowSteps.emailRecipient',
        ],
      },
    };

    return formStore.show(formId.value, payload).finally(() => {
      isLoading.value = false;
    });
  }

  function loadFormFiles() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    return formStore.files(formId.value, payload)
      .then(() => {
      }).finally(() => {
      });
  }

  function loadGroups() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    groupStore.index(payload)
        .then(() => {
        }).finally(() => {
    });
  }

  function loadNonce() {
    isLoading.value = true;

    const payload = {
      params: {
        action: 'login',
        include: [
        ],
      },
    };

    return nonceStore.getNonce(payload)
      .then((response) => {
        return response;
      }).finally(() => {
        isLoading.value = false;
      });
  }

  function loadTrades() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    tradeStore.index(payload)
        .then(() => {
        }).finally(() => {
    });
  }

  watch(formFiles, () => {
    if (!files.value.length) {
      selectedFiles.value = [...formFiles.value.filter(file => file.attached)];
    }
    else {
      const newFiles = formFiles.value.filter(file => {
        return !files.value.find((currentFile) => parseInt(currentFile.id) === parseInt(file.id));
      });

      newFiles.forEach(file => {
        selectedFiles.value.push({...file});
      });
    }

    files.value = [...formFiles.value];
  }, {deep: true});

  watch(searchCompanyName, () => {
    loadCompanies();
  });

  watch(sendTo, () => {
    sendToOption.value = null;
    searchCompanyName.value = '';
    recipients.value = [];
  });

  watch(sendToOption, () => {
    selectAll.value = false;
    searchCompanyName.value = '';
    recipients.value = [];
  });

</script>

<style lang="scss" scoped>

#form-send {
  .box {
    &.preview {
      border-color: $secondary;
    }

    &.recipients {
      max-height: 400px;
      overflow-y: auto;
    }
  }

  .form-url {
    color: $secondary;
  }
}

</style>

<style lang="scss">

#form-send {
  .menu-class {
    max-height: 300px;
  }

  .file-name {
    border: 0;
  }

  .b-checkbox {
    &.checkbox {
      align-items: flex-start;
    }
  }
}

</style>