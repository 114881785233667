<template>
  <Page>Loading...</Page>
</template>

<script setup>

  import { onBeforeMount } from "vue";
  import { useRouter } from "vue-router";

  import Page from "@/components/Page.vue";
  import Auth from "@/auth";
  import { useAuthStore } from "@/internal";

  const authStore = useAuthStore();

  onBeforeMount(() => {
    if (Auth.isAuthenticated()) {
      const router = useRouter();
      router.push({
        name: 'overview'
      });
    } else {
      Auth.authenticate();
    }
  });

</script>
