<template>
    <div v-html="sanitized">
    </div>
</template>

<script setup>

  import DOMPurify from 'dompurify';
  import {computed, toRefs} from "vue";

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    }
  });

  const props = defineProps({
    value: {
      required: true,
    },
  });
  const {
    value,
  } = toRefs(props);

  const sanitized = computed(() => {
    return DOMPurify.sanitize(value.value);
  });

</script>

<style scoped lang="scss">
</style>