import { deserialize } from "jsonapi-fractal";

import {
  BaseAPI,
} from '@/internal';

export default class GroupAPI extends BaseAPI {

  index(params) {
    const { prependURL } = params;
    delete params.prependURL;

    const prepend = prependURL || '';

    return this.get({
      url: `${prepend}groups`,
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        links: res.links,
        paginator: res.meta && res.meta.pagination ? res.meta.pagination : {},
        data: deserialize(res),
      }
    })
  }

  store(data) {
    return this.post({
      url: `groups`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }

};