<template>
  <Page :title="pageTitle">
    <o-notification
        variant="warning"
        role="alert"
    >
      <div class="has-margin-bottom-small">WARNING: This question may be utilized on existing forms.</div>
      <div>Any modifications to this question will be reflected on the existing forms as well as any responses to those forms.
      Any changes to options will not change the answers provided in prior submissions, but will show a different option set to future respondents.</div>
    </o-notification>

    <Form v-model:question-value="question" @cancel="handleCancel" @saved="handleSaved" />
  </Page>
</template>

<script setup>

import {useRoute, useRouter} from "vue-router";
import {computed} from "vue";

import Page from "@/components/Page.vue";
import Form from "./Form.vue";

import {
  findRouteByName,
  useFormQuestionStore,
} from "@/internal";

const formQuestionStore = useFormQuestionStore();
const route = useRoute();
const router = useRouter();

const question = computed(() => {
  return formQuestionStore.find(questionId.value);
});

const questionId = computed(() => {
  return route.params.questionId || null;
});

const pageTitle = computed(() => {
  return "Edit: " + (question.value?.question ? question.value.question : '');
});

function handleCancel() {
  const route = findRouteByName(`questions/active/show`);

  route.params = {
    ...route.params,
    questionId: questionId.value,
  };

  router.push(route);
}

function handleSaved() {
  handleCancel();
}

</script>

<style scoped>

</style>