import axios from "axios";
import Cookies from 'js-cookie';

export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  responseType: 'json',
  withCredentials: true,
  withXSRFToken: true,
});

axiosInstance.interceptors.request.use(config => {
  if (['post', 'put', 'patch', 'delete'].includes(config.method) && !Cookies.get('XSRF-TOKEN')) {
    return axiosInstance({
      method: 'GET',
      url: 'sanctum/csrf-cookie',
    })
    .then(response => config);
  }
  return config;
});

axiosInstance.interceptors.response.use(null, error => {

  if (!error.response) return Promise.reject(error);

  switch (error.response.status) {
    case 500: {
      console.error(error.response);
      return Promise.reject('Server Error');
    }
    case 401: {
      break;
    }
    case 403: {
      break;
    }
    case 419: {
      console.error(error.response);
      return Promise.reject('Token Error');
    }
    default: {
      break;
    }
  }

  return Promise.reject(error.response.data);
});

export default axiosInstance;
