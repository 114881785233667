import Form from "@/pages/Subs/Form.vue";

export default [
  {
    name: 'subs/create',
    path: '/sub/form/:formId/cl/:clientId/c/:companyId/p/:privateKey',
    component: Form,
    meta: {
      requiresAuth: false,
      breadcrumbs: [
      ],
      icon: 'eye',
      useModelForTitle: 'form',
    },
  },
  {
    name: 'subs/edit',
    path: '/sub/form/:formId/cl/:clientId/c/:companyId/p/:privateKey/r/:resumeKey',
    component: Form,
    meta: {
      requiresAuth: false,
      breadcrumbs: [
      ],
      icon: 'eye',
      useModelForTitle: 'form',
    },
  },
  {
    name: 'subs/unknown/create',
    path: '/sub/form/:formId/cl/:clientId/p/:privateKey',
    component: Form,
    meta: {
      requiresAuth: false,
      breadcrumbs: [
      ],
      icon: 'eye',
      useModelForTitle: 'form',
    },
  },
  {
    name: 'subs/unknown/edit',
    path: '/sub/form/:formId/cl/:clientId/p/:privateKey/r/:resumeKey',
    component: Form,
    meta: {
      requiresAuth: false,
      breadcrumbs: [
      ],
      icon: 'eye',
      useModelForTitle: 'form',
    },
  },
]
