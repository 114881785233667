import {defineStore} from "pinia";
import CompanyAPI from "@/api/CompanyAPI";
import {
  utcToLocal,
} from "@/internal";

export const useCompanyStore = defineStore('company', {
  state: () => ({
    data: [],
  }),
  actions: {
    clear() {
      this.data = [];
    },
    find(id) {
      return this.data.find(data => parseInt(data.id) === parseInt(id)) || null;
    },
    index(payload) {
      const { params } = payload;

      return new CompanyAPI().index(params)
      .then((response) => {
        this.data = response.data;
        return response;
      });
    },
  },
  persist: true,
});
