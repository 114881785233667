<template>
    <nav id="navigation-contextual">

        <Toggle @click="handleToggleNavigation" :active="!navigationOpen"/>

        <div id="navigation-contextual-content"
             :class="{ 'is-open': navigationOpen }"
        >
            <div id="primary" v-if="!activeRoute.secondaryMenuActive">
                <div class="top"  v-if="navigationOpen">
                    <router-link to="/" class="ps-icon">
                      <img src="https://assets.p-suite.io/prequal/preconPrequal-logo.svg" alt="PreconPrequal">
                    </router-link>

                    <NavMenu darks :menu="menu"/>
                </div>
                <div class="bottom" v-if="navigationOpen">
                    <div class="button is-light is-block" @click="handleUpdateAvailable" v-if="updateAvailable" style="margin-bottom: 15px;">
                        Update Available
                    </div>
                    <NavMenu darks :menu="bottomMenu"/>
                    <div id="version">v{{version}}</div>
                </div>
            </div>

            <div id="secondary" v-if="activeRoute.secondaryMenuActive">
                <div v-if="navigationOpen">
                    <NavMenu :menu="secondaryMenu" v-if="secondaryMenu"/>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup>

  import { ref, computed, onMounted, watch } from "vue";
  import { useRoute } from "vue-router";

  import appData from '/package';
  import primaryMenu from '@/menus/primary';
  import primaryBottomMenu from '@/menus/primary-bottom';
  import NavMenu from '@/components/NavMenu.vue';
  import Toggle from '@/components/Toggle.vue';

  import {
    Menu,
    useAuthStore,
    useSystemStore,
  } from "@/internal";

  const auth = useAuthStore();
  const system = useSystemStore();
  const user = auth.me;
  const route = useRoute();

  let navigationOpen = ref(true);

  const formId = computed(() => {
    return route.params.formId || null;
  });

  const activeRoute = computed(() => {
    return primaryMenu.menuItems && primaryMenu.menuItems.find(menuItem => menuItem.route && route.name && route.name.startsWith(menuItem.route.name)) || {};
  });

  const menu = computed(() => {
    return primaryMenu({
      formId: formId,
      user: user,
    });
  });

  const bottomMenu = computed(() => {
    return primaryBottomMenu({
      user: user,
    });
  });

  const secondaryMenu = computed(() => {
    if (activeRoute.menuItems) {
      return new Menu({
        menuItems: activeRoute.menuItems,
      });
    }

    return null;
  });

  const updateAvailable = computed(() => {
    return system.updateAvailable;
  });

  const version = computed(() => {
    return appData.version;
  });

  function handleToggleNavigation() {
    navigationOpen.value = !navigationOpen.value;
  }

  function handleUpdateAvailable() {
    window.location.reload();
  }

</script>

<style lang="scss">
    #navigation-contextual {
        display: flex;
        position: relative;
        z-index: 2000;


      &:hover {
            .toggle {
                opacity: 1;
            }
        }

        .toggle {
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(50%, 50%);
            transition: all .2s;

            &.is-active {
                opacity: 1;
            }
        }

        #navigation-contextual-content {
            display: flex;
            overflow-x: hidden;
            transition: all .2s;
            width: 0;

            &.is-open {
                width: 250px;
            }
        }

        #primary,
        #secondary {
            flex: 1;
            padding: 15px 12.5px;
        }

        #primary {
            background: $primary-light;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            #logo {
                color: $white;
                font-family: 'Montserrat', sans-serif;
                font-size: 24px;
                font-weight: 500;
                padding: 5px 15px 0;
            }

            #logo-placeholder {
                height: 48px;
            }

            #version {
                color: $grey;
                font-size: 13px;
                margin-top: 10px;
                text-align: center;
            }
        }

        #secondary {
            background: $white-ter;
            border-right: 1px solid $grey-lighter;

            #object-switcher {
                border: 1px solid $grey;
                border-radius: 4px;
                height: 39px;
                margin-bottom: 7.5px;
                overflow: hidden;
                padding: 7.5px 15px;
                text-align: center;
            }

            #object-switcher-placeholder {
                height: 47px;
            }
        }
    }
</style>
