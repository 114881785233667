import Archived from "@/pages/Questions/Archived.vue";
import Index from "@/pages/Questions/Index.vue";
import Create from "@/pages/Questions/Create.vue";
import Edit from "@/pages/Questions/Edit.vue";
import Show from "@/pages/Questions/Show.vue";

export default [
  {
    name: 'questions',
    path: '/questions',
    component: Index,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'messages-question',
      title: 'Active Questions',
    },
  },
  {
    name: 'questions/show',
    path: '/questions/:questionId',
    component: Show,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'questions/show',
      ],
      icon: 'eye',
      useModelForTitle: 'form-question'
    },
  },
  {
    name: 'questions/active',
    path: '/questions/active',
    component: Index,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'messages-question',
      title: 'Active Questions',
    },
  },
  {
    name: 'questions/active/show',
    path: '/questions/active/:questionId',
    component: Show,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'questions/active',
        'questions/active/show',
      ],
      icon: 'eye',
      useModelForTitle: 'form-question'
    },
  },
  {
    name: 'questions/archived',
    path: '/questions/archived',
    component: Archived,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
      ],
      icon: 'file-slash',
      title: 'Archived Questions',
    },
  },
  {
    name: 'questions/archived/show',
    path: '/questions/archived/:questionId',
    component: Show,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'questions/archived',
        'questions/archived/show',
      ],
      icon: 'eye',
      useModelForTitle: 'form-question',
    },
  },
  {
    name: 'questions/active/edit',
    path: '/questions/active/:questionId/edit',
    component: Edit,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'questions/active',
        'questions/active/show',
        'questions/active/edit',
      ],
      icon: 'message-question',
      useModelForTitle: 'form-question',
    },
  },
  {
    name: 'questions/create',
    path: '/questions/create',
    component: Create,
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        'questions'
      ],
      icon: 'file-plus',
      title: 'Create New Question',
    },
  },
]
