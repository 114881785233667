<template>
  <o-loading
      :full-page="true"
      :active="isLoading"
      icon="rotate"
      icon-size="large"
  />

  <div id="sub-form" class="is-flex" :style="colorFormTextStyle">
    <div class="left column is-two-thirds-desktop is-two-thirds-fullhd is-two-thirds-widescreen is-full-tablet is-full-mobile"
         :style="colorFormBackground ? `background-color: #${colorFormBackground};`: ''"
    >
      <div class="title is-capitalized" :style="colorFormTextStyle">{{form.title}}</div>

      <div v-if="responseSubmitted">
        <div class="subtitle has-margin-left-large" :style="colorFormTextStyle">{{ responseSubmittedMessage }}</div>
<!--        <div class="button is-primary has-margin-top"-->
<!--             :style="colorButton ? `background-color: #${colorButton};`: ''"-->
<!--             @click="handlePDF"-->
<!--             v-if="submittedResponseId"-->
<!--        >-->
<!--          <o-icon icon="file-pdf" pack="fas" class="has-margin-right" />-->
<!--          Download Response-->
<!--        </div>-->
      </div>

      <template v-else-if="responseLoaded || previewMode">
        <HTMLText v-if="form.instructions" :value="'<b>Instructions:</b> ' + form.instructions" class="has-margin-bottom-large" :style="colorFormText" />
        <div class="box form-box" :class="{'sub': !previewMode}">
          <o-loading
              :full-page="false"
              :active="isLoading"
              icon="rotate"
              icon-size="large"
          />
          <template v-for="question in questions">
            <div>
              <QuestionPicker :question="question"
                              :answers="answers[question.id]"
                              :form-id="formId"
                              :preview-mode="previewMode"
                              :filter-files="filterFileUpload"
                              :error="error(question.id)"
                              :color-text="colorFormText"
                              @input="handleAnswerUpdate"
                              @blur="handleAutoSave"
                              @disable-submit="handleDisableSubmit"
              />
            </div>
          </template>
        </div>
      </template>

      <div class="form-footer mobile is-flex is-justify-content-space-between"
           :style="colorBodyBackground ? `border-color: #${colorBodyBackground};`: ''"
           v-if="previewMode || (responseLoaded && !responseSubmitted)"
      >
        <div class="column is-two-thirds has-margin-right">
          <template v-if="editURL">
            <div class="help" :style="colorFormTextStyle">
              If you would like to submit your form at another time, copy and save the following URL to come back to.
              <template v-if="isSupported && editURL">
                <o-tooltip label="Copy to Clipboard">
                  <o-button :label="!copied ? 'Copy' : 'Copied!'"
                            class="copy-button"
                            icon-left="copy"
                            icon-pack="fas"
                            variant="secondary"
                            rounded
                            :style="colorButton ? `background-color: #${colorButton};`: ''"
                            @click='copy(editURL)'
                  />
                </o-tooltip>
              </template>
            </div>
            <div class="help edit-url" :style="colorFormTextStyle">{{editURL}}</div>
          </template>
        </div>
        <div class="column is-flex is-justify-content-flex-end is-one-third">
          <o-button variant="secondary" class="has-margin-right" @click="handleSaveProgress"
                    :style="colorBorder ? `background-color: #${colorBorder};`: ''"
                    :disabled="previewMode || submitDisabled"
          >
            Save Progress
          </o-button>
          <o-button variant="primary" @click="handleSubmit"
                    :style="colorButton ? `background-color: #${colorButton};`: ''"
                    :disabled="previewMode || submitDisabled"
          >
            Submit
          </o-button>
        </div>
      </div>
    </div>
    <div class="right column is-one-third-desktop is-one-third-fullhd is-one-third-widescreen is-full-tablet is-full-mobile"
         :style="colorBodyBackground ? `background-color: #${colorBodyBackground};`: ''"
    >
      <div class="logo-container">
        <div class="box logo"
             :style="colorBorder ? `border-color: #${colorBorder};`: ''"
        >
          <img :src="logoURL" :title="clientName" width="200" v-if="logoURL">
          <div class="subtitle" :style="colorFormTextStyle" v-else>{{clientName}}</div>
          <div class="title has-margin-top-large is-capitalized" :style="colorFormTextStyle">{{form.title}}</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>

  import HTMLText from "@/components/HTMLText.vue";
  import Modal from "@/components/Modal.vue";
  import QuestionPicker from "@/components/Pickers/QuestionPicker.vue";
  import { useClipboard } from '@vueuse/core';

  import {
    findRouteByName,
    NotificationError,
    NotificationSuccess,
    useFormStore,
    useGroupStore,
    useTradeStore,
    useSubFormStore,
    useSubFormResponseStore,
    useSubGroupStore,
    useSubPendingFormResponseStore,
    useSubTradeStore,
  } from "@/internal";
  import {computed, onMounted, ref, toRefs, watch} from "vue";
  import _ from "lodash";
  import {useOruga} from "@oruga-ui/oruga-next";
  import {useRouter} from "vue-router";

  const { text, copy, copied, isSupported } = useClipboard({legacy: true});
  const editURL = ref('');
  const formResponseStore = useSubFormResponseStore();
  const gcFormStore = useFormStore();
  const gcGroupStore = useGroupStore();
  const gcTradeStore = useTradeStore();
  const subFormStore = useSubFormStore();
  const subGroupStore = useSubGroupStore();
  const pendingFormResponseStore = useSubPendingFormResponseStore();
  const subTradeStore = useSubTradeStore();
  const oruga = useOruga();
  const router = useRouter();
  const answers = ref({});
  const submitDisabled = ref(false);
  const errors = ref([]);
  const formResponse = ref(null);
  const isLoading = ref(false);
  const responseSubmitted = ref(false);
  const responseSubmittedMessage = ref('Response Has Previously Been Submitted');
  const confirmLoadAnswersTitle = ref('Use Previously Filled Out Information?');
  const confirmLoadAnswersMessage = ref('You have previously filled out information on this form. Would you like to continue where you left off?');
  const responseLoaded = ref(false);
  const submittedResponseId = ref(null);
  const submittedResponseTitle = ref('');
  const firstLoad = ref(true);

  const props = defineProps({
    formId: {
      required: true,
    },
    clientId: {
      required: false,
      default: null,
    },
    companyId: {
      required: false,
      default: null,
    },
    previewMode: {
      required: false,
      type: Boolean,
      default: false,
    },
    privateKey: {
      required: false,
      default: null,
    },
    resumeKey: {
      required: false,
      default: null,
    },
  });
  const {
    clientId,
    companyId,
    formId,
    previewMode,
    privateKey,
    resumeKey,
  } = toRefs(props);

  const client = computed(() => form.value?.client);

  const clientName = computed(() => client.value?.name || '');

  const colorBodyBackground = computed(() => client.value?.pf_body_background);

  const colorBorder = computed(() => client.value?.pf_border_color);

  const colorButton = computed(() => client.value?.pf_button_color);

  const colorFormBackground = computed(() => client.value?.pf_form_background);

  const colorFormText = computed(() => client.value?.pf_form_text_color);

  const colorFormTextStyle = computed(() => colorFormText.value ? {color: `#${colorFormText.value}`} : '');

  const filterFileUpload = computed(() => client.value?.prequal_file_upload_filter);

  const form = computed(() => {
    return formStore.value.find(formId.value) || {};
  });

  const groups = computed(() => groupStore.value.data || []);

  const logoURL = computed(() => form.value?.client?.logo_url || null);

  const questions = computed(() => {
    return (form.value && form.value.questions && form.value.questions.sort((a, b) => a.rank > b.rank ? 1 : -1)) || [];
  });

  const response = computed(() => null);

  const formStore = computed(() => previewMode.value ? gcFormStore : subFormStore);

  const groupStore = computed(() => previewMode.value ? gcGroupStore : subGroupStore);

  const tradeStore = computed(() => previewMode.value ? gcTradeStore : subTradeStore);

  const trades = computed(() => tradeStore.value.data || []);

  onMounted(async () => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${import.meta.env.VITE_GOOGLE_CAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);

    await loadForm();

    if (!previewMode.value && privateKey.value) {
      loadResponse();
    }
    loadGroups();
    loadTrades();
  });

  function clearErrors() {
    errors.value = [];
  }

  async function confirmLoadPreviousAnswers() {
    const modalInstance = oruga.modal.open({
      component: Modal,
      props: {
        title: confirmLoadAnswersTitle.value,
        message: confirmLoadAnswersMessage.value,
        labelTrue: 'Yes',
        labelFalse: 'No',
        responseTrue: true,
        responseFalse: false,
      },
      cancelable: false,
      trapFocus: true,
      destroyOnHide: true,
    });
    const result = await modalInstance.promise;

    return new Promise((resolve) => {
      if (result) {
        resolve(result.action);
      }
      else {
        resolve(false);
      }
    });
  }

  function error(key) {
    return errors.value?.find(error => error.hasOwnProperty('key') && parseInt(error.key) === parseInt(key))?.detail || '';
  }

  function handleAnswerUpdate(answer) {
    answers.value[answer.join_id] = answer;
  }

  function handleDisableSubmit(disable) {
    submitDisabled.value = disable;
  }

  function handlePDF() {

    isLoading.value = true;

    const payload = {
      client: clientId.value,
      form_id: formId.value,
      private_key: privateKey.value,
      ignore_business_units: true,
      path: 'prequal/pdf',
    };

    if (resumeKey.value) {
      payload.resume_key = resumeKey.value;
    }
    if (companyId.value) {
      payload.company_id = companyId.value;
    }

    formResponseStore.pdf(submittedResponseId.value, `${submittedResponseTitle.value} - ${form.value.title}`, payload).then((response) => {
    }).catch((error) => {

    }).finally(() => {
      isLoading.value = false;
    });
  }

  async function handleSaveProgress() {

    if (previewMode.value) {
      return;
    }

    clearErrors();

    try {
      const token = await recaptchaToken();

      isLoading.value = true;

      const payload = {
        client: clientId.value,
        form_id: formId.value,
        private_key: privateKey.value,
        resume_key: null,
        ignore_business_units: true,
        fields_data: Object.values(answers.value),
        recaptcha_token: token,
      };

      if (resumeKey.value) {
        payload.resume_key = resumeKey.value;
      }
      if (companyId.value) {
        payload.company_id = companyId.value;
      }

      firstLoad.value = false;

      pendingFormResponseStore.store(payload).then((response) => {

        NotificationSuccess({
          message: `Saved`,
        });

        if (!companyId.value) {

          const route = findRouteByName(`subs/unknown/edit`);

          route.params = {
            ...route.params,
            formId: formId.value,
            clientId: clientId.value,
            privateKey: privateKey.value,
            resumeKey: response.data.resume_key,
          };

          router.push(route);
        }
        else {
          loadResponse();
        }
      }).catch((error) => {
        if (error.hasOwnProperty('errors')) {
          errors.value = error.errors;
        }
        else {
          NotificationError({
            message: error,
          });
        }
      }).finally(() => {
        removeLocalStorage();
        isLoading.value = false;
      });
    }
    catch(error) {
      NotificationError({
        message: 'Recaptcha Error',
      });
      console.error('Recaptcha Error', error);
    }
  }

  function handleAutoSave(){
    if (previewMode.value) {
      return;
    }

    isLoading.value = true;
    clearErrors();


    if(resumeKey.value){
      handleSaveProgress();
    }else{
      const payload = {
        client: clientId.value,
        form_id: formId.value,
        private_key: privateKey.value,
        resume_key: null,
        ignore_business_units: true,
        answers: Object.values(answers.value),
      };

      if (resumeKey.value) {
        payload.resume_key = resumeKey.value;
      }
      if (companyId.value) {
        payload.company_id = companyId.value;
      }
      const local_storage_key = getLocalStorageKey(payload);
      localStorage.setItem(local_storage_key, JSON.stringify(payload));
    }

    isLoading.value = false;
  }

  async function handleSubmit() {

    if (previewMode.value) {
      return;
    }
    clearErrors();

    try {
      const token = await recaptchaToken();
      isLoading.value = true;

      const payload = {
        client: clientId.value,
        form_id: formId.value,
        private_key: privateKey.value,
        resume_key: resumeKey.value,
        ignore_business_units: true,
        answers: Object.values(answers.value),
        recaptcha_token: token,
      };

      if (resumeKey.value) {
        payload.resume_key = resumeKey.value;
      }
      if (companyId.value) {
        payload.company_id = companyId.value;
      }

      formResponseStore.store(payload).then((response) => {
        NotificationSuccess({
          message: `Saved`,
        });

        submittedResponseId.value = response.data.id;
        submittedResponseTitle.value = response.data.title;
        responseSubmittedMessage.value = 'Thank You For Submitting Your Response';
        responseSubmitted.value = true;
      }).catch((error) => {
        if (error.hasOwnProperty('errors')) {
          errors.value = error.errors;

          NotificationError({
            message: 'There are errors in your response. Please review.',
          });
        }
        else {
          NotificationError({
            message: error,
          });
        }
      }).finally(() => {
        isLoading.value = false;
        removeLocalStorage();
      });
    }
    catch(error) {
      NotificationError({
        message: 'Recaptcha Error',
      });
      console.error('Recaptcha Error', error);
    }
  }

  function loadForm() {
    isLoading.value = true;

    const payload = {
      params: {
        include: [
          'client',
          'questions.customField',
          'questions.options',
        ],
      },
    };

    if (!previewMode.value) {
      payload.params.client = clientId.value;
      payload.params.company_id = companyId.value;
      payload.params.form_id = formId.value;
      payload.params.private_key = privateKey.value;
    }

    return formStore.value.show(formId.value, payload)
        .finally(() => {
          isLoading.value = false;
        });
  }

  function loadFormResponse() {
    if (!companyId.value && !resumeKey.value) {
      return;
    }

    isLoading.value = true;

    const payload = {
      params: {
        include: [
          'answers.values',
        ],
      },
    };

    if (!previewMode.value) {
      payload.params.client = clientId.value;
      payload.params.company_id = companyId.value;
      payload.params.form_id = formId.value;
      payload.params.private_key = privateKey.value;
      payload.params.resume_key = resumeKey.value;
      payload.params.ignore_business_units = true;
    }

    formResponseStore.show(payload)
        .then((response) => {
          formResponse.value = response;

          responseLoaded.value = true;

          answers.value = {};
          if (response.answers) {
            response.answers.forEach(questionAnswer => {
              answers.value[questionAnswer.join_id] = questionAnswer;
            });

            questions.value.forEach(question => {
              if (!answers.value[question.id]) {
                answers.value[question.id] = {
                  join_id: question.id,
                  question_id: question.question_id,
                  values: [],
                };
              }
            });
          }
        }).catch((error) => {
          if (error.hasOwnProperty('errors')) {
            NotificationError({}, error);
          }
          else {
            NotificationError({
              message: error,
            });
          }
        }).finally(() => {
          isLoading.value = false;
        });
  }

  function loadGroups() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    if (!previewMode.value) {
      payload.params.client = clientId.value;
      payload.params.company_id = companyId.value;
      payload.params.form_id = formId.value;
      payload.params.private_key = privateKey.value;
      payload.params.ignore_business_units = true;
    }

    groupStore.value.index(payload)
        .then(() => {
        }).finally(() => {
        });
  }

  function loadPendingFormResponse() {
    isLoading.value = true;

    const payload = {
      params: {
        include: [
        ],
      },
    };

    if (!previewMode.value) {
      payload.params.client = clientId.value;
      payload.params.form_id = formId.value;
      payload.params.private_key = privateKey.value;
      payload.params.ignore_business_units = true;

      if (resumeKey.value) {
        payload.params.resume_key = resumeKey.value;
      }
      if (companyId.value) {
        payload.params.company_id = companyId.value;
      }
    }

    return pendingFormResponseStore.show(payload)
        .then(async (response) => {
          isLoading.value = false;
          if (response.id) {
            if(response.fields_data){
              let proceed = true;
              if(firstLoad.value){
                proceed = await confirmLoadPreviousAnswers();
                firstLoad.value = false;
              }

              if(proceed) {
                loadSavedAnswers(response.fields_data);
                editURL.value = response.response_url;
                responseLoaded.value = true;
              }else{
                return null;
              }
            }
            return response;
          }
          else {
            populateAnswersFromLocalStorage();
            return null;
          }
        }).catch((error) => {
          if (error.hasOwnProperty('errors')) {
            NotificationError({}, error);
          }
          else {
            NotificationError({
              message: error,
            });
          }
          return null;
        }).finally(() => {
          isLoading.value = false;
        });
  }

  async function loadResponse() {
    if (!previewMode.value) {
      answers.value = {};
      questions.value.forEach(question => {
        answers.value[parseInt(question.id)] = {
          join_id: parseInt(question.id),
          values: [],
        };
      });

      const response = await loadPendingFormResponse();

      if (!response) {
        if (companyId.value) {
          loadFormResponse();
        } else {
          responseLoaded.value = true;
        }

        if (!responseLoaded.value && !resumeKey.value && !companyId.value) {
          populateAnswersFromLocalStorage();
          return null;
        }
      }
    }
  }

  function loadSavedAnswers(savedAnswers) {
    answers.value = {};
    if (savedAnswers) {
      savedAnswers.forEach(savedAnswer => {
        const answerValues = [];
        for (const [joinID, values] of Object.entries(savedAnswer)) {
          values.forEach(answer => {
            answerValues.push({
              value: answer,
            });
          });

          answers.value[parseInt(joinID)] = {
            join_id: parseInt(joinID),
            values: answerValues,
          };
        }

      });
    }
  }

  function loadTrades() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    if (!previewMode.value) {
      payload.params.client = clientId.value;
      payload.params.company_id = companyId.value;
      payload.params.form_id = formId.value;
      payload.params.private_key = privateKey.value;
      payload.params.ignore_business_units = true;
    }

    tradeStore.value.index(payload)
        .then(() => {
        }).finally(() => {
        });
  }

  function questionAnswers(joinID) {
    return answers.value?.find((answer) => parseInt(answer.join_id) === parseInt(joinID)) || {};
  }

  async function populateAnswersFromLocalStorage(){
    const params = {
      client: clientId.value,
      form_id: formId.value,
      private_key: privateKey.value
    };

    if(companyId.value){
      params.company_id = companyId.value;
    }

    const local_storage_key = getLocalStorageKey(params);

    const local_storage_entry = localStorage.getItem(local_storage_key);
    if(local_storage_entry !== null){
      isLoading.value = true;
      let response = JSON.parse(local_storage_entry);

      if (response.answers) {
        if (await confirmLoadPreviousAnswers()) {
          response.answers.forEach(questionAnswer => {
            answers.value[questionAnswer.join_id] = questionAnswer;
          });

          questions.value.forEach(question => {
            if (!answers.value[question.id]) {
              answers.value[question.id] = {
                join_id: question.id,
                question_id: question.question_id,
                values: [],
              };
            }
          });
          responseLoaded.value = true
        };
      }

      isLoading.value = false;
    }
  }

  function recaptchaToken() {
    return new Promise((resolve, reject) => {
      grecaptcha.ready(function() {
        grecaptcha.execute(import.meta.env.VITE_GOOGLE_CAPTCHA_SITE_KEY, {action: 'submit'})
          .then(token => {
            resolve(token);
          }).catch(error => {
            reject(token);
          });
      });
    });
  }

  function removeLocalStorage(){
    const params = {
      client: clientId.value,
      form_id: formId.value,
      private_key: privateKey.value
    };

    if(companyId.value){
      params.company_id = companyId.value;
    }

    const local_storage_key = getLocalStorageKey(params);
    const local_storage_entry = localStorage.getItem(local_storage_key);
    if(local_storage_entry !== null) {
      localStorage.removeItem(local_storage_key);
    }
  }

  function getLocalStorageKey(params){
    let local_storage_key = 'form_progress';
    if(params.form_id) {
      local_storage_key += "_" + params.form_id;
    }
    if(params.client) {
      local_storage_key += "_" + params.client;
    }
    if(params.private_key) {
      local_storage_key += "_" + params.private_key;
    }

    if(params.company_id) {
      local_storage_key += "_" + params.company_id;
    }else {
      local_storage_key += "_0";
    }

    return local_storage_key;
  }

</script>

<style lang="scss" scoped>

#sub-form {

  .form-box {
    position: relative;

    &.sub {
      margin-bottom: 7em;
    }
  }

  .left {
    background-color: rgb(255, 255, 255);
    min-height: 100vh;
    padding: 2em 3em 3em 3em;

    .box {
      align-items: flex-start;
      overflow-y: auto;
    }
  }

  .right {
    background-color: $primary;

    position: sticky;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .logo-container {
    display: block;
    margin-right: .75em;

    .logo {
      z-index: 10;
      border: 5px solid $secondary;
      border-radius: 0;

      .label {
        width: 200px;
      }
    }
  }

  .form-footer {
    background: #f7f7f7;
    border-top: 1px solid $primary;
    padding: 5px 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: inherit;
    z-index: 100;

    .copy-button {
      font-size: .65em;
    }

    .edit-url {
      overflow-x: scroll;
      text-wrap: nowrap;
    }
  }
}

@media (max-width: 768px) {
  #sub-form {
    .form-footer {
      &.mobile {
        flex-direction: column-reverse;
      }
    }
  }
}

@media (max-width: 1023px) {
  #sub-form {
    flex-direction: column-reverse;

    .left,
    .right {
      padding-left: 30px;
      padding-right: 30px;
      -webkit-box-pack: center;
      overflow: visible;
      min-height: unset;
    }

    .right {
      height: auto;
    }

    .logo-container {
      margin-right: unset;

      .icon {
        display: none;
      }

      .logo {
        .label {
          width: unset;
          margin-left: 1em;
        }

        .title {
          display: none;
        }
      }
    }
  }
}

</style>


<style lang="scss">

#sub-form {

  .form-footer {
    .tooltip-content {
      background: #4A4A4AFF !important;

      &:before {
        border-top-color: #4A4A4AFF !important;
      }
    }
  }
}

</style>