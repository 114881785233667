<template>
  <Page>
    <Form @cancel="handleCancel" @saved="handleView" />
  </Page>
</template>

<script setup>

import Page from "@/components/Page.vue";
import Form from "./Form.vue";

import {useRouter} from "vue-router";
import {
  findRouteByName,
} from "@/internal";

const router = useRouter();

function handleCancel() {
  const route = findRouteByName(`questions/active`);

  router.push(route);
}

function handleView(question) {
  const route = findRouteByName(`questions/active/show`);

  route.params = {
    ...route.params,
    questionId: question.id,
  };

  router.push(route);
}

</script>

<style lang="scss" scoped>

</style>