<template>

  <div class="index-table">

    <div class="loading-wrapper" v-if="loading && !loadingInBackground">
      <o-loading
          :full-page="false"
          active
          icon="rotate"
          icon-size="large"
      />
    </div>

    <div class="table-box">

      <div class="level top-controls">

        <div class="level-left">

          <div class="level-item">
            <slot name="left"/>
          </div>

          <div class="level-item" v-if="inboxToggle">
            <o-field>
              <o-radio v-model="inboxMode" :native-value="true">
                <span>Inbox</span>
              </o-radio>
              <o-radio v-model="inboxMode" :native-value="false">
                <span>All Project Leads</span>
              </o-radio>
            </o-field>
          </div>

          <div class="level-item" v-if="assignedToggle">
            <o-field>
              <o-radio v-model="assignedOnly" :native-value="false">
                <span>All</span>
              </o-radio>
              <o-radio v-model="assignedOnly" :native-value="true">
                <span>Just Mine</span>
              </o-radio>
            </o-field>
          </div>

          <div class="level-item">
            <o-dropdown aria-role="list" v-if="checkedRows.length">
              <div class="button is-secondary is-outlined" slot="trigger">
                <span>Bulk Actions</span>
                <o-icon icon="chevron-down" pack="fas" size="is-small"/>
              </div>
              <o-dropdown-item aria-role="listitem"
                             @click="action.click"
                             v-for="action in bulkActions"
                             :key="action.key"
              >
                {{action.title}}
              </o-dropdown-item>
            </o-dropdown>
          </div>

        </div>
        <div class="level-right">
          <div class="level-item" v-if="action">
            <div class="control has-icons-left">
              <span class="icon is-left">
                  <o-icon icon="search" size="is-small"/>
              </span>
              <o-input
                  class="search-input"
                  type="text"
                  placeholder="Search"
                  @keypress.native.enter="search"
                  @keydown.native.esc="searchClear"
                  v-model="searchTerm"
              />
            </div>
          </div>
          <div class="level-item" v-if="displayStatusFilter && hasArchived && action">
            <o-field>
              <o-radio v-model="archivedFilter" native-value="all">
                <span>All</span>
              </o-radio>

              <o-radio v-model="archivedFilter" native-value="active">
                <span>Active</span>
              </o-radio>

              <o-radio v-model="archivedFilter" native-value="archived">
                <span>Archived</span>
              </o-radio>
            </o-field>
          </div>
          <div class="level-item" v-if="displayAdd">
            <o-button class="is-fullwidth"
                     :label="addLabel"
                     variant="primary"
                     @click="handleAddClick"
                     v-if="canCreate"
            />
          </div>


          <div class="level-item">
            <slot name="right"/>
          </div>

          <div class="level-item">
            <o-button class="is-fullwidth" @click="handleSettingsClick">
              <o-icon icon="cog" size="is-small" variant="secondary" />
            </o-button>
          </div>
        </div>
      </div>
      <div class="level top-controls" v-if="filterByLetter">
        <div class="filter">
          <span class="pointer" :class="{ 'is-active': showAll }" @click="setFilterAll">All</span>
          <template v-for="letter in alphabet">
            <span class="pointer" :class="{ 'is-active': filterLetter === letter }" @click="setFilterLetter(letter)">{{letter}}</span>
          </template>
          <span class="pointer" :class="{ 'is-active': showNumbers }" @click="setFilterNumbers">0 - 9</span>
        </div>
      </div>

      <p v-if="!visibleColumns.length">No columns are selected</p>

      <template v-if="!loading">

        <div class="is-flex">
          <div class="has-margin-bottom-large column is-half">
            <o-select v-model="perPage" :disabled="!paginated">
              <option value="10">10 per page</option>
              <option value="20">20 per page</option>
              <option value="30">30 per page</option>
              <option value="40">40 per page</option>
              <option value="50">50 per page</option>
            </o-select>
          </div>
          <div class="column is-half" v-if="paginated">
            <o-pagination
                :total="paginator.total"
                :per-page="parseInt(perPage)"
                :simple="isPaginationSimple"
                :current="paginator.current_page"
                @change="handlePageChange"
            />
          </div>
        </div>

        <o-table :backend-pagination="paginated"
                backend-sorting
                :checkable="checkable"
                :checked-rows.sync="localCheckedRows"
                :data="items"
                :row-class="rowClass"
                th-current-sort-class="current-sort-class"
                th-sort-icon-class="sort-icon-class"
                icon-pack="fas"
                :default-sort-direction="sortOrder"
                :default-sort="sortBy"
                sort-icon="arrow-up"
                @sort="handleSort"
                v-if="!loading"
        >

          <template #empty>
            <div class="empty">
              <o-icon icon="inbox" size="is-extra-large"/>
              <br/>
              <div class="title has-text-centered">
                No Results
              </div>
            </div>
          </template>

          <o-table-column field="actions"
                        key="actions"
                        label=""
                        class="control-column"
                        v-slot="props"
                        v-if="displayActions"
          >
            <div class="row-controls">

              <o-dropdown aria-role="list" v-if="!props.row.system">

                <template #trigger="{ active }">
                  <button class="button is-light" slot="trigger">
                    <o-icon pack="fas" variant="secondary" :icon="active ? 'caret-up' : 'caret-down'"></o-icon>
                  </button>
                </template>

                <template v-if="isResourceForm && !props.row.system">
                  <o-dropdown-item aria-role="listitem" @click="handleClick(props.row)" v-if="canRead">
                    <o-icon icon="ellipsis-h-alt"/>
                    <span class="dropdown-item-text">View{{!props.row.form_locked && !props.row.archived ? ' / Edit' : ''}}</span>
                  </o-dropdown-item>

                  <o-dropdown-item aria-role="listitem" @click="handlePreviewClick(props.row)" v-if="canRead">
                    <o-icon icon="eye"/>
                    <span class="dropdown-item-text">Preview</span>
                  </o-dropdown-item>

                  <template v-if="props.row.form_locked">

                    <o-dropdown-item aria-role="listitem" @click="handleViewLogClick(props.row)" v-if="canRead">
                      <o-icon icon="list-check"/>
                      <span class="dropdown-item-text">View Log</span>
                    </o-dropdown-item>
                  </template>

                  <o-dropdown-item aria-role="listitem" @click="handleRestoreClick(props.row)" v-if="canRestore && props.row.archived">
                    <o-icon icon="spinner" size="is-small" class="fa-spin" v-if="restoring.includes(props.row.id)"/>
                    <o-icon icon="trash-restore"/>
                    <span class="dropdown-item-text">Restore</span>
                  </o-dropdown-item>

                  <o-dropdown-item aria-role="listitem" @click="handleArchiveClick(props.row)" v-else-if="canArchive && !props.row.archived">
                    <o-icon icon="spinner" size="is-small" class="fa-spin" v-if="archiving.includes(props.row.id)"/>
                    <o-icon icon="archive"/>
                    <span class="dropdown-item-text">Archive</span>
                  </o-dropdown-item>

                  <o-dropdown-item aria-role="listitem" @click="handleDeleteClick(props.row)" v-if="canDelete && !props.row.form_locked && props.row.archived">
                    <o-icon icon="trash-alt"/>
                    <span class="dropdown-item-text">Delete</span>
                  </o-dropdown-item>

                  <o-dropdown-item aria-role="listitem" @click="handleSendClick(props.row)" v-if="canUpdate && !props.row.archived  && props.row.num_questions && props.row.name && props.row.title && props.row.subject_question_id">
                    <o-icon icon="envelope"/>
                    <span class="dropdown-item-text">Send</span>
                  </o-dropdown-item>
                </template>

                <template v-else-if="isResourceFormResponse && !props.row.system">
                  <o-dropdown-item aria-role="listitem" @click="handleClick(props.row)" v-if="canRead">
                    <o-icon icon="eye"/>
                    <span class="dropdown-item-text">View</span>
                  </o-dropdown-item>

                  <o-dropdown-item aria-role="listitem" @click="handleDeleteClick(props.row)" v-if="props.row.status_key === 'declined'">
                    <o-icon icon="trash-alt"/>
                    <span class="dropdown-item-text">Delete</span>
                  </o-dropdown-item>
                </template>

                <template v-else-if="isResourceFormQuestion && !props.row.system">
                  <o-dropdown-item aria-role="listitem" @click="handleClick(props.row)" v-if="canRead">
                    <o-icon icon="ellipsis-h-alt"/>
                    <span class="dropdown-item-text">View{{!props.row.is_system || (props.row.is_system && (props.row.is_group_multi_select_type || props.row.is_group_single_select_type || props.row.is_trade_multi_select_type || props.row.is_trade_single_select_type)) ? ' / Edit' : ''}}</span>
                  </o-dropdown-item>

                  <o-dropdown-item aria-role="listitem" @click="handleDeleteClick(props.row)" v-if="canArchive && !props.row.is_system && !props.row.archived">
                    <o-icon icon="trash-alt"/>
                    <span class="dropdown-item-text">Delete</span>
                  </o-dropdown-item>
                </template>

                <template v-else>
                  <o-dropdown-item aria-role="listitem" @click="handleClick(props.row)" v-if="!props.row.system">
                    <o-icon icon="ellipsis-h-alt"/>
                    <span class="dropdown-item-text" style="padding-right: 10px;">View / Edit</span>
                  </o-dropdown-item>

                  <o-dropdown-item aria-role="listitem" @click="handleMergeClick(props.row)" v-if="!props.row.system && canUpdate && displayMerge">
                    <o-icon icon="object-group"/>
                    <span class="dropdown-item-text">Merge Into Another</span>
                  </o-dropdown-item>

                  <o-dropdown-item aria-role="listitem" @click="handleApproveDisapproveClick(props.row, true)" v-if="!props.row.approved && update && !props.row.system && canUpdate  && displayApprove">
                    <o-icon icon="spinner" size="is-small" class="fa-spin" v-if="approving.includes(props.row.id)"/>
                    <o-icon icon="thumbs-up" v-else/>
                    <span class="dropdown-item-text">Approve</span>
                  </o-dropdown-item>

                  <o-dropdown-item aria-role="listitem" @click="handleApproveDisapproveClick(props.row, false)"
                                 v-if="props.row.approved && update && !props.row.system && canUpdate">
                    <o-icon icon="spinner" size="is-small" class="fa-spin" v-if="disapproving.includes(props.row.id)"/>
                    <o-icon icon="thumbs-down" v-else/>
                    <span class="dropdown-item-text">Disapprove</span>
                  </o-dropdown-item>

                  <o-dropdown-item aria-role="listitem" @click="handleArchiveClick(props.row)" v-if="!props.row.archived && archive && !props.row.system && canArchive">
                    <o-icon icon="spinner" size="is-small" class="fa-spin" v-if="archiving.includes(props.row.id)"/>
                    <o-icon icon="archive" v-else/>
                    <span class="dropdown-item-text">Archive</span>
                  </o-dropdown-item>

                  <o-dropdown-item aria-role="listitem" @click="handleRestoreClick(props.row)"
                                 v-if="props.row.archived && restore && !props.row.system && canRestore">
                    <o-icon icon="spinner" size="is-small" class="fa-spin" v-if="restoring.includes(props.row.id)"/>
                    <o-icon icon="trash-restore" v-else/>
                    <span class="dropdown-item-text">Restore</span>
                  </o-dropdown-item>
                </template>

                </o-dropdown>

              <o-modal :active.sync="scopeSheetReminders[props.row.id]" :id="`scope-sheet-reminder-modal${props.row.id}`" :width="400">
                <div class="box">
                  <div class="title">Reminder</div>
                </div>
              </o-modal>

              <div class="tag is-warning" v-if="props.row.archived">Archived</div>

              <o-tooltip label="System Objects are Locked" type="is-warning" position="is-right"
                        v-if="props.row.system">
                <div class="button is-white" disabled="">
                  <o-icon icon="lock"/>
                </div>
              </o-tooltip>

            </div>
          </o-table-column>

          <template v-for="column in visibleColumns" :key="column.field">

            <o-table-column
                :field="column.field"
                :label="column.label"
                :numeric="column.numeric"
                :sortable="column.sortable"
                :width="column.width"
                v-slot="props"
            >
              <div :class="{ 'edit-column': canUpdate && column.editable && model, editing: isEditing(column, props.row) }">

                <template v-if="isEditing(column, props.row) && model">
<!--                  <DynamicFormInput :errors="form.errors"-->
<!--                                    :field="model.fields[column.field]"-->
<!--                                    inline-->
<!--                                    style="min-width: 200px;"-->
<!--                                    v-model="inlineInput"-->
<!--                  />-->
                </template>

                <component v-else-if="column.component"
                           :is="column.component"
                           :row="props.row"
                           :style="{ 'max-width': column.maxWidth ? `${column.maxWidth}px` : null, 'padding-top': !column.editable ? '0px' : null }"
                           :value="props.row[column.field]"
                           :label="column.label"
                           @click="handleClick"
                />

                <div class="no-wrap"
                     :class="{ truncate: column.truncate }"
                     :style="{ 'max-width': column.maxWidth ? `${column.maxWidth}px` : null, 'padding-top': !column.editable ? '0px' : null }"
                     v-else
                >
                  {{props.row[column.field]}}
                </div>

                <div class="edit-controls" v-if="canUpdate && column.editable && model">

                  <template v-if="isEditing(column, props.row)">

                    <div class="edit-cancel-button" @click="handleEditCancel">
                      <o-icon icon="times"/>
                    </div>

                    <div class="edit-button" @click="handleEditConfirm">
                      <o-icon icon="spinner" class="fa-spin" v-if="updating.includes(props.row.id)"/>
                      <o-icon icon="check" v-else/>
                    </div>

                  </template>

                  <template v-else>

                    <div class="edit-button" @click="handleEdit(props.row, column)"
                         v-if="column.editable">
                      <o-icon :icon="props.row[column.field] ? 'pencil' : 'plus'"/>
                    </div>

                  </template>

                </div>

              </div>

            </o-table-column>

          </template>

        </o-table>

        <div class="is-flex">
          <div class="has-margin-bottom-large column is-half">
            <o-select v-model="perPage" :disabled="!paginated">
              <option value="10">10 per page</option>
              <option value="20">20 per page</option>
              <option value="30">30 per page</option>
              <option value="40">40 per page</option>
              <option value="50">50 per page</option>
            </o-select>
          </div>
          <div class="column is-half" v-if="paginated">
            <o-pagination
                :total="paginator.total"
                :per-page="parseInt(perPage)"
                :simple="isPaginationSimple"
                :current="paginator.current_page"
                @change="handlePageChange"
            />
          </div>
        </div>

      </template>

    </div>

    <o-modal :active.sync="isTableSettingsModalActive" scroll="keep" id="table-settings-modal" :width="400">
      <TableSettings :availableColumns="availableColumns"
                     :title="title"
                     @saved="saveTableSettings"
      />
    </o-modal>

  </div>

</template>

<script setup>

import _, {camelCase, isArray} from 'lodash';

  import {
    Column,
    Dialog,
    Form,
    NotificationError,
    NotificationSuccess,
    useAuthStore,
    userHasPermission,
  } from '@/internal';

  import TableSettings from '@/components/TableSettings.vue';
  import {computed, onMounted, toRefs, ref, watch} from "vue";
  import {useRoute} from "vue-router";

  const route = useRoute();
  const authStore = useAuthStore();

  const props = defineProps({
    action: {
      default: '',
      required: false,
      type: Function,
    },
    addLabel: {
      default: '',
      required: false,
      type: String,
    },
    approveClick: {
      type: Function,
      default: () => {
        return {};
      },
      required: false,
    },
    approving: {
      default: () => [],
      required: false,
      type: Array,
    },
    archive: {
      type: Function,
    },
    archiving: {
      default: () => [],
      required: false,
      type: Array,
    },
    assignedToggle: {
      default: false,
      type: Boolean,
    },
    bulkActions: {
      default: () => [],
      required: false,
      type: Array,
    },
    checkable: {
      default: false,
      required: false,
      type: Boolean,
    },
    checkedRows: {
      default: () => [],
      required: false,
      type: Array,
    },
    click: {
      type: Function,
      default: () => {
        return {};
      },
      required: false,
    },
    destroy: {
      type: Function,
    },
    displayActions: {
      required: false,
      default: true,
      type: Boolean,
    },
    mergeClick: {
      type: Function,
      default: () => {
        return {};
      },
      required: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    defaultItemsPerPage: {
      required: false,
    },
    defaultSortBy: {
      required: false,
    },
    defaultSortOrder: {
      required: false,
    },
    defaultStatusFilter: {
      type: String,
    },
    disapproving: {
      default: () => [],
      required: false,
      type: Array,
    },
    filterByLetter: {
      type: Boolean,
      default: false,
    },
    hasArchived: {
      default: true,
      required: false,
      type: Boolean,
    },
    inboxToggle: {
      default: false,
      type: Boolean,
    },
    includes: {
      default: '',
      required: false,
    },
    isPaginationSimple: {
      type: Boolean,
      default: false,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
    model: {
      type: Function,
      required: false,
    },
    paginated: {
      type: Boolean,
      default: false,
    },
    paginationPosition: {
      type: String,
      default: 'bottom',
    },
    paginator: {
      type: Object,
      default: () => {
        return {};
      },
      required: false,
    },
    params: {
      default: () => {
        return {};
      },
      required: false,
      type: Object,
    },
    resource: {
      required: true,
      type: String,
    },
    restore: {
      type: Function,
    },
    restoring: {
      default: () => [],
      required: false,
      type: Array,
    },
    update: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    displayBadge: {
      type: Boolean,
    },
    displayMerge: {
      type: Boolean,
    },
    displayApprove: {
      type: Boolean,
    },
    displayStatusFilter: {
      required: false,
      type: Boolean,
      default: true,
    },
    displayViewProjects: {
      type: Boolean,
    },
  });
  const {
    action,
    addLabel,
    approveClick,
    approving,
    archive,
    archiving,
    assignedToggle,
    bulkActions,
    checkable,
    checkedRows,
    click,
    destroy,
    mergeClick,
    columns,
    defaultItemsPerPage,
    defaultSortBy,
    defaultSortOrder,
    defaultStatusFilter,
    disapproving,
    displayActions,
    filterByLetter,
    hasArchived,
    inboxToggle,
    includes,
    isPaginationSimple,
    items,
    model,
    paginated,
    paginationPosition,
    paginator,
    params,
    resource,
    restore,
    restoring,
    update,
    title,
    displayBadge,
    displayMerge,
    displayApprove,
    displayStatusFilter,
    displayViewProjects,
  } = toRefs(props);

  let alphabet = ref('ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''));
  let archivedFilter = ref('active');
  let assignedOnly = ref(false);
  let availableColumns = ref([]);
  let booted = ref(false);
  let editing = ref(null);
  let filterLetter = ref(null);
  let form = ref(new Form());
  let inboxMode = ref(false);
  let inlineInput = ref(null);
  let isTableSettingsModalActive = ref(false);
  let itemsPerPageKey = ref(null);
  let loading = ref(false);
  let loadingInBackground = ref(false);
  let localCheckedRows = ref([]);
  let page = ref(1);
  let perPage = ref(10);
  let scopeSheetReminders = ref([]);
  let sortBy = ref(defaultSortBy.value || '');
  let sortOrder = ref(defaultSortOrder.value || 'asc');
  let searchTerm = ref('');
  let searchVisible = ref(false);
  let settingsKey = ref(null);
  let showAll = ref(true);
  let showNumbers = ref(false);
  let tableSettings = ref(null);
  let tableSettingsLoaded = ref(false);
  let tableSettingsChanged = ref(false);
  let visibleColumns = ref([]);
  let updating = ref([]);
  let user = ref(null);

  const canArchive = computed(() => userHasPermission(`${camelCase(resource.value)}:archive`));
  const canCreate = computed(() => userHasPermission(`${camelCase(resource.value)}:create`));
  const canDelete = computed(() => userHasPermission(`${camelCase(resource.value)}:delete`));
  const canRead = computed(() => userHasPermission(`${camelCase(resource.value)}:read`));
  const canRestore = computed(() => userHasPermission(`${camelCase(resource.value)}:restore`));
  const canUpdate = computed(() => userHasPermission(`${camelCase(resource.value)}:update`));

  const displayAdd = computed(() => {
    return !!addLabel.value;
  });

  const isResourceForm = computed(() => {
    return resource.value === 'form';
  });

  const isResourceFormQuestion = computed(() => {
    return resource.value === 'form-question';
  });

  const isResourceFormResponse = computed(() => {
    return resource.value === 'form-response';
  });

  const emit = defineEmits([
    'addClick',
    'approveClick',
    'archiveClick',
    'click',
    'deleteClick',
    'editClick',
    'mergeClick',
    'previewClick',
    'response',
    'restoreClick',
    'sendClick',
    'update:checkedRows',
    'viewLogClick',
    'viewProjectsClick',
  ]);

  onMounted(() => {
    user.value = authStore.me;

    localCheckedRows.value = checkedRows.value;

    if (defaultStatusFilter && defaultStatusFilter.value) {
      archivedFilter.value = defaultStatusFilter.value;
    }

    const table = route.name.replace('/', '.');
    settingsKey.value = `${user.value.id}.${table}`;
    itemsPerPageKey.value = `${user.value.id}.${table}.perPage`;
    perPage.value = localStorage.getItem(itemsPerPageKey.value) ? localStorage.getItem(itemsPerPageKey.value) : (paginator.value.per_page ? paginator.value.per_page : (defaultItemsPerPage.value ? defaultItemsPerPage.value : perPage.value));
    loadTableSettings();
  });

  function getItems(backgroundFetch = false) {

    if (!action.value || loading.value) {
      return false;
    }

    loading.value = true;
    loadingInBackground.value = backgroundFetch;

    const payload = {
      params: {
        include: includes.value,
        page: {
          offset: page.value,
          limit: perPage.value,
        },
        status: archivedFilter.value,
      },
    };

    if (filterByLetter.value) {
      payload.params.startsWith = showAll.value ? null : showNumbers.value ? '0,1,2,3,4,5,6,7,8,9' : filterLetter.value;
    }

    if (sortBy.value) {
      if (!isArray(sortBy.value)) {
        sortBy.value = [sortBy.value];
      }

      payload.params.sort = [];
      sortBy.value.forEach(sort => {
        const sortByParam = sort === 'bidDue' ? 'bidDueDate' : sort;

        if (sortOrder.value && sortOrder.value.toLowerCase() === 'desc') {
          payload.params.sort.push("-" + _.snakeCase(sortByParam));
        }
        else {
          payload.params.sort.push(_.snakeCase(sortByParam));
        }
      });
    }

    if (inboxMode.value) {
      payload.params.inboxMode = inboxMode.value;
    }

    if (assignedOnly.value) {
      payload.params.assignedOnly = assignedOnly.value;
    }

    if (searchTerm.value.length) {
      payload.params.q = searchTerm.value;
    }

    if (route.params.formId) {
      payload.params.formId = route.params.formId;
    }

    if (route.params.responseId) {
      payload.params.responseId = route.params.responseId;
    }

    if (route.params.dateColumn && route.params.dateTimeStart && route.params.dateTimeEnd) {
      payload.params.dateColumn = route.params.dateColumn;
      payload.params.dateTimeStart = route.params.dateTimeStart;
      payload.params.dateTimeEnd = route.params.dateTimeEnd;
    }

    action.value(payload).then(response => {
      emit('response', response);
    }).catch((error) => {
      if (error.errors) {
        NotificationError({}, error);
      }
      else {
        NotificationError({
          message: error,
        });
      }
    }).finally(() => {
      booted.value = true;
      loading.value = false;
      loadingInBackground.value = false;
    });
  }

  function handleAddClick() {
    emit('addClick');
  }

  function handleClick(row) {
    emit('click', row);
  }

  function handleDeleteClick(row) {
    if (!destroy.value) {
      emit('deleteClick', row);
      return;
    }

    Dialog({
      title: 'Delete',
      message: `Are you sure you want to delete this and all related data?`,
      confirmText: 'Yes',
      canCancel: true,
      variant: 'warning',
      onCancel: () => {
      },
      onConfirm: () => {
        destroy.value(row.id).then(() => {
          NotificationSuccess({
            message: 'Deleted',
          });
        }).catch((error) => {
          if (error.errors) {
            NotificationError({}, error);
          }
          else {
            NotificationError({
              message: error,
            });
          }
        }).finally(() => {
          getItems(true);
        });
      },
    });

  }

  function handleEditClick(row) {
    emit('editClick', row);
  }

  function handleMergeClick(row) {
    emit('mergeClick', row);
  }

  function handlePreviewClick(row) {
    emit('previewClick', row);
  }

  function handleSendClick(row) {
    emit('sendClick', row);
  }

  function handleViewLogClick(row) {
    emit('viewLogClick', row);
  }

  function handleFormFieldUpdate(payload) {
    inlineInput.value = {
      ...payload,
    }
  }

  function handleEdit(row, column) {
    editing.value = {
      id: row.id,
      row,
      column,
    };
    inlineInput.value = row[column.field];

    form.value.fields = {
      ...row,
    };
  }

  function handleEditCancel() {
    editing.value = null;
    inlineInput.value = null;
  }

  function handleEditConfirm() {

    const payload = {};

    for (let [key, value] of Object.entries(model.value.fields)) {
      payload[key] = value.handler ? value.handler(editing.value.row[key]) : editing.value.row[key];
    }

    if (editing.value.column.field === 'bidDue') {
      payload['bidDueAt'] = inlineInput.value.bidDueAt;
      payload['bidDueDate'] = inlineInput.value.bidDueDate;
    }
    else {
      payload[editing.value.column.field] = inlineInput.value;
    }

    updating.value.push(editing.value.id);
    update.value(payload).then(() => {
      NotificationSuccess({
        message: 'Updated',
      });
      updating.value = [
        ...updating.value.filter(id => id !== editing.value.id),
      ];

      getItems();

      editing.value = null;
      inlineInput.value = null;
    }).catch((error) => {
      if (error.errors) {
        NotificationError({}, error);
      }
      else {
        NotificationError({
          message: error,
        });
      }
      updating.value = [
        updating.value.filter(id => id !== editing.value.id),
      ];
    });
  }

  function handleApproveDisapproveClick(row, approve) {
    if (approve) {
      approving.value.push(row.id);
    }
    else {
      disapproving.value.push(row.id);
    }

    emit('approveClick', row, approve);
  }

  function handleArchiveClick(row) {
    archiving.value.push(row.id);
    archive.value(row.id).then(() => {
      NotificationSuccess({
        message: 'Archived',
      });
    }).catch((error) => {
      if (error.errors) {
        NotificationError({}, error);
      }
      else {
        NotificationError({
          message: error,
        });
      }
    }).finally(() => {
      archiving.value = archiving.value.filter(id => id !== row.id);
      getItems(true);
    });
  }

  function handlePageChange(goToPage) {
    page.value = goToPage;
    getItems();
  }

  function handleRestoreClick(row) {
    restoring.value.push(row.id);
    restore.value(row.id).then(() => {
      NotificationSuccess({
        message: 'Restored',
      });
    }).catch((error) => {
      if (error.errors) {
        NotificationError({}, error);
      }
      else {
        NotificationError({
          message: error,
        });
      }
    }).finally(() => {
      restoring.value = restoring.value.filter(id => id !== row.id);
      getItems(true);
    });
  }

  function handleSettingsClick() {
    isTableSettingsModalActive.value = !isTableSettingsModalActive.value;
  }

  function handleSort(column) {
    sortBy.value = column.field;
    sortOrder.value = sortOrder.value === 'desc' ? 'asc' : 'desc';
    getItems();
  }

  function isEditing(column, row) {
    return editing.value !== null && editing.value.column.field === column.field && editing.value.id === row.id;
  }

  function saveTableSettings(updatedTableSettings) {
    if (!tableSettingsLoaded.value) {
      return;
    }

    const settings = {
      ...updatedTableSettings,
      archivedFilter: archivedFilter.value,
      assignedOnly: assignedOnly.value,
      inboxMode: inboxMode.value,
    };

    localStorage.setItem(settingsKey.value, JSON.stringify(settings));
    tableSettings.value = settings;
    tableSettingsChanged.value = true;
    isTableSettingsModalActive.value = false;
    loadAvailableColumns();
  }

  function loadAvailableColumns() {
    return new Promise((resolve) => {
      if (tableSettings.value && tableSettings.value.columns) {
        availableColumns.value = columns.value
            .map(column => new Column(column))
            .map(column => {
              const columnSettings = tableSettings.value.columns.find(_column => _column.field === column.field) || {};
              column.rank = columnSettings.rank !== undefined ? columnSettings.rank : column.rank;
              column.visible = columnSettings.visible !== undefined ? columnSettings.visible : column.visible;
              return column;
            });
      } else {
        availableColumns.value = columns.value.map(column => new Column(column));
      }

      resolve();
    }).then(() => {
      loadVisibleColumns();
    });
  }

  function loadTableSettings() {
    tableSettings.value = JSON.parse(localStorage.getItem(settingsKey.value)) || {};
    archivedFilter.value = defaultStatusFilter.value || 'active';
    inboxMode.value = tableSettings.value && tableSettings.value.inboxMode !== undefined ? tableSettings.value.inboxMode : false;
    tableSettingsLoaded.value = true;
    loadAvailableColumns();
  }

  function loadVisibleColumns() {
    return new Promise((resolve) => {
      const numPreviousVisibleColumns = visibleColumns.value.length;
      const previousVisibleColumns = JSON.stringify(visibleColumns.value);
      visibleColumns.value = availableColumns.value.filter(column => column.visible).sort((a, b) => {
        if (a.rank > b.rank) return 1;
        else if (a.rank < b.rank) return -1;
        else return 0;
      });
      const reloadData = (!numPreviousVisibleColumns && previousVisibleColumns !== JSON.stringify(visibleColumns.value)) || tableSettingsChanged.value;
      tableSettingsChanged.value = false;
      resolve(reloadData);
    }).then((reloadData = false) => {
      if (reloadData) {
        getItems();
      }
    });
  }

  function rowClass(row, index) {
    return action.value === 'project/index'
        ? row.source === 'scraper' ? 'source-scraper' : row.source === 'rfq' ? 'source-rfq' : 'source-manual'
        : action.value === 'company/index'
            ? row.isRfqCompany ? 'source-rfq' : null
            : null;
  }

  function search() {
    if (!searchTerm.value.length) {
      return;
    }

    page.value = 1;

    getItems();
  }

  function searchClear() {
    searchTerm.value = '';
    page.value = 1;
    getItems();
  }

  function setFilterAll() {
    showAll.value = true;
    showNumbers.value = false;
    filterLetter.value = null;

    getItems();
  }

  function setFilterLetter(letter) {
    showAll.value = false;
    showNumbers.value = false;
    filterLetter.value = letter;

    getItems();
  }

  function setFilterNumbers() {
    showAll.value = false;
    showNumbers.value = true;
    filterLetter.value = null;

    getItems();
  }

  watch(archivedFilter, () => {
    page.value = 1;
    saveTableSettings(tableSettings.value);
  });

  watch(assignedOnly, () => {
    page.value = 1;
    saveTableSettings(tableSettings.value);
  });

  watch(columns, () => {
    loadTableSettings();
  });

  watch(inboxMode, () => {
    page.value = 1;
    saveTableSettings(tableSettings.value);
  });

  watch(localCheckedRows, () => {
    emit('update:checkedRows', localCheckedRows.value);
  });

  watch(params, () => {
    page.value = 1;
    saveTableSettings(tableSettings.value);
  });

  watch(perPage, () => {
    localStorage.setItem(itemsPerPageKey.value, perPage.value);

    page.value = 1;
    getItems();
  });

  watch(defaultStatusFilter, () => {
    if (defaultStatusFilter.value) {
      archivedFilter.value = defaultStatusFilter.value;
    }

    page.value = 1;
    getItems();
  });

</script>

<style lang="scss">
.index-table {

  .loading-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  .filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;

    span.is-active {
      color: $primary;
      font-weight: bold;
    }
  }

  .current-sort-class {
    border-color: $secondary !important;
  }

  .sort-icon-class {
    color: $secondary !important;
  }

  .pagination-previous,
  .pagination-next,
  .pagination-link {
    color: $secondary;
  }

  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: $secondary !important;
  }

  .pagination-previous:hover,
  .pagination-next:hover,
  .pagination-link:hover,
  .pagination-previous[disabled],
  .pagination-previous.is-disabled,
  .pagination-next[disabled],
  .pagination-next.is-disabled,
  .pagination-link[disabled],
  .pagination-link.is-disabled {
    color: $secondary;
  }

  .b-table {
    table.table {
      tr {
        td {
          min-width: 105px;

          &.checkbox-cell {
            min-width: auto;
            width: 40px;
          }

          &.control-column {
            min-width: auto;
            width: 45px;
          }
        }
      }
    }
  }

  .source-manual {
    td:first-child {
      border-left: 10px solid transparent;
    }
  }

  .source-scraper {
    td:first-child {
      border-left: 10px solid lighten($primary, 10%);
    }
  }

  .source-rfq {
    td:first-child {
      border-left: 10px solid #27ae60;
    }
  }

  .edit-column {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    min-height: 44px;
    padding: 11px 70px 11px 25px;
    position: relative;

    .edit-controls {
      align-items: center;
      cursor: pointer;
      display: none;
      justify-content: center;
      margin-top: -13px;
      position: absolute;
      right: 8px;
      top: 50%;

      .icon {
        background: $primary;
        border-radius: 50%;
        color: $white;
        font-size: 0.65em;
        height: 26px;
        margin-left: 5px;
        width: 26px;
      }

      .button {
        border: none;
      }

      .edit-cancel-button .icon {
        background: $white;
        color: $grey-darker;
      }
    }

    &:hover {

      border: 1px dashed $grey-dark;

      .edit-controls {
        display: flex;
      }
    }

    &.editing {
      border: 1px dashed $grey-dark;
      padding: 5px 70px 5px 15px;

      .edit-controls {
        display: flex;
      }
    }
  }

  .row-controls {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      margin-left: 5px;
    }

    .dropdown-item {

      .icon {
        position: relative;
        top: 2px;
      }

      .dropdown-item-text {
        position: relative;
        top: -2px;
        left: 15px;
      }
    }
  }

  .empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5% 0;

    .title {
      margin-top: 1rem;
    }
  }
}
</style>
