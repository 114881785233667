<template>
    <div @click="handleClick" class="text-column is-link">
        {{value}}
    </div>
</template>

<script setup>

  import {toRefs} from "vue";

  const props = defineProps( {
    row: {
      required: true,
    },
    value: {
      required: true,
    },
  });
  const {
    row,
    value,
  } = toRefs(props);

  const emit = defineEmits([
    'click',
  ]);

  function handleClick() {
    emit('click', row.value);
  }

</script>

<style scoped lang="scss">
    .text-column {
      max-width: 200px;
      overflow-x: auto;
    }
</style>