<template>
    <div>
        <NavMenu :menu="recentMenu"  />
    </div>
</template>

<script setup>

import NavMenu from '../NavMenu.vue';

import {computed, toRefs} from "vue";

import {
  Menu,
} from "@/internal";

const props = defineProps({
  recents: {
    default: [],
    required: true,
    type: Array,
  },
});
const {
  recents,
} = toRefs(props);

const recentMenu = computed(() => {
  return new Menu({
    title: 'Recent',
    menuItems: recents.value.map(route => {
      return {
        title: route.meta?.title || route.meta.title || route.title || 'No Title Set',
        route,
        displayInactive: true,
        iconPack: 'fal',
      };
    }),
  });
});

</script>

<style scoped lang="scss">

</style>