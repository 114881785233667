<template>
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        icon="rotate"
        icon-size="large"
    />

  <div class="question-form has-margin-bottom-large">
    <o-field :label="`Question${isQuestionRequired ? '*' : ''}`"
             :variant="error('question') ? 'danger' : ''"
             :message="error('question')"
    >
      <o-input v-model="question" :required="isQuestionRequired" :disabled="isSystemQuestion" maxlength="255"></o-input>
    </o-field>

    <o-field :label="`Question Note${isQuestionNoteRequired ? '*' : ''}`"
             :variant="error('question_note') ? 'danger' : ''"
             :message="error('question_note')"
    >
      <o-input v-model="note" type="textarea" :required="isQuestionNoteRequired" :disabled="isSystemQuestion"></o-input>
    </o-field>

    <o-field label="Question Type*"
             :variant="error('type_id') ? 'danger' : ''"
             :message="error('type_id')"
    >
      <o-dropdown v-model="type"
                  aria-role="list"
                  menu-class="menu-class"
                  close-on-click
                  required
                  :disabled="isSystemQuestion"
      >
        <template #trigger="{ active }">
          <div class="button is-secondary is-outlined">
            <span>{{ (type && type.name) || '--- Select ---'}}</span>
            <o-icon :icon="active ? 'caret-up' : 'caret-down'" pack="fas"></o-icon>
          </div>
        </template>

        <o-dropdown-item :value="type" v-for="type in questionTypes">{{ type.name }}</o-dropdown-item>
      </o-dropdown>
    </o-field>

    <o-field label="Answer Options*" addons-class="addons-class" v-if="isOptionsType || isGroupType || isTradeType"
             :variant="error('options') ? 'danger' : ''"
             :message="error('options')"
    >
      <div class="has-margin-bottom-small">{{optionsInstructions}}</div>

      <template v-if="isOptionsType">
        <draggable v-model="options"
                   item-key="id"
                   tag="div"
                   :component-data="{name:'fade'}"
                   @start="drag=true"
                   @end="drag=false"
                   class="target-area"
        >
          <template #item="{element}">
            <div class="is-flex has-margin-bottom is-align-items-center">
              <o-icon icon="sort" pack="fas" variant="secondary" size="small" class="sort-icon has-margin-right" />
              <o-input v-model="element.value" class="has-margin-right-small" input-class="input-class" maxlength="150" />
              <div class="button action-icon" @click="handleRemoveOption(element)">
                <o-icon icon="trash-alt" variant="secondary" />
              </div>
            </div>
          </template>
        </draggable>

        <div class="is-flex has-margin-bottom is-align-items-center">
          <o-icon icon="sort" pack="fas" variant="secondary" size="small" class="sort-icon-placeholder has-margin-right" />
          <o-input v-model="newOption" class="has-margin-right-small" input-class="input-class" @blur="handleNewOption" maxlength="150" />
          <div class="button action-icon" @click="handleNewOption">
            <o-icon icon="circle-plus" variant="secondary" />
          </div>
        </div>
      </template>

      <div v-else>
        <o-checkbox v-model="selectAll" @click="handleSelectAll">Check All</o-checkbox>
        <div class="box multi-select has-margin-top" :class="{'danger': error('options')}">
          <o-checkbox v-for="group in groups" v-model="selectedGroups" :native-value="group.id" root-class="checkboxes" required v-if="isGroupType">{{group.name}}</o-checkbox>
          <o-checkbox v-for="trade in trades" v-model="selectedTrades" :native-value="trade.id" root-class="checkboxes" required v-else>{{trade.short_label}}</o-checkbox>
        </div>
      </div>
    </o-field>

    <o-field label="Custom Field"
             addons-class="addons-class"
             :variant="error('custom_field_id') ? 'danger' : ''"
             :message="error('custom_field_id')"
             v-else-if="isCustomType"
    >
      <o-dropdown v-model="customField"
                  aria-role="list"
                  menu-class="menu-class"
                  close-on-click
      >
        <template #trigger="{ active }">
          <div class="button is-secondary is-outlined">
            <span>{{ (customField && customField.name) || '--- Select ---'}}</span>
            <o-icon :icon="active ? 'caret-up' : 'caret-down'" pack="fas"></o-icon>
          </div>
        </template>

        <o-dropdown-item :value="field" v-for="field in customFields">{{ field.name }}</o-dropdown-item>
      </o-dropdown>
    </o-field>

  </div>

  <div class="has-text-right">
    <o-button class="is-primary has-margin-right" @click="handleCancel">Cancel</o-button>
    <o-button class="is-primary" @click="handleSave">{{saveButtonLabel}}</o-button>
  </div>
</template>

<script setup>

  import {
    NotificationError,
    NotificationSuccess,
    useCustomFieldStore,
    useGroupStore,
    useFormQuestionStore,
    useFormQuestionTypeStore,
    useTradeStore,
  } from "@/internal";

  import {computed, onMounted, ref, toRefs, watch} from "vue";
  import draggable from 'vuedraggable';
  import {v4 as uuidv4} from 'uuid';

  const customFieldStore = useCustomFieldStore();
  const formQuestionStore = useFormQuestionStore();
  const formQuestionTypeStore = useFormQuestionTypeStore();
  const groupStore = useGroupStore();
  const tradeStore = useTradeStore();
  const customField = ref({});
  const errors = ref([]);
  const isLoading = ref(false);
  const question = ref('');
  const newOption = ref('');
  const note = ref('');
  const type = ref({});
  const options = ref([]);
  const selectAll = ref(false);
  const selectedGroups = ref([]);
  const selectedTrades = ref([]);

  const props = defineProps({
    questionValue: {
      required: false,
      type: Object,
      default: null,
    },
  });
  const {
    questionValue,
  } = toRefs(props);

  const emit = defineEmits([
      'cancel',
      'saved',
  ]);

  const canEditSystemQuestionOptions = computed(() => questionValue.value?.is_group_multi_select_type || questionValue.value?.is_group_single_select_type || questionValue.value?.is_trade_multi_select_type || questionValue.value?.is_trade_single_select_type);

  const customFields = computed(() => customFieldStore.data || []);

  const groups = computed(() => groupStore.data.sort((a, b) => a.name > b.name ? 1 : -1) || []);

  const isCustomType = computed(() => type.value?.is_custom_type);

  const isGroupType = computed(() => type.value?.is_group_multi_select_type || type.value?.is_group_single_select_type);

  const isInstructionType = computed(() => type.value?.is_instruction_type);

  const isOptionsType = computed(() => type.value?.is_checkbox_type || type.value?.is_radio_type || type.value?.is_dropdown_type);

  const isQuestionRequired = computed(() => !isInstructionType.value);

  const isQuestionNoteRequired = computed(() => false);

  const isSystemQuestion = computed(() => questionValue.value?.is_system);

  const isTradeType = computed(() => type.value?.is_trade_multi_select_type || type.value?.is_trade_single_select_type);

  const optionsInstructions = computed(() => isOptionsType.value ? 'Enter the options available for this question. Drag them to the order you\'d like.' : (isGroupType.value ? 'Select the groups available for this question.' : 'Select the trades available for this question.'));

  const questionTypes = computed(() => formQuestionTypeStore.data || []);

  const saveButtonLabel = computed(() => questionValue.value ? 'Save' : 'Add');

  const trades = computed(() => tradeStore.data.sort((a, b) => a.short_label > b.short_label ? 1 : -1) || []);

  onMounted(() => {
    loadQuestionTypes();
    loadCustomFields();
    loadGroups();
    loadTrades();

    if (questionValue.value) {
      init();
    }
  });

  function clearErrors() {
    errors.value = [];
  }

  function clearForm() {
    question.value = '';
    note.value = '';
    type.value = {};

    options.value = [];
    selectedGroups.value = [];
    selectedTrades.value = [];
    customField.value = {};
  }

  function error(key) {
    return errors.value?.find(error => error.hasOwnProperty('key') && error.key === key)?.detail || '';
  }

  function handleCancel() {
    clearForm();
    emit('cancel');
  }

  function handleNewOption() {
    if (newOption.value.trim()) {
      options.value.push({
        id: `new_${uuidv4()}`,
        value: newOption.value,
      });
    }
    newOption.value = '';
  }

  function handleRemoveOption(removeOption) {
    options.value = options.value.filter(option => option.id != removeOption.id);
  }

  function handleSave() {

    isLoading.value = true;
    clearErrors();

    const payload = {
      include: [
        'options',
      ],
      id: questionValue.value ? questionValue.value.id : null,
      question: question.value,
      question_note: note.value,
      type_id: parseInt(type.value.id),
    };

    if (isOptionsType.value) {
      payload.options = options.value.map(option => option.value);
    }
    else if (isGroupType.value) {
      payload.options = selectedGroups.value;
    }
    else if (isTradeType.value) {
      payload.options = selectedTrades.value;
    }
    else if (isCustomType.value) {
      payload.custom_field_id = customField.value.id;
    }

    if (payload.options) {
      payload.options = payload.options.filter(option => !!option);
    }

    const action = questionValue.value ? formQuestionStore.update : formQuestionStore.store;

    action(payload).then((response) => {
      NotificationSuccess({
        message: `Question ${questionValue.value ? 'Saved' : 'Added'}`,
      });

      clearForm();
      emit('saved', response.data);
    }).catch((error) => {
      if (error.hasOwnProperty('errors')) {
        errors.value = error.errors;
      }
      else {
        NotificationError({
          message: error,
        });
      }
    }).finally(() => {
      isLoading.value = false;
    });

  }

  function handleSelectAll() {
    selectAll.value = !selectAll.value;

    if (isGroupType.value) {
      selectedGroups.value = selectAll.value ? groups.value.map(group => group.id) : []
    }
    else if (isTradeType.value) {
      selectedTrades.value = selectAll.value ? trades.value.map(trade => trade.id) : []
    }
  }

  function init() {
    question.value = questionValue.value.question;
    note.value = questionValue.value.question_note;
    type.value = questionValue.value.formQuestionType;
    customField.value = questionValue.value.customField;
    options.value = questionValue.value.options || [];
    selectedGroups.value = questionValue.value.is_group_multi_select_type || questionValue.value.is_group_single_select_type ? questionValue.value.options.map(option => option.value_id) : [];
    selectedTrades.value = questionValue.value.is_trade_multi_select_type || questionValue.value.is_trade_single_select_type ? questionValue.value.options.map(option => option.value_id) : [];
  }

  function loadCustomFields() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    customFieldStore.index(payload)
        .then(() => {
        }).finally(() => {
    });
  }

  function loadQuestionTypes() {
    isLoading.value = true;

    const payload = {
      params: {
        include: [
        ],
      },
    };

    formQuestionTypeStore.index(payload)
        .finally(() => {
          isLoading.value = false;
        });
  }

  function loadGroups() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    groupStore.index(payload)
        .then(() => {
        }).finally(() => {
    });
  }

  function loadTrades() {
    const payload = {
      params: {
        include: [
        ],
      },
    };

    tradeStore.index(payload)
        .then(() => {
        }).finally(() => {
    });
  }

  watch(questionValue, () => {
    init();
  });

  watch(type, () => {
    selectAll.value = false;
  });

</script>

<style lang="scss" scoped>

.question-form {

  .action-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }

  .multi-select {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
  }

  .checkboxes {
    display: flex !important;
    margin-bottom: .5em;
  }

  .sort-icon {
    cursor: pointer;
  }

  .sort-icon-placeholder {
    color: transparent !important;
  }

  .box {
    &.danger {
      border-color: $danger;
    }
  }
}

.menu-class {
  max-height: 300px;
}

</style>

<style lang="scss">

.question-form {
  .addons-class {
    display: block !important;
  }

  .input-class {
    width: 500px;
  }
}

</style>
