<template>
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        :can-cancel="true"
        icon="rotate"
        icon-size="large"
    />

    <IndexTable
        :key="key"
        :action="formResponseStore.index"
        :columns="defaultTableColumns"
        :default-status-filter="statusFilter"
        :display-status-filter="false"
        :includes="include"
        :items="formResponseStore.data"
        paginated
        :pagination-position="paginationPosition"
        :paginator="paginator"
        resource="form-response"
        title="Responses"
        default-sort-by="submitted_at"
        default-sort-order="desc"
        @response="handleResponse"
        @click="handleView"
        @deleteClick="handleDelete"
    />
</template>

<script setup>

  import {ref, computed, onMounted, watch, toRefs, onBeforeMount} from "vue";
  import {useRouter} from "vue-router";

  import {
    useAuthStore,
    useFormResponseStore,
    Dialog,
    findRouteByName,
    NotificationError,
    NotificationSuccess,
  } from "@/internal";

  import IndexTable from "@/components/IndexTable.vue";

  const authStore = useAuthStore();
  const formResponseStore = useFormResponseStore();
  const router = useRouter();
  const isLoading = ref(false);
  const include = [
      'company',
      'formUser',
      'formResponseWorkflow.activeStep',
  ];
  const key = ref(1);
  const defaultTableColumns = [
    {
      field: 'id',
      label: 'ID',
      width: 40,
      numeric: true,
    },
    {
      field: 'title',
      label: 'Submission',
      component: 'NameColumn',
      sortable: true,
      editable: true,
    },
    {
      field: 'submitted_at',
      label: 'Submitted On',
      sortable: true,
    },
    {
      field: 'formUser',
      label: 'Approver',
      component: 'UserNameColumn',
    },
    {
      field: 'company',
      label: 'Company',
      component: 'CompanyColumn',
    },
    {
      field: 'formResponseWorkflow',
      label: 'Workflow (If Applicable)',
      component: 'WorkflowColumn',
    },
    {
      field: 'total_score',
      label: 'Score',
    },
  ];
  const paginator = ref({
    perPage: 10,
  });

  const props = defineProps({
    statusFilter: {
      required: false,
      type: String,
      default: 'pending',
    },
  });
  const {
    statusFilter,
  } = toRefs(props);

  const responseStatus = computed(() => {
    return statusFilter.value === 'pending' ? 'inbox' : statusFilter.value.replace('_', '-');
  });
  const paginationPosition = computed(() => {
    return "both";
  });

  function handleResponse(response) {
    if (response.paginator) {
      paginator.value = response.paginator;
    }
    else {
      paginator.value = {
        currentPage: 1,
        total: response.data.length,
      };
    }
  }

  function handleDelete(formResponse) {
    Dialog({
      title: 'Delete Response',
      message: `Are you sure you want to delete this response?`,
      confirmText: 'Yes',
      canCancel: true,
      variant: 'warning',
      onCancel: () => {
      },
      onConfirm: () => {

        isLoading.value = true;

        formResponseStore.delete(formResponse.id).then(() => {
          NotificationSuccess({
            message: `Response Deleted`,
          });
          key.value++;
        }).catch((error) => {
          if (error.errors) {
            NotificationError({}, error);
          }
          else {
            NotificationError({
              message: error,
            });
          }
        }).finally(() => {
          isLoading.value = false;
        });
      },
    });
  }

  function handleView(item) {
    const route = findRouteByName(`responses/${responseStatus.value}/show`);

    route.params = {
      ...route.params,
      responseId: item.id,
    };

    router.push(route);
  }

</script>

<style scoped>

</style>