<template>
  <Page :title="pageTitle" id="view-log">
    <o-loading
        :full-page="true"
        :active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <template v-slot:controls>
      <o-tooltip label="Download Email Logs" variant="secondary">
        <div class="button is-secondary has-margin-top" @click="handleExport">
          <o-icon icon="file-excel" pack="fas" size="large" />
        </div>
      </o-tooltip>
    </template>

    <ViewLog ref="viewLogRef"
             :response-id="responseId"
             :export-title="response?.form?.name"
     />

  </Page>
</template>

<script setup>

import {useRoute, useRouter} from "vue-router";

  import Page from "@/components/Page.vue";
  import ViewLog from "@/components/ViewLog.vue";

  import {
    findRouteByName,
    NotificationError,
    useAuthStore,
    useFormResponseStore,
  } from "@/internal";
  import {computed, onMounted, ref, watch} from "vue";

  const authStore = useAuthStore();
  const formResponseStore = useFormResponseStore();
  const route = useRoute();
  const router = useRouter();
  const isLoading = ref(false);
  const viewLogRef = ref(null);

  const response = computed(() => {
    return formResponseStore.find(responseId.value);
  });

  const responseId = computed(() => {
    return route.params.responseId || null;
  });

  const companyId = computed(() => {
    return response.value?.company?.id || null;
  });

  const pageTitle = computed(() => {
    return response.value ? response.value.title : '';
  });

  onMounted(() => {
    loadResponse();
    if (!companyId.value) {
      const route = findRouteByName(`responses/show`);

      route.params = {
        ...route.params,
        responseId: responseId.value,
      };

      router.push(route);
    }
  });

  function handleExport() {
    viewLogRef.value.handleExport();
  }

  function loadResponse() {

    isLoading.value = true;

    const payload = {
      params: {
        include: [
          'answers.values',
          'comments',
          'company',
          'form.questions.customField',
          'formResponseWorkflow.activeStep',
          'formResponseWorkflowSteps.user',
          'formResponseWorkflowSteps.workflowStep',
        ],
        include_can_email: true,
      },
    };

    return formResponseStore.show(responseId.value, payload)
      .catch((error) => {
        if (error.errors) {
          NotificationError({}, error);
        }
        else {
          NotificationError({
            message: error,
          });
        }
      }).finally(() => {
        isLoading.value = false;
      });
  }

</script>

<style lang="scss" scoped>

#view-log {
}

</style>