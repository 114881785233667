import { BaseApi } from "@pipelinesuite/ps-toolbox";

import Auth from "@/auth";

export default class BaseAPI extends BaseApi {

  baseUrl = `${import.meta.env.VITE_APP_API_URL}`;
  authToken = window.sessionStorage.getItem('access_token');

  async processResponse(response) {
    const data = typeof response.json === 'function' ? await response.json() : response.data;

    if (response.ok || response.statusText.toLowerCase() === "ok") {
      return data;
    }
    else if (response.status === 403 || (response.status === 401 && data.message.toLowerCase().indexOf('unauthenticated') >= 0)) {
      Auth.logout();
    }
    else if (response.status === 500) {
      return Promise.reject('Server Error');
    }
    else {
      let error = data && (data.message || data);

      try {
        if (error.errors.length && error.errors[0].message && JSON.parse(error.errors[0].message).hasOwnProperty('errors')) {
          error = JSON.parse(error.errors[0].message);
        }
        else if (JSON.parse(error).hasOwnProperty('errors')) {
          error = JSON.parse(error);
        }
      }
      catch (err) {
        error = 'Error Occurred';
      }

      return Promise.reject(error);
    }
  }

  async processAxiosError(response) {
    if ((typeof response) === 'string') {
      return Promise.reject(response);
    }

    let error = response && response.errors;

    try {
      if (error.length && error[0].hasOwnProperty('message')) {
        error = JSON.parse(error[0].message);

        if (error.hasOwnProperty('errors') && error.errors.length && error.errors[0].hasOwnProperty('code')) {
          error = error.errors[0].detail;
        }
      }
    }
    catch (err) {
    }

    return Promise.reject(error);
  }

};