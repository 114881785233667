<template>
    <div class="workflow-column no-wrap" v-if="value">
        {{ status }}
    </div>
</template>

<script setup>

import {computed, toRefs} from "vue";

const props = defineProps({
  row: {
    required: true,
  },
  value: {
    required: true,
  },
});
const {
  row,
  value,
} = toRefs(props);

const status = computed(() => value.value.is_complete ? 'Completed' : value.value.activeStep.label);

</script>

<style scoped lang="scss">
    .workflow-column {
        align-items: center;
        display: flex;
        min-width: 200px;
    }
</style>