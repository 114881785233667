import Overview from "@/pages/Overview.vue";
import NotActivated from "@/pages/NotActivated.vue";

export default [
  {
    name: 'overview',
    path: '/',
    component: Overview,
    alias: '/overview',
    meta: {
      requiresAuth: true,
      title: 'Overview',
    }
  },
  {
    name: 'not-activated',
    path: '/not-activated',
    component: NotActivated,
    meta: {
      requiresAuth: false,
      title: 'Account Not Activated',
    }
  },
]
