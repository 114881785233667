import { useOruga } from '@oruga-ui/oruga-next';

const oruga = useOruga();

export default function NotificationSuccess(config) {
  oruga.notification.open({
    position: 'top-right',
    variant: 'success',
    duration: 5000,
    ...config,
  });
};
