import { useOruga } from '@oruga-ui/oruga-next';

import DialogDisplay from '@/components/DialogDisplay.vue';

const oruga = useOruga();

export default function Dialog(config) {

  const newConfig = {...config};
  delete newConfig.message;

  oruga.notification.open({
    component: DialogDisplay,
    props: {
      ...config,
    },
    indefinite: true,
    position: 'top',
    variant: 'success',
    ...newConfig,
  });
};
