<template>
  <o-tooltip label="Go to Company Record" variant="secondary">
    <a class="is-secondary" :class="{'button': !iconOnly}" target="_blank" :href="`${rfqAppUrl}ehCompany/dspCompany/companyID/${companyId}`" v-if="companyId">
      <o-icon icon="address-card" pack="fas" :variant="iconOnly ? 'secondary': ''" class="has-margin-right" />
      <template v-if="!iconOnly">View Company</template>
    </a>
  </o-tooltip>
</template>

<script setup>

  import {computed, toRefs} from "vue";

  const props = defineProps({
    companyId: {
      required: true,
    },
    iconOnly: {
      required: false,
      default: false,
    }
  });
  const {
    companyId,
  } = toRefs(props);

  const rfqAppUrl = computed(() => import.meta.env.VITE_RFQ_APP_URL);

</script>

<style scoped lang="scss">
</style>