<template>
    <o-loading
        :full-page="true"
        :active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <div class="box">
      <EmailLogLegend />
    </div>

    <IndexTable
        :action="emailLogStore.index"
        :columns="defaultTableColumns"
        :display-status-filter="false"
        :display-actions="false"
        :includes="include"
        :items="emailLogStore.data"
        paginated
        pagination-position="both"
        :paginator="paginator"
        resource="email-log"
        title="Email Logs"
        :default-sort-by="sortBy"
        default-sort-order="desc"
        @response="handleResponse"
        v-if="formId || responseId"
    />
</template>

<script setup>

  import EmailLogLegend from "@/components/EmailLogLegend.vue";
  import IndexTable from "@/components/IndexTable.vue";

  import {
    useEmailLogStore,
  } from "@/internal";
  import {ref, toRefs} from "vue";

  const emailLogStore = useEmailLogStore();
  const defaultTableColumns = [
    {
      field: 'destination',
      label: 'Recipient',
      sortable: true,
    },
    {
      field: 'event_bits',
      label: 'Details',
      component: 'EmailEventsDetailsColumn',
      sortable: true,
    },
    {
      field: 'company',
      label: 'Company',
      component: 'CompanyColumn',
    },
    {
      field: 'sent_on',
      label: 'Request Date',
      sortable: true,
    },
    {
      field: 'sent_by',
      label: 'Sent By',
      sortable: true,
    },
    {
      field: 'processed',
      label: 'Processed',
      component: 'EmailEventColumn',
      sortable: true,
    },
    {
      field: 'deferred',
      label: 'Deferred',
      component: 'EmailEventColumn',
      sortable: true,
    },
    {
      field: 'dropped',
      label: 'Dropped',
      component: 'EmailEventColumn',
      sortable: true,
    },
    {
      field: 'delivered',
      label: 'Delivered',
      component: 'EmailEventColumn',
      sortable: true,
    },
    {
      field: 'bounced',
      label: 'Bounced',
      component: 'EmailEventColumn',
      sortable: true,
    },
    {
      field: 'opened',
      label: 'Opened',
      component: 'EmailEventColumn',
      sortable: true,
    },
    {
      field: 'clicked',
      label: 'Clicked',
      component: 'EmailEventColumn',
      sortable: true,
    },
  ];
  const include = [
    'company',
    'events',
  ];
  const isLoading = ref(false);
  const paginator = ref({
    perPage: 10,
  });
  const sortBy = [
    'sent_on',
  ];

  const props = defineProps({
    formId: {
      required: false,
    },
    responseId: {
      required: false,
    },
    exportTitle: {
      required: false,
      type: String,
    },
  });
  const {
    formId,
    responseId,
    exportTitle,
  } = toRefs(props);

  function handleExport() {
    if (!formId.value && !responseId.value) {
      return;
    }

    isLoading.value = true;

    const payload = {
      params: {
        path: "prequal/excel",
      },
    };

    if (formId.value) {
      payload.params.formId = formId.value;
    }
    if (responseId.value) {
      payload.params.responseId = responseId.value;
    }

    emailLogStore.export(`Email Logs - ${exportTitle.value}.csv`, payload).then((response) => {
    }).catch((error) => {

    }).finally(() => {
      isLoading.value = false;
    });
  }

  function handleResponse(response) {
    if (response.paginator) {
      paginator.value = response.paginator;
    }
    else {
      paginator.value = {
        currentPage: 1,
        total: response.data.length,
      };
    }
  }

  defineExpose({
    handleExport,
  });
</script>

<style lang="scss" scoped>


</style>