<template>
  <SubForm class="sub-form" :form-id="formId" :preview-mode="true" />
</template>

<script setup>

import {useRoute, useRouter} from "vue-router";

  import SubForm from "../Subs/SubForm.vue";

  import {
    Dialog,
    findRouteByName,
    useFormStore,
    userHasPermission,
  } from "@/internal";

  import {computed, onMounted} from "vue";

  const formStore = useFormStore();
  const route = useRoute();
  const router = useRouter();

  const canArchive = computed(() => {
    return userHasPermission('form:archive') && !form.value.archived;
  });

  const canDelete = computed(() => {
    return userHasPermission('form:delete') && !form.value.form_locked && form.value.archived;
  });

  const canEdit = computed(() => {
    return userHasPermission('form:update') && !form.value.form_locked && !form.value.archived;
  });

  const canRead = computed(() => {
    return userHasPermission('form:read');
  });

  const canRestore = computed(() => {
    return userHasPermission('form:restore') && form.value.archived;
  });

  const canSend = computed(() => {
    return userHasPermission('form:update') && !form.value.archived && form.value?.questions?.length
        && form.value.name && form.value.title && form.value.subject_question_id
        && (!form.value.send_email_alert || (form.value.send_email_alert && form.value.send_email_to_user_id && form.value.send_email_subject));
  });

  const form = computed(() => {
    return formStore.find(formId.value);
  });

  const formId = computed(() => {
    return route.params.formId || null;
  });

  const pageTitle = computed(() => {
    return "Preview: " + (form.value ? form.value.name : '');
  });

  const status = computed(() => {
    return form.value ? (form.value.archived ? 'archived' : 'active') : '';
  });

  function handleArchive() {
    if (!canArchive.value) {
      return;
    }

    isLoading.value = true;

    formStore.archive(formId.value)
        .then(() => {
        })
        .finally(() => {
          isLoading.value = false;
        });
  }

  function handleRestore() {
    if (!canRestore.value) {
      return;
    }

    isLoading.value = true;

    formStore.restore(formId.value)
        .then(() => {
          loadForm();
        })
        .finally(() => {
          isLoading.value = false;
        });
  }

  function deleteForm() {
    if (!canDelete.value) {
      return;
    }

    isLoading.value = true;

    formStore.delete(formId.value)
        .finally(() => {
          isLoading.value = false;

          const route = findRouteByName(`forms/archived`);
          router.push(route);
        });
  }

  function handleDelete() {

    Dialog({
      title: 'Delete Form',
      message: `Are you sure you want to delete this form?`,
      confirmText: 'Yes',
      canCancel: true,
      variant: 'warning',
      onCancel: () => {
      },
      onConfirm: () => {
        deleteForm();
      },
    });
  }

function loadForm() {
  isLoading.value = true;

  const payload = {
    params: {
      include: [
        'approvedGroup',
        'businessUnit',
        'questions.customField',
        'questions.options',
        'sendEmailTo',
        'subjectQuestion',
        'unapprovedGroup',
        'workflow.workflowSteps.emailRecipient',
      ],
    },
  };

  formStore.show(formId.value, payload).finally(() => {
    isLoading.value = false;
  });
}

</script>

<style lang="scss" scoped>

.sub-form {
  height: 600px;
  overflow-y: auto;
}

</style>