<template>
  <nav id="navigation-account">
    <div>
      <NavMenu :menu="accountSettingsMenu" />
    </div>
  </nav>
</template>

<script setup>

import settingsMenu from '@/menus/settings';
import NavMenu from '@/components/NavMenu.vue';
import {computed} from "vue";

import {
  findRouteByName,
} from "@/internal";

const accountSettingsMenu = computed(() => {
  return settingsMenu({
    // handleSystemPreferencesClick: async () => {
    //   const route = findRouteByName('settings/system-preferences');
    //   await router.push(route);
    // },

  });
});

</script>