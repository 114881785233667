import { deserialize } from "jsonapi-fractal";

import {
  AxiosInstance,
  BaseAPI,
} from '@/internal';

export default class FormFileAPI extends BaseAPI {

  async store(formId, data) {
    const formData = new FormData(); // pass data as a form

    formData.append("file", data);

    return AxiosInstance({
      url: `subs/form-files/${formId}/response`,
      data: formData,
      method: 'POST',
      headers: {
      },
    }).then(res => this.processResponse(res)
    ).catch((error) => {
      return this. processAxiosError(error);
    }).then(res => {
      return {
        data: res,
      }
    });
  }
};