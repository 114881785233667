<template>

  <div id="send-email" class="is-flex">
    <div class="column is-half">
      <o-field label="Email Message">
        <o-input type="textarea" v-model="emailMessage" />
      </o-field>
      <o-field label="Button Text" v-if="includeButton">
        <o-input v-model="buttonText" maxlength="255" />
      </o-field>
    </div>

    <div class="column is-half">
      <div class="label">Email Preview</div>
      <div class="box preview">
        <o-field label="Subject Line" v-if="formTitle">{{formTitle}}</o-field>

        <img :src="clientLogo" width="200" :alt="clientName" v-if="clientLogo" />

        <div class="box">
          <div class="has-margin-bottom-large">
            <HTMLText :value="htmlEmailMessage" />
          </div>
          <div class="button is-primary has-margin-bottom-large" v-if="includeButton">{{buttonText}}</div>
          <div class="has-margin-bottom">Thank you,</div>
          <div class="has-margin-left-large">
            <div>{{ `${user.first_name} ${user.last_name}` }}</div>
            <div>{{ `${user.client.name}` }}</div>
          </div>
        </div>

        <div><strong>{{ `${user.client.name}` }}</strong>{{ clientAddress ? ` - ${clientAddress}` : ''}}</div>
      </div>
    </div>
  </div>

</template>

<script setup>

  import HTMLText from '@/components/HTMLText.vue';
  import {computed, onMounted, ref, toRefs, watch} from "vue";

  import {
    useAuthStore,
  } from "@/internal";

  const authStore = useAuthStore();
  const buttonText = ref('Update Your Information Here');
  const emailMessage = ref('Please take a few minutes to fill out and/or update your information.\n\nFeel free to contact our office if you have any questions.');
  const user = authStore.me;

  const props = defineProps({
    message: {
      required: false,
      type: String,
    },
    button: {
      required: false,
      type: String,
    },
    formTitle : {
      required: false,
      type: String,
    },
    includeButton: {
      required: false,
      type: Boolean,
      default: true,
    },
  });
  const {
    message,
    button,
    formTitle,
    includeButton,
  } = toRefs(props);

  const emit = defineEmits([
     'update',
  ]);

  onMounted(() => {
    buttonText.value = button.value || buttonText.value;
    emailMessage.value = message.value || emailMessage.value;
    if (!message.value || (includeButton.value && !button.value)) {
      handleUpdate();
    }
  });

  const clientAddress = computed(() => {
    const client = user.client;

    return (`${client.address}${client.address ? ', ' : ''}${client.city}${client.city ? ', ' : ''}${client.state} ${client.zip}`).trim();
  });

  const clientLogo = computed(() => user?.client?.logo_url || null);

  const clientName = computed(() => user?.client?.name || '');

  const htmlEmailMessage = computed(() => emailMessage.value.split('\n').join('<br>'));

  function handleUpdate() {
    if (includeButton.value) {
      emit('update', emailMessage.value, buttonText.value);
    }
    else {
      emit('update', emailMessage.value);
    }
  }

  watch(emailMessage, () => handleUpdate());

  watch(buttonText, () => handleUpdate());

  watch(button, () => buttonText.value = button.value || buttonText.value);

  watch(message, () => emailMessage.value = message.value || emailMessage.value);

</script>

<style lang="scss" scoped>

#send-email {
  .box {
    &.preview {
      border-color: $secondary;
    }
  }
}

</style>
