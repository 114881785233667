import {defineStore} from "pinia";
import {deserialize, serialize} from "jsonapi-fractal";
import FormResponseCommentAPI from "@/api/FormResponseCommentAPI";
import {
  utcToLocal,
} from "@/internal";

export const useFormResponseCommentStore = defineStore('form-response-comment', {
  state: () => ({
    data: [],
  }),
  actions: {
    clear() {
      this.data = [];
    },
    find(id) {
      return this.data.find(data => parseInt(data.id) === parseInt(id)) || null;
    },
    index(formId, responseId, payload) {
      const { params } = payload;

      return new FormResponseCommentAPI().index(formId, responseId, params)
      .then((response) => {
        this.data = response.data.map((data) => {
          return {
            ...data,
            updated_at: utcToLocal(data.updated_at),
          };
        });
        return response;
      });
    },
    show(id, payload) {
      const { params } = payload;

      return new FormResponseCommentAPI().show(id, params)
        .then((response) => {
          const obj = {
            ...response.data,
            updated_at: utcToLocal(response.data.updated_at),
          };

          let index = 0;
          for (index = 0; index < this.data.length; index++) {
            if (parseInt(this.data[index].id) === parseInt(id)) {
              this.data[index] = {
                ...obj,
              }

              break;
            }
          }
          if (index === this.data.length) {
            this.data[index] = obj;
          }

          return obj;
        });
    },
    store(payload) {
      const { formId, responseId, params, ...rest } = payload;

      return new FormResponseCommentAPI().store(formId, responseId, rest)
        .then((response) => {
          return response;
        });
    },
    update(payload) {
      const { id, params, ...rest } = payload;

      return new FormResponseCommentAPI().update(id, rest)
        .then((response) => {
          return response;
        });
    },
    delete(id) {
      return new FormResponseCommentAPI().delete(id)
        .then((response) => {
        });
    },
  },
  persist: true,
});
