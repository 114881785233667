<template>
  <div v-if="subsRoute">
    <router-view :key="route.path" />
  </div>
  <div class="app" v-else>
    <template v-if="authenticated">
      <NavMenuPanel />
      <NavigationGlobal class="is-hidden-mobile" />
      <NavigationContextual class="is-hidden-mobile" />
      <main id="main">
        <router-view :key="route.path" />
      </main>
    </template>
    <template v-else>
      <div style="flex: 1;">
        <Header />
        <router-view :key="route.path" />
      </div>
    </template>
  </div>
</template>

<script setup>

  import NavMenuPanel from './components/NavMenuPanel.vue'
  import NavigationGlobal from './components/NavigationGlobal.vue'
  import NavigationContextual from './components/NavigationContextual.vue'

  import {ref, computed, watch} from "vue";
  import { useRoute } from "vue-router";

  import Auth from "@/auth";
  import Header from "@/components/Header.vue";

  const route = useRoute();

  const authenticated = computed(() => {
    return Auth.isAuthenticated();
  });

  const subsRoute = computed(() => route && route.name?.startsWith('subs/'));

</script>

<style scoped>

.app {
  display: flex;
  width: 100%;
  height: 100%;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
