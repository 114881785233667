<template>
  <template
      v-if="item.menuItems && item.menuItems.length"
  >
    <li>
      <a class="menu-item" @click="handleMenuItem(item.route)" :class="itemClasses" :title="item.title || ''">
        <o-icon :icon="item.icon" :pack="item.iconPack" />
        <span>{{item.title}}</span>
      </a>
      <ul class="sub-menu">
        <template v-for="(subItem, j) in item.menuItems">
          <NavMenuItem :item="subItem" />
        </template>
      </ul>
    </li>
  </template>
  <template v-else>
    <li>
      <a class="menu-item" @click="handleMenuItem(item.route)" :class="itemClasses" :title="item.title || ''">
        <o-icon :icon="item.icon" :pack="item.iconPack" v-if="item.icon" />
        <span v-if="item.shouldShowTitle">{{item.title}}</span>
      </a>
    </li>
  </template>
</template>

<script setup>

  import {ref, computed, onMounted, watch, toRefs} from "vue";
  import {useRoute, useRouter} from "vue-router";

  let numUnreadNotifications = ref(0);
  const subMenuActive = ref(false);

  const props = defineProps({
    item: {
      required: true,
      type: Object,
    },
  });
  const {
    item,
  } = toRefs(props);
  const itemProp = item.value;

  onMounted(() => {
  });

  const currentRoute = useRoute();
  const router = useRouter();

  function handleMenuItem(route) {
    if (itemProp.onClick) {
      itemProp.onClick();
    }
    else {
      router.push({
        name: route.name,
        params: route.params,
      });
    }
  }

  const itemClasses = computed(() => {
    const classes = ['menu-item'];

    if (!itemProp.shouldShowTitle) {
      classes.push('icon-only');
    }

    if (item.value.route && !item.value.displayInactive && ((currentRoute.name === item.value.route.name)
        || (item.value.activeWithParent && item.value.route.name.startsWith(`${currentRoute.name}/`))
        || (currentRoute.name !== undefined && currentRoute.name.startsWith(`${item.value.route.name}/`)))) {
      classes.push('item-active');
      subMenuActive.value = true;
    }
    else if (currentRoute.name !== undefined && !currentRoute.name.startsWith(`${item.value.route.name}/`)) {
      subMenuActive.value = false;
    }

    return classes.join(' ');
  });

</script>

<style lang="scss" scoped>
    .nav-menu-item-liner {
        align-items: center;
        display: flex;
        position: relative;

        .nav-menu-item-count {
            background: $grey-lighter;
            border-radius: 20px;
            color: $black;
            font-size: 12px;
            padding: 0 5px;
            position: absolute;
            top: -12.5px;
            right: -5px;
            transform: translateX(50%);
        }
    }
</style>

<style lang="scss">
</style>
