<template>
    <div class="html-column is-link" @click="handleClick">
      <HTMLText :value="value" />
    </div>
</template>

<script setup>

import HTMLText from '@/components/HTMLText.vue';
import {toRefs} from "vue";

const props = defineProps({
  row: {
    required: true,
  },
  value: {
    required: true,
  },
});
const {
  row,
  value,
} = toRefs(props);

const emit = defineEmits([
  'click',
]);

function handleClick() {
  emit('click', row.value);
}

</script>

<style scoped lang="scss">
    .html-column {
        display: flex;
        max-width: 300px;
    }
</style>