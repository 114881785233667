<template>
  <div class="box" :class="{'highlight' : isHighlighted}">
    <div class="columns">
      <div class="column is-1">
        <o-icon icon="comment" pack="fas" variant="secondary" />
      </div>
      <div class="column is-9">
        <div class="help">{{value.user.first_name}} {{value.user.last_name}} @ {{value.updated_at}}</div>
        <template v-if="isUpdating">
          <o-field label="Comment">
            <o-input v-model="comment" type="textarea" />
          </o-field>
          <o-field label="Show on Company Notes"
                   body-horizontal-class="body-horizontal-class"
                   label-horizontal-class="label-horizontal-class"
                   horizontal
          >
            <o-switch v-model="showOnCompanyNotes" />
          </o-field>
        </template>
        <div v-else>{{comment}}</div>
      </div>
      <div class="column">
        <template v-if="isUpdating">
          <o-button class="is-primary is-small has-margin-bottom-small" @click="handleCancel">Cancel</o-button>
          <o-button class="is-primary is-small" @click="handleSave">Save</o-button>
        </template>
        <template v-else>
          <o-button class="is-small button-margin-right" @click="handleEdit" :disabled="disabled" v-if="canEdit">
            <o-icon icon="pencil" variant="primary"/>
          </o-button>
          <o-button class="is-small" @click="handleDelete" :disabled="disabled" v-if="canDelete">
            <o-icon icon="trash-alt" variant="primary"/>
          </o-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted, ref, toRefs, watch} from "vue";

  import {
    Dialog,
    useAuthStore,
    userHasPermission,
  } from '@/internal';

  const authStore = useAuthStore();
  const user = authStore.me;
  const comment = ref(null);
  const isHighlighted = ref(false);
  const isUpdating = ref(false);
  const showOnCompanyNotes = ref(false);

  const props = defineProps({
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
    },
  });
  const {
    disabled,
    value,
  } = toRefs(props);

  const emit = defineEmits([
     'delete',
     'disable',
     'save',
  ]);

  const canDelete = computed(() => {
    return userHasPermission('formResponseComment:delete') && parseInt(value.value.user_id) === parseInt(user.id);
  });

  const canEdit = computed(() => {
    return userHasPermission('formResponseComment:update') && parseInt(value.value.user_id) === parseInt(user.id);
  });

  onMounted(() => {
    setData();
  });

  function handleCancel() {
    comment.value = value.value.comment;
    isUpdating.value = false;
    isHighlighted.value = false;
    emit('disable', false);
  }

  function handleDelete() {
    isHighlighted.value = true;
    emit('disable', true);

    Dialog({
      title: 'Delete Comment',
      message: `Are you sure you want to delete this comment?`,
      confirmText: 'Yes',
      canCancel: true,
      variant: 'warning',
      onCancel: () => {
        isHighlighted.value = false;
        emit('disable', false);
      },
      onConfirm: () => {
        isHighlighted.value = false;
        emit('delete', value.value.id);
        emit('disable', false);
      },
    });
  }

  function handleEdit() {
    isUpdating.value = true;
    isHighlighted.value = true;
    emit('disable', true);
  }

  function handleSave() {
    emit('save', {id: value.value.id, comment: comment.value, show_on_company_notes: showOnCompanyNotes.value});
    isUpdating.value = false;
    isHighlighted.value = false;
  }

  function setData() {
    comment.value = value.value.comment;
    showOnCompanyNotes.value = value.value.show_on_company_notes;
  }

  watch(value, () => {
    setData();
  });

</script>

<style lang="scss" scoped>

.button-margin-right {
  margin-right: 1px;
}

.highlight {
  border-color: $secondary;
}

</style>