
import {
  BaseAPI,
} from '@/internal';

export default class NonceAPI extends BaseAPI {

  getNonce(params) {
    return this.get({
      url: "nonce",
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        data: res,
      }
    });
  }
};