import { deserialize } from "jsonapi-fractal";

import {
  AxiosInstance,
  BaseAPI,
} from '@/internal';

export default class PendingFormResponseAPI extends BaseAPI {

  show(params) {
    return this.get({
      url: "subs/pending-form-responses",
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        data: deserialize(res),
      }
    });
  }

  async store(data) {
    return AxiosInstance({
      url: `subs/pending-form-responses/store`,
      data: data,
      method: 'POST',
    }).then(res => this.processResponse(res)
    ).catch((error) => {
      return this.processAxiosError(error);
    }).then(res => {
      return {
        data: deserialize(res),
      }
    });
  }
};