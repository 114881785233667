<template>
    <div>
        <o-loading active v-if="syncing" />
        <NavMenu :menu="menu"/>
    </div>
</template>

<script setup>

import NavMenu from '../NavMenu.vue';

import {
  findRouteByName,
  Menu,
  NotificationSuccess,
  userIsRole,
} from '@/internal';

import {computed, ref} from "vue";

const syncing = ref(false);

const menu = computed(() => {
  return new Menu({
    title: 'Pipeline Staff',
    menuItems: [
      {
        route: findRouteByName(''),
        when: userIsRole('super'),
      },
      {
        title: 'Sync Accounts',
        icon: 'sync-alt',
        when: userIsRole('super'),
        onClick: () => {
          syncing.value = true;
          this.superSync().then(() => {
            NotificationSuccess({
              message: 'Accounts Synced',
            });
            emit('close');
          }).finally(() => {
            syncing.value = false;
          });
        },
      },
      {
        route: findRouteByName(''),
        when: userIsRole('super'),
      },
    ],
  });
});

const emit = defineEmits([
    'close',
]);

</script>