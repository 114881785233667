<template>
    <div class="question-picker">
        <o-loading
            :full-page="false"
            :active="isLoading"
            icon="rotate"
            icon-size="large"
        />

        <div v-if="question.is_instruction_type">
          <o-field label-class="instruction-label">
            <template #label>
              <HTMLText :style="colorTextStyle" :value="question.question" />
            </template>
            <HTMLText :style="colorTextStyle" :value="question.question_note" />
          </o-field>
        </div>

        <o-field :label-for="question.id"
                 :variant="error ? 'danger' : ''"
                 :message="error"
                 addons-class="field-addons"
                 message-class="danger-message-class"
                 v-else
        >
            <template #label>
              <div class="is-flex">
                <HTMLText :style="colorTextStyle" :value="question.question" />
                <o-tooltip label="Options Need to be Selected" variant="danger" v-if="!isQuestionComplete">
                  <o-icon size="medium" icon="triangle-exclamation" pack="fas" variant="danger"></o-icon>
                </o-tooltip>
                <o-tooltip label="Required" v-else-if="question.is_required">
                  <o-icon size="small" icon="asterisk" pack="fas" variant="danger"></o-icon>
                </o-tooltip>
              </div>
            </template>

            <HTMLText class="help" :style="colorTextStyle" v-if="question.question_note" :value="question.question_note" />

            <o-input :id="question.id"
                     :name="question.id"
                     :placeholder="placeholderQuestion"
                     :disabled="disabled"
                     :type="question.is_multi_line_text_type ? 'textarea' : 'input'"
                     v-model="answer"
                     v-if="question.is_single_line_text_type || question.is_multi_line_text_type"
                     @blur="handleBlur"
            />

            <o-radio v-for="option in questionOptions"
                     :id="question.id"
                     :name="question.id"
                     :placeholder="placeholderQuestion"
                     :disabled="disabled"
                     v-model="answer"
                     :native-value="option.value"
                     root-class="checkboxes"
                     v-if="question.is_radio_type"
            >
              {{option.value}}
            </o-radio>

            <o-checkbox v-for="option in questionOptions"
                        :id="question.id"
                        :name="question.id"
                        :placeholder="placeholderQuestion"
                        :disabled="disabled"
                        v-model="answer"
                        :native-value="option.value"
                        root-class="checkboxes"
                        v-if="question.is_checkbox_type"
            >
              {{option.value}}
            </o-checkbox>

            <o-dropdown aria-role="list"
                        :id="question.id"
                        :name="question.id"
                        :placeholder="placeholderQuestion"
                        :disabled="disabled"
                        menu-class="menu-class"
                        scrollable
                        v-model="answer"
                        v-if="question.is_dropdown_type"
            >
              <template #trigger="{ active }">
                <div class="button is-secondary is-outlined">
                  <span>{{(answer && answer.value) || '--- Select ---'}}</span>
                  <o-icon :icon="active ? 'caret-up' : 'caret-down'" pack="fas"></o-icon>
                </div>
              </template>

              <o-dropdown-item aria-role="listitem"
                               v-for="option in questionOptions"
                               :value="option"
              >
                {{option.value}}
              </o-dropdown-item>
            </o-dropdown>

            <o-input :id="question.id"
                     :name="question.id"
                     :placeholder="placeholderQuestion"
                     :disabled="disabled"
                     type="password"
                     v-model="answer"
                     v-if="question.is_password_type"
            />

            <div class="" v-if="question.is_yes_no_type">
              <o-radio v-for="option in questionOptions"
                       :id="question.id"
                       :name="question.id"
                       :placeholder="placeholderQuestion"
                       :disabled="disabled"
                       v-model="answer"
                       :native-value="option.value"
              >
                {{option.value}}
              </o-radio>
            </div>

            <FileUploadInput
                :form-id="formId"
                v-model:file-name="answer"
                :disabled="disabled || previewMode"
                :file-types="fileTypes"
                @input="handleFileInput"
                @disable-submit="handleDisableSubmit"
                v-if="question.is_upload_type"
            />

            <o-input :id="question.id"
                     :name="question.id"
                     :placeholder="placeholderQuestion"
                     :disabled="disabled"
                     type="date"
                     v-model="answer"
                     v-if="question.is_date_type"
                     @blur="handleBlur"
            />

            <o-input :id="question.id"
                     :name="question.id"
                     :placeholder="placeholderQuestion"
                     :disabled="disabled"
                     type="time"
                     v-model="answer"
                     v-if="question.is_time_type"
                     @blur="handleBlur"
            />

            <o-input :id="question.id"
                     :name="question.id"
                     :placeholder="placeholderQuestion"
                     :disabled="disabled"
                     type="datetime-local"
                     v-model="answer"
                     v-if="question.is_datetime_type"
                     @blur="handleBlur"
            />

            <div class="box multi-select" v-if="question.is_group_multi_select_type">
              <o-checkbox v-for="option in questionOptions"
                          :id="question.id"
                          :name="question.id"
                          :placeholder="placeholderQuestion"
                          :disabled="disabled"
                          v-model="answer"
                          :native-value="option.value_id"
                          root-class="checkboxes"
              >
                {{option.displayName}}
              </o-checkbox>
            </div>

            <div class="box multi-select" v-if="question.is_group_single_select_type">
              <o-radio v-for="option in questionOptions"
                       :id="question.id"
                       :name="question.id"
                       :placeholder="placeholderQuestion"
                       :disabled="disabled"
                       v-model="answer"
                       :native-value="option.value_id"
                       root-class="checkboxes"
              >
                {{option.displayName}}
              </o-radio>
            </div>

            <div class="box multi-select" v-if="question.is_trade_multi_select_type">
              <o-checkbox v-for="option in questionOptions"
                          :id="question.id"
                          :name="question.id"
                          :placeholder="placeholderQuestion"
                          :disabled="disabled"
                          v-model="answer"
                          :native-value="option.value_id"
                          root-class="checkboxes"
              >
                {{option.displayName}}
              </o-checkbox>
            </div>

            <div class="box multi-select" v-if="question.is_trade_single_select_type">
              <o-radio v-for="option in questionOptions"
                          :id="question.id"
                          :name="question.id"
                          :placeholder="placeholderQuestion"
                          :disabled="disabled"
                          v-model="answer"
                          :native-value="option.value_id"
                          root-class="checkboxes"
              >
                {{option.displayName}}
              </o-radio>
            </div>

            <CustomFieldInput v-model:custom-field="customField"
                              :disabled="disabled"
                              v-model:value="answer"
                              :colr-text="colorTextStyle"
                              @input="handleCustomInput"
                              @blur="handleBlur"
                              v-if="question.is_custom_type && customField"
            />
        </o-field>
    </div>
</template>

<script setup>

import {computed, onMounted, ref, toRefs, watch, nextTick} from "vue";

import CustomFieldInput from "@/components/Pickers/CustomFieldInput.vue";
import FileUploadInput from "@/components/Pickers/FileUploadInput.vue";
import HTMLText from "@/components/HTMLText.vue";

import {
  NotificationError,
  useGroupStore,
  useTradeStore,
  useSubGroupStore,
  useSubTradeStore,
} from "@/internal";

const gcGroupStore = useGroupStore();
const gcTradeStore = useTradeStore();
const subGroupStore = useSubGroupStore();
const subTradeStore = useSubTradeStore();

const answer = ref();
const isLoading = ref(false);
const mountComplete = ref(false);

const props = defineProps({
  answers: {
    required: false,
  },
  colorText: {
    required: false,
  },
  disabled: {
    required: false,
    type: Boolean,
    default: false,
  },
  error: {
    required: false,
    default: null,
  },
  filterFiles: {
    required: false,
  },
  formId: {
    required: false,
    default: null,
  },
  previewMode: {
    required: false,
    type: Boolean,
    default: false,
  },
  question: {
    required: true,
    type: Object,
  },
  value: {
    required: false,
  },
});
const {
  answers,
  colorText,
  disabled,
  error,
  filterFiles,
  formId,
  previewMode,
  question,
  value,
} = toRefs(props);

const emit = defineEmits([
    'disableSubmit',
    'input',
    'blur'
]);

onMounted(() => {
  initAnswer();
  nextTick(() => {
    mountComplete.value = true;
  });
});

const colorTextStyle = computed(() => colorText.value ? {color: `#${colorText.value}`} : '');

const customField = computed(() => {
  return question.value?.customField
          ? {
              ...question.value.customField,
              name: question.value.question,
              options: {},
            }
          : null;
});

const fileTypes = computed(() => filterFiles.value ? [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
] : []);

const groupStore = computed(() => previewMode.value ? gcGroupStore : subGroupStore);

const tradeStore = computed(() => previewMode.value ? gcTradeStore : subTradeStore);

const isArrayType = computed(() => {
  return question.value.is_group_multi_select_type || question.value.is_trade_multi_select_type || question.value.is_checkbox_type;
});

const isDropDownType = computed(() => {
  return question.value.is_dropdown_type;
});

const isGroupType = computed(() => {
  return question.value.is_group_multi_select_type || question.value.is_group_single_select_type;
});

const isQuestionComplete = computed(() => {
  const optionsType = question.value.is_group_multi_select_type || question.value.is_group_single_select_type || question.value.is_trade_multi_select_type || question.value.is_trade_single_select_type || question.value.is_checkbox_type || question.value.is_radio_type || question.value.is_dropdown_type;
  return (optionsType && question.value.options && question.value.options.length) || !optionsType;
});

const isTradeType = computed(() => {
  return question.value.is_trade_multi_select_type || question.value.is_trade_single_select_type;
});

const isInputFieldType = computed(() => {
  return question.value.is_single_line_text_type || question.value.is_multi_line_text_type || question.value.is_password_type || question.value.is_date_type || question.value.is_time_type || question.value.is_datetime_type || question.value.is_custom_type;
});

const groupsAnswer = computed(() => {
  return (answers.value?.length && answers.value.values.map(answer => groupStore.value.find(answer.value)).filter(answer => answer !== null)) || [];
});

const placeholderQuestion = computed(() => question.value.question.replace(/(<([^>]+)>)/gi, ""));

const questionOptions = computed(() => {
  if (isGroupType.value) {
    return question.value?.options?.filter(option => !!getGroup(option.value_id))
    .map(option => {
        return {
          ...option,
          displayName: getGroup(option.value_id),
        }
      })
      .sort((a, b) => a.displayName > b.displayName ? 1 : -1);
  }
  else if (isTradeType.value) {
    return question.value?.options?.filter(option => !!getTrade(option.value_id))
      .map(option => {
        return {
          ...option,
          displayName: getTrade(option.value_id),
        }
      })
      .sort((a, b) => a.displayName > b.displayName ? 1 : -1);
  }

  return question.value.options;
});

const tradesAnswer = computed(() => {
  return (answers.value?.length && answers.value.values.map(answer => tradeStore.value.find(answer.value)).filter(answer => answer !== null)) || [];
});

function getGroup(id) {
  const group = groupStore.value.find(id);
  return group ? group.name : '';
}

function getTrade(id) {
  const trade = tradeStore.value.find(id);
  return trade ? trade.short_label : '';
}

function handleCustomInput(value) {
  answer.value = value;
}

function handleDisableSubmit(disable) {
  emit('disableSubmit', disable);
}

function handleFileInput(value) {
  answer.value = value;
}

function handleInput() {
  if (isArrayType.value) {
    emit('input', {
      ...answers.value,
      values: answer.value.map(answerValue => {
        return {
          id: null,
          answer_id: answers.value?.id || null,
          value: answerValue,
        };
      }),
    });
  }
  else {
    emit('input', {
      ...answers.value,
      values: [{
        id: null,
        answer_id: answers.value?.id || null,
        value: isDropDownType.value && answer.value.hasOwnProperty('value') ? answer.value.value : answer.value,
      }],
    });
  }
  //if isn't blurable input type
  if(!isInputFieldType.value && mountComplete.value){
    handleBlur();
  }
}

function initAnswer() {
  if (isArrayType.value) {
    answer.value = answers.value?.hasOwnProperty('values') ? answers.value.values.map(answerValue => answerValue.value) : [];
  }
  else {
    answer.value = answers.value?.values?.length ? answers.value.values[0].value : '';

    if (isDropDownType.value) {
      answer.value = question.value.options.find(option => option.value === answer.value);
    }
  }
}

function handleBlur(){
  emit('blur');
}

watch(answer, () => {
  handleInput();
});

</script>

<style lang="scss" scoped>
    .question-picker {
      margin-bottom: 1em;

      .multi-select {
        width: 100%;
        max-height: 300px;
        overflow-y: scroll;
      }

      .checkboxes {
        display: flex;
        margin-bottom: .5em;
      }

      .menu-class {
        max-height: 300px;
      }

    }
</style>

<style lang="scss">
    .question-picker {

      .checkbox {
        .check {
          &:checked {
            background-color: #4A4A4AFF !important;
            border-color: #4A4A4AFF !important;
          }

          &:hover {
            border-color: #4A4A4AFF !important;
          }

          &:focus {
            box-shadow: 0 0 0.5em rgba(122, 122, 122, 0.8) !important;
          }
        }
      }

      .radio {
        .check {
          &:checked {
            //background-color: #4A4A4AFF !important;
            border-color: #4A4A4AFF !important;
          }

          &:before {
            background: #4A4A4AFF !important;
          }

          &:hover {
            border-color: #4A4A4AFF !important;
          }

          &:focus {
            box-shadow: 0 0 0.5em rgba(122, 122, 122, 0.8) !important;
          }
        }
      }

      input:focus {
        border-color: $secondary;
      }

      .is-primary {
        .tooltip-content {
          background: #4A4A4AFF !important;

          &:before {
            border-top-color: #4A4A4AFF !important;
          }
        }
      }

      .field-addons {
        display: flex;
        flex-direction: column;

        .help {
          margin-top: 0;
          margin-bottom: .75em;
        }
      }

      .danger-message-class {
        color: $danger;
      }

      .instruction-label {
        font-size: 1.25rem !important;
        font-weight: 400;
        line-height: 1.25;
      }
    }
</style>
