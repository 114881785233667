import {defineStore} from "pinia";
import {deserialize, serialize} from "jsonapi-fractal";
import AuthAPI from "@/api/AuthAPI";
import Auth from "@/auth";
import {NotificationSuccess} from "../internal";

export const useAuthStore = defineStore('authme', {
  state: () => ({
    me: null,
  }),
  actions: {
    clear() {
      this.me = null;
    },
    redirectToLogout() {
      localStorage.setItem('redirectPath', window.location.pathname);
    },
    getMe(payload) {
      let params = {};
      if (payload?.hasOwnProperty("params")) {
        params = payload.params;
      }

      return new AuthAPI().getMe({
        ...params,
        include: [
          'associatedBusinessUnits',
          'client',
          'permissions',
          'roles',
        ],
      }).then(response => {
        this.me = response.data;
        return response.data;
      }).catch((e) => {
        console.error(e);
      });
    },
  },
  persist: true,
});
