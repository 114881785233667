import { deserialize } from "jsonapi-fractal";

import {
  BaseAPI,
} from '@/internal';

export default class FormResponseCommentAPI extends BaseAPI {

  index(formId, responseId, params) {
    return this.get({
      url: `form/${formId}/response/${responseId}/comment`,
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        data: deserialize(res),
      }
    })
  }

  show(id, params) {
    return this.get({
      url: `form-response-comments/${id}`,
      headers: [],
      queryParams: params,
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        data: deserialize(res),
      }
    })
  }

  store(formId, responseId, data) {
    return this.post({
      url: `form/${formId}/response/${responseId}/comment`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        data: deserialize(res),
      }
    })
  }

  update(id, data) {
    return this.put({
      url: `form-response-comments/${id}`,
      headers: [],
      body: JSON.stringify(data),
    }).then(res => this.processResponse(res)
    ).then(res => {
      return {
        data: deserialize(res),
      }
    })
  }

  delete(id) {
    return super.delete({
      url: `form-response-comments/${id}`,
      headers: [],
    }).then(res => this.processResponse(res)
    ).then(res => {
        return {
          data: deserialize(res),
        }
      })
  }
};