import {
  findRouteByName,
  Menu,
  userHasPermission,
} from '@/internal';

export default (payload = {}) => {

  return new Menu({
    menuItems: [
      {
        hideTitle: true,
        route: findRouteByName('responses'),
        when: userHasPermission('formResponse:read'),
        iconPack: 'fal',
      },
      {
        hideTitle: true,
        route: findRouteByName('forms'),
        when: userHasPermission('form:read'),
        iconPack: 'fal',
      },
      {
        hideTitle: true,
        route: findRouteByName('questions'),
        when: userHasPermission('formQuestion:read'),
        iconPack: 'fal',
      },
    ],
  });
};