<template>
  <Page id="question-show" :title="pageTitle">
    <o-loading
        :full-page="true"
        v-model:active="isLoading"
        :can-cancel="true"
        icon="rotate"
        icon-size="large"
    />

    <template v-slot:addon v-if="question && !isLoading">
      <o-tooltip label="Saved to Company Data" position="right" variant="secondary" class="has-margin-top has-margin-right" v-if="isDBRetainQuestion">
        <o-icon icon="thumbtack" variant="secondary" size="small" />
      </o-tooltip>
      <div class="tag is-warning has-margin-top" v-if="!question.active">Archived</div>
    </template>

    <template v-slot:controls v-if="question && !isLoading">
      <div class="buttons is-flex-wrap-nowrap">
        <router-link class="button is-primary" :to="`/questions/active/${questionId}/edit`" v-if="canEdit">
          <o-icon icon="pencil" class="has-margin-right" />
          Edit
        </router-link>
        <div class="button is-primary" @click="handleArchive" v-if="canArchive">
          <o-icon icon="trash-alt" pack="fas" class="has-margin-right" />
          Delete
        </div>
      </div>
    </template>

    <template v-if="question && !isLoading">
      <div class="has-margin-bottom-large">
        <div class="label">Question</div>
        <HTMLText :value="question.question" />
      </div>

      <div class="has-margin-bottom-large">
        <div class="label">Note</div>
        <HTMLText :value="question.question_note" />
      </div>

      <div class="has-margin-bottom-large">
        <div class="label">Type</div>
        <div>{{question.formQuestionType.name}}</div>
      </div>

      <div class="has-margin-bottom-large" v-if="question.formQuestionType.is_custom_type">
        <div class="label">Custom Field</div>
        <div>{{question.customField?.name}}</div>
      </div>

      <div class="has-margin-bottom-large">
        <div class="label">Answer Options/Preview</div>
        <div class="box">
          <QuestionPicker :question="question" :preview-mode="true" v-if="question" />
        </div>
      </div>
    </template>
  </Page>
</template>

<script setup>

import {useRoute, useRouter} from "vue-router";

 import Page from "@/components/Page.vue";
 import QuestionPicker from "@/components/Pickers/QuestionPicker.vue";
 import HTMLText from "@/components/HTMLText.vue";

 import {
   Dialog,
   findRouteByName,
   NotificationError,
   NotificationSuccess,
   useGroupStore,
   useFormQuestionStore,
   useTradeStore,
   userHasPermission,
 } from "@/internal";
 import {computed, onBeforeMount, onMounted, ref} from "vue";

 const formQuestionStore = useFormQuestionStore();
 const groupStore = useGroupStore();
 const tradeStore = useTradeStore();
 const route = useRoute();
 const router = useRouter();
 const isLoading = ref(false);

 const question = computed(() => {
   return formQuestionStore.find(questionId.value);
 });

 const questionId = computed(() => {
   return route.params.questionId || null;
 });

 const pageTitle = computed(() => {
   return question.value?.question?.replace(/(<([^>]+)>)/ig, '') || '';
 });

 const canArchive = computed(() => userHasPermission('formQuestion:archive') && !isSystemQuestion.value && question.value?.active);

 const canEdit = computed(() => userHasPermission('formQuestion:update') && (!isSystemQuestion.value || (isSystemQuestion.value && canEditSystemQuestionOptions.value)) && question.value?.active);

 const canEditSystemQuestionOptions = computed(() => question.value?.is_group_multi_select_type || question.value?.is_group_single_select_type || question.value?.is_trade_multi_select_type || question.value?.is_trade_single_select_type);

 const canRestore = computed(() => userHasPermission('formQuestion:restore') && !isSystemQuestion.value && !question.value?.active);

 const groups = computed(() => groupStore.data || []);

 const isDBRetainQuestion = computed(() => question.value?.db_retain);

const isSystemQuestion = computed(() => question.value?.is_system);

 const options = computed(() => question.value.options || []);

 const trades = computed(() => tradeStore.data || []);

 onBeforeMount(async () => {
   await loadQuestion();
   loadGroups();
   loadTrades();
 });

 function handleArchive() {

   Dialog({
     title: 'Delete Question',
     message: `Are you sure you want to delete this question?`,
     confirmText: 'Yes',
     canCancel: true,
     variant: 'warning',
     onCancel: () => {
     },
     onConfirm: () => {
       isLoading.value = true;

       formQuestionStore.archive(questionId.value).then(() => {
         NotificationSuccess({
           message: `Question Deleted`,
         });

         const route = findRouteByName(`questions`);
         router.push(route);
       }).catch((error) => {
         if (error.errors) {
           NotificationError({}, error);
         }
         else {
           NotificationError({
             message: error,
           });
         }
       }).finally(() => {
         isLoading.value = false;
       });
     },
   });
 }

 function handleRestore() {

   isLoading.value = true;

   formQuestionStore.restore(questionId.value).then(() => {
     NotificationSuccess({
       message: `Question Restored`,
     });
   }).catch((error) => {


   }).finally(() => {
     loadQuestion();
     isLoading.value = false;
   });
 }

 function loadQuestion() {

   isLoading.value = true;

   const payload = {
     params: {
       include: [
         'customField',
         'formQuestionType',
         'options',
       ],
     },
   };

   return formQuestionStore.show(questionId.value, payload)
       .finally(() => {
         isLoading.value = false;
       });

 }

 function loadGroups() {
   const payload = {
     params: {
       include: [
       ],
     },
   };

   groupStore.index(payload)
       .then(() => {
       }).finally(() => {
   });
 }

 function loadTrades() {
   const payload = {
     params: {
       include: [
       ],
     },
   };

   tradeStore.index(payload)
       .then(() => {
       }).finally(() => {
   });
 }

</script>

<style lang="scss" scoped>

#question-show {
  .page-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }
}
</style>

<style lang="scss">

#response-show {
  .body-horizontal-class {
    .field {
      text-align: left;
    }
  }

  .label-horizontal-class {
    flex-basis: 300px;
    flex-grow: unset;
    flex-shrink: unset;

    .label {
      margin-top: 0;
      text-align: left;
      line-height: normal;
    }
  }
}

</style>
