<template>
  <Page :title="pageTitle" id="view-log">
    <o-loading
        :full-page="true"
        :active="isLoading"
        icon="rotate"
        icon-size="large"
    />

    <template v-slot:controls>
      <o-tooltip label="Download Email Logs" variant="secondary">
        <div class="button is-secondary has-margin-top" @click="handleExport">
          <o-icon icon="file-excel" pack="fas" size="large" />
        </div>
      </o-tooltip>
    </template>

    <ViewLog ref="viewLogRef"
             :form-id="formId"
             :export-title="form.name"
     />

  </Page>
</template>

<script setup>

  import {useRoute} from "vue-router";

  import Page from "@/components/Page.vue";
  import ViewLog from "@/components/ViewLog.vue";

  import {
    useAuthStore,
    useFormStore,
  } from "@/internal";
  import {computed, onMounted, ref, watch} from "vue";

  const authStore = useAuthStore();
  const formStore = useFormStore();
  const route = useRoute();
  const isLoading = ref(false);
  const viewLogRef = ref(null);

  const form = computed(() => {
    return formStore.find(formId.value);
  });

  const formId = computed(() => {
    return route.params.formId || null;
  });

  const pageTitle = computed(() => {
    return "View Log: " + (form.value ? form.value.name : '');
  });

  onMounted(() => {
    loadForm();
  });

  function handleExport() {
    viewLogRef.value.handleExport();
  }

  function loadForm() {
    isLoading.value = true;

    const payload = {
      params: {
        include: [
          'approvedGroup',
          'businessUnit',
          'questions.customField',
          'questions.options',
          'sendEmailTo',
          'subjectQuestion',
          'unapprovedGroup',
          'workflow.workflowSteps.emailRecipient',
        ],
      },
    };

    formStore.show(formId.value, payload).finally(() => {
      isLoading.value = false;
    });
  }

</script>

<style lang="scss" scoped>

#view-log {
}

</style>