<template>
    <div class="comment-form">

      <o-loading
          :full-page="false"
          v-model:active="isLoading"
          :can-cancel="true"
          icon="rotate"
          icon-size="large"
      />

      <div id="comments">
        <div class="title">Comments</div>
          <template v-for="comment in comments" :key="comment.id">
              <Comment :value="comment"
                       :disabled="disabled"
                       @disable="handleDisabled"
                       @delete="handleDelete"
                       @save="handleSave"
              />
          </template>
      </div>

      <div class="box comment-input has-margin-bottom">
        <o-field label="Comment">
          <o-input v-model="comment" type="textarea" :disabled="disabled" />
        </o-field>
        <o-field label="Show on Company Notes"
                 body-horizontal-class="body-horizontal-class"
                 label-horizontal-class="label-horizontal-class"
                 horizontal
                 v-if="canAddCompanyNote"
        >
          <o-switch v-model="showOnCompanyNotes" :disabled="disabled" />
        </o-field>
        <div class="column buttons has-text-right">
          <o-button class="is-light" @click="handleCancel" :disabled="disabled" v-if="comment">
              Cancel
          </o-button>
          <o-button class="is-primary" @click="handleSave" :class="{ 'is-loading': isSaving }" :disabled="disabled">
              Add Comment
          </o-button>
        </div>
      </div>
      <div>&nbsp;</div>
  </div>
</template>

<script setup>

import {computed, onMounted, ref, toRefs} from "vue";

import {
  NotificationSuccess,
  useAuthStore,
  useFormResponseCommentStore,
  useFormResponseStore,
} from '@/internal';

import Comment from "@/components/Comment.vue";
import NoDataPlaceholder from "@/components/NoDataPlaceholder.vue";

const authStore = useAuthStore();
const formResponseCommentStore = useFormResponseCommentStore();
const formResponseStore = useFormResponseStore();
const comment = ref('');
const disabled = ref(false);
const isLoading = ref(false);
const isSaving = ref(false);
const showOnCompanyNotes = ref(false);

const props = defineProps({
  responseId: {
    required: true,
  },
  canAddCompanyNote: {
    required: false,
    default: true,
  },
});
const {
  responseId,
  canAddCompanyNote,
} = toRefs(props);

const comments = computed(() => formResponseCommentStore.data || []);

const response = computed(() => {
  return formResponseStore.find(responseId.value);
});

const user = computed(() => authStore.me);

onMounted(() => {
  loadComments();
});

function handleCancel() {
  reset();
}

function handleDelete(id) {

  isSaving.value = true;

  formResponseCommentStore.delete(id).then(() => {
    NotificationSuccess({
      message: `Comment Deleted`,
    });
  }).catch((error) => {

  }).finally(() => {
    isSaving.value = false;
    reset();
    loadComments();
  });
}

function handleDisabled(disabledValue) {
  disabled.value = disabledValue;
}

function handleSave(data) {

  const newComment = data.hasOwnProperty('comment') ? false : true;

  if (newComment && !comment.value) {
    return;
  }

  isSaving.value = true;

  const payload = {
    id: newComment ? null : data.id,
    comment: newComment ? comment.value : data.comment,
    show_on_company_notes: newComment ? showOnCompanyNotes.value : data.show_on_company_notes,
    formId: response.value.form_id,
    responseId: response.value.id,
  };

  const action = newComment ? formResponseCommentStore.store : formResponseCommentStore.update;

  action(payload).then(() => {
    NotificationSuccess({
      message: `Comment ${newComment ? 'Added' : 'Saved'}`,
    });
  }).catch((error) => {

  }).finally(() => {
    isSaving.value = false;
    reset();
    loadComments();
  });
}

function loadComments() {
  isLoading.value = true;

  const payload = {
    params: {
      include: [
        'user',
      ],
    },
  };

  formResponseCommentStore.index(response.value.form_id, responseId.value, payload)
    .finally(() => {
      isLoading.value = false;
    });
}

function reset() {
  comment.value = '';
  disabled.value = false;
  showOnCompanyNotes.value = false;
}

</script>

<style lang="scss" scoped>
.comment-form {
    height: 100%;
    display: flex;
    flex-direction: column;

    #comments {
        margin-bottom: 15px;
    }

    .box {
      &.comment-input {
        min-width: fit-content !important;
      }
    }
}
</style>

<style lang="scss">
.comment-form {

    .body-horizontal-class {
      .field {
        text-align: left;
      }
    }

    .label-horizontal-class {
      white-space: nowrap;

      .label {
        margin-top: 0;
        text-align: left;
        line-height: normal;
      }
    }
}
</style>
